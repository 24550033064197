// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body{
  /* background: #000000 !important; */
  font-family: 'Poppins', sans-serif !important;
  /* overflow-x: hidden !important; */
}
a:hover{
  text-decoration: none !important;
}

.dark-theme {
  background: rgba(22, 24, 42, 1) !important;
  color: #fff !important;
}

.dash_right_panel .toggle__button {
  display: none;
}

.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  inset: 0;
  z-index: 9999;
}

.dark-theme .modal-backdrop.show {
  opacity: .8;
}
.react-tel-input .flag-dropdown {
  border: 0 !important;
  background: transparent !important;
}
.dash_right_panel .react-tel-input .form-control {
  font-size: 16px;
}
.btn.btn_primary.btn-lg,
.btn.btn_secondary.btn-lg {
  padding: 0.8rem 2.4rem;
  font-size: 1rem;
}
.btn.btn_secondary {
  border-radius: 0.4rem;
  background-color: #e6e6ef;
  padding: 0.5rem 2rem;
  border: 0;
  color: #565678;
}
.btn.btn_secondary:hover {
  background-color: #d3d3e3;
  color: #565678;
}

@media (min-width: 1200px){
  .container{
      max-width: 1200px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,oCAAoC;EACpC,6CAA6C;EAC7C,mCAAmC;AACrC;AACA;EACE,gCAAgC;AAClC;;AAEA;EACE,0CAA0C;EAC1C,sBAAsB;AACxB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,oCAAoC;EACpC,eAAe;EACf,OAAO;EACP,QAAQ;EACR,SAAS;EACT,MAAM;EACN,QAAQ;EACR,aAAa;AACf;;AAEA;EACE,WAAW;AACb;AACA;EACE,oBAAoB;EACpB,kCAAkC;AACpC;AACA;EACE,eAAe;AACjB;AACA;;EAEE,sBAAsB;EACtB,eAAe;AACjB;AACA;EACE,qBAAqB;EACrB,yBAAyB;EACzB,oBAAoB;EACpB,SAAS;EACT,cAAc;AAChB;AACA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE;MACI,4BAA4B;EAChC;AACF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');\n\nbody{\n  /* background: #000000 !important; */\n  font-family: 'Poppins', sans-serif !important;\n  /* overflow-x: hidden !important; */\n}\na:hover{\n  text-decoration: none !important;\n}\n\n.dark-theme {\n  background: rgba(22, 24, 42, 1) !important;\n  color: #fff !important;\n}\n\n.dash_right_panel .toggle__button {\n  display: none;\n}\n\n.loader-wrapper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: rgba(0, 0, 0, 0.4);\n  position: fixed;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  top: 0;\n  inset: 0;\n  z-index: 9999;\n}\n\n.dark-theme .modal-backdrop.show {\n  opacity: .8;\n}\n.react-tel-input .flag-dropdown {\n  border: 0 !important;\n  background: transparent !important;\n}\n.dash_right_panel .react-tel-input .form-control {\n  font-size: 16px;\n}\n.btn.btn_primary.btn-lg,\n.btn.btn_secondary.btn-lg {\n  padding: 0.8rem 2.4rem;\n  font-size: 1rem;\n}\n.btn.btn_secondary {\n  border-radius: 0.4rem;\n  background-color: #e6e6ef;\n  padding: 0.5rem 2rem;\n  border: 0;\n  color: #565678;\n}\n.btn.btn_secondary:hover {\n  background-color: #d3d3e3;\n  color: #565678;\n}\n\n@media (min-width: 1200px){\n  .container{\n      max-width: 1200px !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
