import React, { useState, useRef, useEffect } from "react";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Form,
  Dropdown,
  Table,
  Accordion,
  Card,
  Tab,
  Nav,
  OverlayTrigger,
  Tooltip as BootstrapTooltip,
} from "react-bootstrap";
import DashboardLeftPanel from "../../../components/DashboardLeftPanel/dashboardLeftPanel";
import { Link, useHistory, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Audio } from "react-loader-spinner";
import axios from "../../../axios/axios";
import message from "../../../messages";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./generatearticle.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import Moment from "moment";
import {
  FacebookSocial,
  InsSocial,
  TwitterSocial,
  LinkedSocial,
  Wp,
  FbLogo,
} from "../../../assets/images";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';

const fileTypes = ["JPG", "PNG", "GIF"];

const roundTimeOptions = [
  "12:00 AM",
  "12:30 AM",
  "1:00 AM",
  "1:30 AM",
  "2:00 AM",
  "2:30 AM",
  "3:00 AM",
  "3:30 AM",
  "4:00 AM",
  "4:30 AM",
  "5:00 AM",
  "5:30 AM",
  "6:00 AM",
  "6:30 AM",
  "7:00 AM",
  "7:30 AM",
  "8:00 AM",
  "8:30 AM",
  "9:00 AM",
  "9:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",

  "12:00 PM",
  "12:30 PM",
  "1:00 PM",
  "1:30 PM",
  "2:00 PM",
  "2:30 PM",
  "3:00 PM",
  "3:30 PM",
  "4:00 PM",
  "4:30 PM",
  "5:00 PM",
  "5:30 PM",
  "6:00 PM",
  "6:30 PM",
  "7:00 PM",
  "7:30 PM",
  "8:00 PM",
  "8:30 PM",
  "9:00 PM",
  "9:30 PM",
  "10:00 PM",
  "10:30 PM",
  "11:00 PM",
  "11:30 PM",
];

const ShareOnMedia = (props) => {
  const tooltip = "If you want to post in your Profile and groups, Please copy the text and share it manually";
  const params = useParams();
  let today = Moment(new Date()).format("YYYY-MM-DD");
  const [selectedImage, setSelectedImage] = useState([]);
  const [img, setimg] = useState([]);
  const [startDate, setStartDate] = useState(""); /////////////
  const [dateError, setDateError] = useState(""); ///////////
  const [time, setTime] = useState(""); ///////////////
  const [timeError, setTimeError] = useState("");
  const [dragImg, setDragImg] = useState(null);
  const [connectedAcc, setConnectedAcc] = useState([]);
  const [selectedAcc, setSelectedAcc] = useState([]);
  const [wpData, setWpData] = useState({
    url: "",
    app_id: "",
    app_password: "",
  });
  const [credsError, setCredsError] = useState({
    urlErr: "",
    appIdErr: "",
    appPassErr: "",
  });
  const [loader, setLoader] = useState(false);
  const [ckEditorData, setCkEditorData] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCatValue, setSelectedCatValue] = useState("");
  const [showWpModal, setShowWpModal] = useState(false);
  const [getPostDetails, setPostDetails] = useState([]);
  const [activeFB, setActiveFb] = useState(false);
  const [wordCount, setWordCount] = useState("");

  const handlePostPublish = async () => {
    let paramsData = {
      title: getPostDetails.title,
      content: getPostDetails.content ? getPostDetails.content : ckEditorData,
      images: !dragImg ? selectedImage : [URL.createObjectURL(dragImg)],
      facebook_ids: selectedAcc
        .filter((account) => account.account_type === "Facebook")
        .map((account) => account.id),
      twitter_ids: selectedAcc
        .filter((account) => account.account_type === "Twitter")
        .map((account) => account.id),
      instagram_ids: selectedAcc
        .filter((account) => account.account_type === "Instagram")
        .map((account) => account.id),
      linkedin_ids: selectedAcc
        .filter((account) => account.account_type === "LinkedIn")
        .map((account) => account.id),
      wordpress_ids: selectedAcc
        .filter((account) => account.account_type === "WordPress")
        .map((account) => account.id),
      wp_category: selectedCatValue,
    };
    if (params.postId) {
      paramsData.postId = params.postId;
    }
    setLoader(true);
    await axios
      .post("/post-publish", paramsData)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          message.success("Post published successfully");
        }
      })
      .catch((err) => {
        setLoader(false);
        message.error("Internal server error");
      });
  };
  const DateAndTimeValidate = () => {
    setDateError("");
    setTimeError("");

    if (!startDate) {
      setDateError("Date field is required");
      return false;
    } else if (startDate < today) {
      setDateError("Please select a date that is today or in the future");
      return false;
    }
    if (!time) {
      setTimeError("Time field is required");
      return false;
    }

    return true;
  };
  const handleSchedule = async (e) => {
    e.preventDefault();
    if (DateAndTimeValidate()) {
      let paramsData = {
        title: getPostDetails.title,
        content: getPostDetails.content ? getPostDetails.content : ckEditorData,
        images: !dragImg ? selectedImage : [URL.createObjectURL(dragImg)],
        schedule_at: `${startDate} ${time}`,
        facebook_ids: selectedAcc
          .filter((account) => account.account_type === "Facebook")
          .map((account) => account.id),
        twitter_ids: selectedAcc
          .filter((account) => account.account_type === "Twitter")
          .map((account) => account.id),
        instagram_ids: selectedAcc
          .filter((account) => account.account_type === "Instagram")
          .map((account) => account.id),
        linkedin_ids: selectedAcc
          .filter((account) => account.account_type === "LinkedIn")
          .map((account) => account.id),
        wordpress_ids: selectedAcc
          .filter((account) => account.account_type === "WordPress")
          .map((account) => account.id),
        wp_category: selectedCatValue,
      };

      if (params.postId) {
        paramsData.postId = params.postId;
      }
      // Submit the form or perform other actions
      setLoader(true);
      await axios
        .post("/post-schedule", paramsData)
        .then((res) => {
          setLoader(false);
          message.success(res.data.message);
        })
        .catch((err) => {
          setLoader(false);
          console.log("err", err);
          message.error(err.response.data.message);
        });
    }
  };

  const countData = () => {
    const spaceSplit = getPostDetails?.content?.split(" ")?.length
    setWordCount(spaceSplit)
  }

  useEffect(()=>{
    countData()
  })

  const handleShowModal = () => {
    setShowWpModal(true);
  };
  const handleCloseModal = () => {
    setShowWpModal(false);
  };

  const handleWpChange = (event) => {
    const { name, value } = event.target;
    setWpData({ ...wpData, [name]: value });
  };

  const handleTimeChange = (event) => {
    const newTime = event.target.value;
    setTime(newTime);
  };

  const handleCheckboxChange = (event) => {
    const checkboxValue = event.target.value;
    const isChecked = selectedImage.includes(checkboxValue);

    if (isChecked) {
      // If the checkbox value is already in the array, remove it to uncheck
      setSelectedImage(
        selectedImage.filter((value) => value !== checkboxValue)
      );
    } else {
      // If the checkbox value is not in the array, add it to check
      setSelectedImage([...selectedImage, checkboxValue]);
    }
  };

  const getAllSocialAccount = async () => {
    await axios
      .get("/social/accounts")
      .then((res) => {
        const instaAcc = res.data.data;
        const filterAc = instaAcc.filter(
          (iAc) =>
            iAc.account_type === "LinkedIn" ||
            iAc.account_type === "Facebook" ||
            iAc.account_type === "WordPress" ||
            iAc.account_type === "Instagram" ||
            iAc.account_type === "Twitter"
        );
        // console.log("filterAc", filterAc)
        setConnectedAcc(filterAc);
      })
      .catch((err) => {
        // console.error(err, "<<-- Error in get all social accounts")
      });
  };

  const handleAccChange = async (e, accountId, account_type) => {
    const isChecked = e.target.checked;
    // Check if the account with the provided id exists in the selectedAcc array
    const existingAccountIndex = selectedAcc.findIndex(
      (account) => account.id === accountId
    );

    if (existingAccountIndex !== -1) {
      // Account exists, so update its selected property
      setSelectedAcc(selectedAcc.filter((acc) => acc.id !== accountId));
    } else {
      // Account does not exist, so add the new account data
      setSelectedAcc((prevSelectedAcc) => [
        ...prevSelectedAcc,
        { id: accountId, account_type: account_type, selected: isChecked },
      ]);
    }
  };

  const facebookAuth = async () => {
    setLoader(true);
    await axios
      .get("/facebook/auth")
      .then((res) => {
        setLoader(false);
        // console.log("data", res);
        window.open(res.data.data.url, "_blank");
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };

  const instagramAuth = async () => {
    setLoader(true);
    await axios
      .get("/instagram/auth")
      .then((res) => {
        setLoader(false);
        // console.log("data", res);
        window.open(res.data.data.url, "_self");
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };

  const twitterAuth = async () => {
    setLoader(true);
    await axios
      .get("/twitter/auth")
      .then((res) => {
        setLoader(false);
        // console.log("data", res);
        window.open(res.data.data.url, "_blank");
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };

  const linkedinAuth = async () => {
    setLoader(true);
    await axios
      .get("/linkedin/auth")
      .then((res) => {
        setLoader(false);
        // console.log("data", res);
        window.open(res.data.data.url, "_blank");
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };
  const wordpressAuth = async () => {
    setLoader(true);

    if (!wpData.app_id) {
      setCredsError({ ...credsError, appIdErr: "AppId is required" });
      return;
    }
    if (!wpData.url) {
      setCredsError({ ...credsError, urlErr: "Url is required" });
      return;
    }
    if (!wpData.app_password) {
      setCredsError({ ...credsError, appPassErr: "AppId is required" });
      return;
    } else {
      await axios
        .post("/wordpress/auth", wpData)
        .then((res) => {
          setLoader(false);
          handleCloseModal();
        })
        .catch((err) => {
          setLoader(false);
          console.log("err", err);
          message.error(err.response.data.message);
        });
    }
  };

  const handleImgSubmit = async (e) => {
    e.preventDefault();

    let paramsData = {
      title: getPostDetails.title,
      no_of_images: 5,
    };
    setLoader(true);
    await axios
      .post("/image-generate", paramsData)
      .then((res) => {
        setLoader(false);
        setimg(res.data.data);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setCkEditorData(data);
  };

  const getPost = async () => {
    await axios
      .get(`/post/` + params.id)
      .then((res) => {
        setLoader(false);
        setPostDetails(res.data.data);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };

  const scrollDown = () => {
    const element = document.getElementById("scrollTargetRef");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      console.error("Element not found");
    }
  };

  const hanldeSwitchMedia = () => {
    setActiveFb(true);
    scrollDown();
  };

  useEffect(() => {
    getPost();
    getAllSocialAccount();
  }, []);

  const handleBack = () => {

  } 

  return (
    <div className="dash_inner_partts">
      {loader == true && (
        <div className="loader-wrapper">
          <Audio
            height="80"
            width="80"
            radius="9"
            color="green"
            ariaLabel="loading"
            wrapperStyle
            wrapperClass
          />
        </div>
      )}
      <DashboardLeftPanel />
      <div className="dash_right_panel">
        <div className="dash_right_inner_panel">
          <div className="edit_art_top">
            <h3>Edit your article</h3><span>No. of Word: {wordCount}</span>

            <div className="ckeditor_partts">
              <CKEditor
                editor={ClassicEditor}
                onChange={handleEditorChange}
                data={getPostDetails.content}
              />
            </div>
          </div>
          <div className="generate_images">
            {!img.length > 0 && <h3>Choose Images</h3>}
            {!img.length > 0 && (
              <div className="generate_images_inner">
                <input
                  type="file"
                  name="imagefile"
                  accept="image/*"
                  onChange={(event) => {
                    setDragImg(event.target.files[0]);
                  }}
                />
              </div>
            )}

            <div className="generate_images_partts">
              <div className="generate_images_inner_partts">
                {!dragImg && <h3>Generate Images</h3>}

                {!dragImg && (
                  <button
                    variant="primary"
                    type="button"
                    // disabled={!imgTopic}
                    className="btn btn_primary mt-3"
                    onClick={handleImgSubmit}
                  >
                    Generate
                  </button>
                )}

                <div className="generated_images">
                  <div className="generated_image_inner">
                    {img.map((item, key) => {
                      // console.log("url",item.url)
                      return (
                        <label key={item.url} className="labl2" for={key}>
                          <input
                            type="checkbox"
                            name={key}
                            value={item.url}
                            id={key}
                            checked={selectedImage.includes(item.url)}
                            onChange={handleCheckboxChange}
                          />
                          <div>
                            <img src={item.url || item} className="img-fluid" />
                          </div>
                        </label>
                      );
                    })}
                  </div>
                </div>

                <div className="generate_buttons">
                  <button type="button" className="btn btn_primary" onClick={hanldeSwitchMedia}>
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>

          {activeFB == true && (
            <div className="schedule_date_time mt-5">
              <h3>Scheduled Date and Time</h3>
              <div className="">
                <div className="row">
                  <div className="col-md-6">
                    <div className="">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Scheduled Date</Form.Label>
                        <Form.Control
                          value={startDate}
                          type="date"
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                        <div>
                          {dateError && <p style={{ color: "red" }}>{dateError}</p>}
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label>Scheduled Time</Form.Label>
                        <select className="form-control" value={time} onChange={handleTimeChange}>
                          <option value="">Select a round time</option>
                          {roundTimeOptions.map((timeOption, index) => (
                            <option key={index} value={timeOption}>
                              {timeOption}
                            </option>
                          ))}
                        </select>
                        <div>
                          {timeError && <p style={{ color: "red" }}>{timeError}</p>}
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>
              <Button
                type="button"
                className="d-block w-100 mb-3"
                onClick={handleSchedule}
              >
                Scheduled
              </Button>
              <Button className="mr-2" onClick={handlePostPublish}>
                  Publish
                </Button>
                <Button onClick={getAllSocialAccount}>Refresh</Button>
            </div>
          )}

          {activeFB == true && (
            <div className="review_article_box mt-5">
              {connectedAcc &&
                connectedAcc.length > 0 &&
                connectedAcc.map((acc) =>
                  acc.accounts.map((account) => {
                    return (
                      <div className="org_innr_innrr text-dark">
                        {account !== "" && <div className="prf_textts"></div>}
                        <div className="org_innr_partts">
                          <input
                            className="styled-checkbox"
                            id={account.id}
                            key={`${account.id}-${acc.account_type}`}
                            type="checkbox"
                            onChange={(e) => {
                              handleAccChange(e, account.id, acc.account_type);
                            }}
                          />
                          <label for={account.id}>
                            <img
                              src={
                                acc.account_type === "Facebook"
                                  ? FbLogo
                                  : acc.account_type === "LinkedIn"
                                  ? LinkedSocial
                                  : acc.account_type === "Twitter"
                                  ? TwitterSocial
                                  : acc.account_type === "Instagram"
                                  ? InsSocial
                                  : Wp
                              }
                            />
                            <span>{account.name}</span>
                          </label>
                        </div>
                      </div>
                    );
                  })
                )}
            </div>
          )}

          {activeFB == true && (
            <div className="prf_parttts mt-5" id="scrollTargetRef">
              <div className="prf_textts">
                <h4 className="" style={{ color: "white" }}>
                  Connect your social media account :
                </h4>
              </div>
              <div className="social_connect_inner_sec social__connect_inner_parttts">
                <div className="social_inner_inner_sec">
                  <div className="social_white_sec">
                    <img src={FacebookSocial} width={35} />
                    <h4>Facebook{" "}
                            <span>
                              <OverlayTrigger
                                overlay={
                                  <BootstrapTooltip>{tooltip}</BootstrapTooltip>
                                }
                              >
                                <Button>
                                <FontAwesomeIcon icon={faInfo} />
                                </Button>
                              </OverlayTrigger>
                            </span></h4>
                    <span>Pages</span>
                    <button
                      type="button"
                      className="connect_button"
                      onClick={facebookAuth}
                    >
                      Connect
                    </button>
                  </div>
                </div>
                {/* <div className="social_inner_inner_sec">
                <div className="social_white_sec">
                  <img src={InsSocial} width={35} />
                  <h4>Instagram</h4>
                  <span>Profile</span>
                  <button
                    type="button"
                    className="connect_button"
                    onClick={instagramAuth}
                  >
                    Connect
                  </button>
                </div>
              </div>

              <div className="social_inner_inner_sec">
                <div className="social_white_sec">
                  <img src={TwitterSocial} width={35} />
                  <h4>Twitter</h4>
                  <span>Profile</span>
                  <button
                    type="button"
                    className="connect_button"
                    onClick={twitterAuth}
                  >
                    Connect
                  </button>
                </div>
              </div>
              <div className="social_inner_inner_sec">
                <div className="social_white_sec">
                  <img src={LinkedSocial} width={35} />
                  <h4>Linkedin</h4>
                  <span>Profile</span>
                  <button
                    type="button"
                    className="connect_button"
                    onClick={linkedinAuth}
                  >
                    Connect
                  </button>
                </div>
              </div>

              <div className="social_inner_inner_sec">
                <div className="social_white_sec">
                  <img src={Wp} width={35} />
                  <h4>Wordpress Blog</h4>
                  <span>Profile</span>
                  <button
                    type="button"
                    className="connect_button"
                    onClick={handleShowModal}
                  >
                    Connect
                  </button>
                  <Modal
                    show={showWpModal}
                    onHide={handleCloseModal}
                    size="lg"
                    centered
                  >
                    <Modal.Header className="d-block">
                      <Modal.Title className="text-center d-block">
                        Wordpress Login
                      </Modal.Title>

                      <Button
                        variant="secondary"
                        className="wp_close_modal"
                        onClick={handleCloseModal}
                      >
                        X
                      </Button>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="login_wp_partts">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Url</Form.Label>
                          <Form.Control
                            name="url"
                            type="text"
                            className="emailfield"
                            placeholder="Enter url"
                            onChange={handleWpChange}
                          />
                          <p style={{ color: "red" }}>{credsError.urlErr}</p>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>App Id</Form.Label>
                          <Form.Control
                            name="app_id"
                            type="text"
                            className="emailfield"
                            placeholder="Enter app id"
                            onChange={handleWpChange}
                          />
                          <p style={{ color: "red" }}>{credsError.appIdErr}</p>
                        </Form.Group>

                        <Form.Group
                          className="mb-3"
                          controlId="formBasicPassword"
                        >
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            name="app_password"
                            type="password"
                            className="emailfield"
                            placeholder="Enter password"
                            onChange={handleWpChange}
                          />
                          <p style={{ color: "red" }}>{credsError.appPassErr}</p>
                        </Form.Group>
                        <Button
                          variant="primary"
                          type="submit"
                          className="wp_submit"
                          onClick={wordpressAuth}
                        >
                          Submit
                        </Button>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
              </div> */}
              </div>
            </div>
          )}

        </div>
      </div>    
    </div>
  );
};

export default ShareOnMedia;
