import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./registration.css";
import { LoginLogo, YellowStar, Blogger, OrgReg,
    UserReg, } from "../../assets/images";

const NewRegistration = () => {
    const [slideIndex, setSlideIndex] = useState(0);
    const settings = {
      dots: false,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      autoplay: true,
      speed: 400,
      swipeToSlide: true,
    };
    return (
        <section className="new_reg">
            <div className="login_page">
              <Container>
                <Row className="align-items-center">
                  <Col md={6}>
                    <div className="login_left">
                        <div className="login_inner_left">
                            {/* <div className="login_logo">
                                <img src={LoginLogo} width={200} />
                            </div> */}
                            <div className="new_reg1">
                              <div className="reg_as_org">
                                  <Link to="organization-registration"><img src={OrgReg} width={30} className="mr-2" /> <span>Register as a Organization</span></Link>
                              </div>
                              <div className="or_reg"><span>Or</span></div>
                              <div className="reg_as_org">
                                  <Link to="individual-registration"><img src={UserReg} width={30} className="mr-2" /> <span>Register as a Individual</span></Link>
                              </div>
                            </div>
                        </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="login_right">
                      <div className="login_inner_right">
                        <Slider className="testimonial_slider" {...settings}>
                          <div className="testimonial_item">
                            <div className="yellow_star">
                              <img src={YellowStar} />
                              <img src={YellowStar} />
                              <img src={YellowStar} />
                              <img src={YellowStar} />
                              <img src={YellowStar} />
                            </div>
                            <p>
                              I really like what GPT GoodNews generates. I've tried a few of
                              AIs and some of them generate lots of content, but often out of
                              topic, or with phrases which have really no meaning. Especially
                              for technical content.
                            </p>
                            <div className="blog_writer d-flex align-items-center">
                              <div className="blogger_name">
                                <img src={Blogger} />
                              </div>
                              <div className="blogger_des">
                                <h3>Sonam Mitra</h3>
                                <span>Blog Writer</span>
                              </div>
                            </div>
                          </div>
                          <div className="testimonial_item">
                            <div className="yellow_star">
                              <img src={YellowStar} />
                              <img src={YellowStar} />
                              <img src={YellowStar} />
                              <img src={YellowStar} />
                              <img src={YellowStar} />
                            </div>
                            <p>
                              I really like what GPT GoodNews generates. I've tried a few of
                              AIs and some of them generate lots of content, but often out of
                              topic, or with phrases which have really no meaning. Especially
                              for technical content.
                            </p>
                            <div className="blog_writer d-flex align-items-center">
                              <div className="blogger_name">
                                <img src={Blogger} />
                              </div>
                              <div className="blogger_des">
                                <h3>Sonam Mitra</h3>
                                <span>Blog Writer</span>
                              </div>
                            </div>
                          </div>
                          <div className="testimonial_item">
                            <div className="yellow_star">
                              <img src={YellowStar} />
                              <img src={YellowStar} />
                              <img src={YellowStar} />
                              <img src={YellowStar} />
                              <img src={YellowStar} />
                            </div>
                            <p>
                              I really like what GPT GoodNews generates. I've tried a few of
                              AIs and some of them generate lots of content, but often out of
                              topic, or with phrases which have really no meaning. Especially
                              for technical content.
                            </p>
                            <div className="blog_writer d-flex align-items-center">
                              <div className="blogger_name">
                                <img src={Blogger} />
                              </div>
                              <div className="blogger_des">
                                <h3>Sonam Mitra</h3>
                                <span>Blog Writer</span>
                              </div>
                            </div>
                          </div>
                          <div className="testimonial_item">
                            <div className="yellow_star">
                              <img src={YellowStar} />
                              <img src={YellowStar} />
                              <img src={YellowStar} />
                              <img src={YellowStar} />
                              <img src={YellowStar} />
                            </div>
                            <p>
                              I really like what GPT GoodNews generates. I've tried a few of
                              AIs and some of them generate lots of content, but often out of
                              topic, or with phrases which have really no meaning. Especially
                              for technical content.
                            </p>
                            <div className="blog_writer d-flex align-items-center">
                              <div className="blogger_name">
                                <img src={Blogger} />
                              </div>
                              <div className="blogger_des">
                                <h3>Sonam Mitra</h3>
                                <span>Blog Writer</span>
                              </div>
                            </div>
                          </div>
                          <div className="testimonial_item">
                            <div className="yellow_star">
                              <img src={YellowStar} />
                              <img src={YellowStar} />
                              <img src={YellowStar} />
                              <img src={YellowStar} />
                              <img src={YellowStar} />
                            </div>
                            <p>
                              I really like what GPT GoodNews generates. I've tried a few of
                              AIs and some of them generate lots of content, but often out of
                              topic, or with phrases which have really no meaning. Especially
                              for technical content.
                            </p>
                            <div className="blog_writer d-flex align-items-center">
                              <div className="blogger_name">
                                <img src={Blogger} />
                              </div>
                              <div className="blogger_des">
                                <h3>Sonam Mitra</h3>
                                <span>Blog Writer</span>
                              </div>
                            </div>
                          </div>
                        </Slider>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
        </section>
        );
};

export default NewRegistration;