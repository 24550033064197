import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Form,
  Dropdown,
  Table,
  Accordion,
  Card,
} from "react-bootstrap";
import DashboardLeftPanel from "../../../components/DashboardLeftPanel/dashboardLeftPanel";
import DashRightPanel from "../../../components/DashboardRightPanel/dashbaordrightpanel";
import axios from "../../../axios/axios";
import { Audio } from "react-loader-spinner";
import message from "../../../messages";
import { useParams } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Toggle } from "../../../assets/images";
function UpdateMember() {
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [isValidPhn, setIsValidPhn] = useState(false);
  const fromValidate = () => {
    setNameError("");
    setPhoneError("");
    setEmailError("");
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (!name) {
      setNameError("Please enter member’s name");
      return false;
    }
    if (!email) {
      setEmailError("Please enter your member's email");
      return false;
    } else if (reg.test(email) === false) {
      setEmailError("member's email is invalid");
      return false;
    }
    if (!phone) {
      setPhoneError("Please enter member’s phone number");
      return false;
    } else if (phone.length < 10 && phone.length > 14) {
      setPhoneError("Member’s phone is invalid");
      return false;
    }

    return true;
  };
  // Effect to load the state from localStorage on initial render
  useEffect(() => {
    const storedCollapsed = localStorage.getItem("collapsed");
    if (storedCollapsed) {
      setCollapsed(JSON.parse(storedCollapsed));
    }
  }, []);

  // Effect to save the state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("collapsed", JSON.stringify(collapsed));
  }, [collapsed]);

  // Effect to load the state from localStorage on initial render
  useEffect(() => {
    const storedExpanded = localStorage.getItem("expanded");
    if (storedExpanded) {
      setExpanded(JSON.parse(storedExpanded));
    }
  }, []);

  // Effect to save the state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("expanded", JSON.stringify(collapsed));
  }, [collapsed]);
  const getMember = async () => {
    await axios
      .get(`/member-edit/${id}`)
      .then((res) => {
        setLoader(false);
        setName(res.data.data.name);
        setEmail(res.data.data.email);
        setPhone(res.data.data.phone);
        // console.log(res.data.data);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };

  useEffect(() => {
    getMember();
  }, []);

  const handlePhone = (value, data, event, formattedValue) => {
    setPhone(formattedValue);
    const numericPhoneNumber = value ? value.replace(/\D/g, "") : "";
      numericPhoneNumber.length > 10
        ? setIsValidPhn(false)
        : setIsValidPhn(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (fromValidate() && !isValidPhn) {
      let paramsData = {
        user_id: id,
        name,
        email,
        phone,
      };
      setLoader(true);
      // Submit the form or perform other actions
      await axios
        .post("/member-update", paramsData)
        .then((res) => {
          setLoader(false);
          message.success(res.data.message);

          setTimeout(() => {
            window.open("/manage-members", "_self");
          }, 2000);
        })
        .catch((err) => {
          setLoader(false);
          console.log("err", err);
        });
    }
  };

  const cancelButton = () => {
    window.open("/manage-members", "_self");
  };

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
    setExpanded(!expanded);
  };
  return (
    <div className="dash_inner_partts">
      <div className={`sidebar ${collapsed ? "collapsed" : ""}`}>
        <DashboardLeftPanel />
      </div>

      <div className={`dash_right_panel ${expanded ? "expanded" : ""}`}>
        <button onClick={toggleSidebar} className="toggle__button">
          <img src={Toggle} />
        </button>
        <div className="dash_right_inner_panel">
          <Row>
            <Col lg="9" md="12" sm="12">
              <div className="email_verified update_verifieds">
                {loader == true && (
                  <div className="loader-wrapper">
                    <Audio
                      height="80"
                      width="80"
                      radius="9"
                      color="green"
                      ariaLabel="loading"
                      wrapperStyle
                      wrapperClass
                    />
                  </div>
                )}
                <div className="email_verified_inner update_member_inner">
                  <div className="email_verified_inner_inner mt-5">
                    <h3 className="mb-4">Edit Member's</h3>
                    <Form>
                      <Form.Group
                        className="type_email_box"
                        controlId="formBasicEmail"
                      >
                        <Form.Control
                          type="text"
                          name="name"
                          value={name}
                          placeholder="Type member’s name"
                          className="type_email"
                          onChange={(e) => setName(e.target.value)}
                        />
                        <div>
                          {nameError && (
                            <p style={{ color: "red" }}>{nameError}</p>
                          )}
                        </div>
                      </Form.Group>
                      <Form.Group
                        className="type_email_box"
                        controlId="formBasicEmail"
                      >
                        <Form.Control
                          type="text"
                          name="email"
                          value={email}
                          placeholder="Type member’s email"
                          className="type_email"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <div>
                          {emailError && (
                            <p style={{ color: "red" }}>{emailError}</p>
                          )}
                        </div>
                      </Form.Group>
                      <Form.Group
                        className="type_email_box"
                        controlId="formBasicEmail"
                      >
                        <PhoneInput
                          value={phone}
                          country={'us'}
                          onChange={handlePhone}
                          inputStyle={{ width: "100%" }}
                          placeholder="Type member’s phone number"
                          className="type_email"
                        />
                        <div>
                          {isValidPhn && (
                            <span style={{ color: "red" }}>
                              Invalid member’s phone number
                            </span>
                          )}
                          {phoneError && (
                            <p style={{ color: "red" }}>{phoneError}</p>
                          )}
                        </div>
                      </Form.Group>
                      <div>
                        <Button
                          type="button"
                          variant="default"
                          className="btn_secondary mr-2"
                          onClick={cancelButton}
                        >
                          Cancel
                        </Button>
                        <Button 
                          type="submit" 
                          variant="default"
                          onClick={handleSubmit}
                          className="btn_primary">
                          Submit
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="3" md="12" sm="12" className="pr-0">
              <div className="dash_right_paertts">
                <DashRightPanel />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default UpdateMember;
