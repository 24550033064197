// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/black_theme_overlay2.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dark-theme .rbc-today {
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
}

.dark-theme .rbc-btn-group button {
    color: #fff;
}

.dark-theme .rbc-btn-group button.rbc-active {
    color: #000;
}

.dark-theme .event_calendar {
    position: relative;
}
.dark-theme .event_calendar:before {
    content: "";
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;
    position: absolute;
    width: 100%;
    height: 584px;
    bottom: -200px;
    right: 0;
    left: 0;
    pointer-events: none;
}

/* .modal_body {
    height: 30em;
} */

.modal-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.content-description {
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    background: #fff;
}


.dark-theme h5{
    color: #fff;
}


.dark-theme .content-description{
    background: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/ContentCalendar/content-calendar.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,oCAAoC;AACxC;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,6DAA0E;IAC1E,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,cAAc;IACd,QAAQ;IACR,OAAO;IACP,oBAAoB;AACxB;;AAEA;;GAEG;;AAEH;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,oBAAoB;IACpB,sBAAsB;IACtB,4BAA4B;IAC5B,gBAAgB;AACpB;;;AAGA;IACI,WAAW;AACf;;;AAGA;IACI,uBAAuB;AAC3B","sourcesContent":[".dark-theme .rbc-today {\n    color: #fff;\n    background: rgba(255, 255, 255, 0.2);\n}\n\n.dark-theme .rbc-btn-group button {\n    color: #fff;\n}\n\n.dark-theme .rbc-btn-group button.rbc-active {\n    color: #000;\n}\n\n.dark-theme .event_calendar {\n    position: relative;\n}\n.dark-theme .event_calendar:before {\n    content: \"\";\n    background: url(../../../assets/images/black_theme_overlay2.png) no-repeat;\n    position: absolute;\n    width: 100%;\n    height: 584px;\n    bottom: -200px;\n    right: 0;\n    left: 0;\n    pointer-events: none;\n}\n\n/* .modal_body {\n    height: 30em;\n} */\n\n.modal-top {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.content-description {\n    width: 100%;\n    overflow: hidden;\n    display: -webkit-box;\n    -webkit-line-clamp: 10;\n    -webkit-box-orient: vertical;\n    background: #fff;\n}\n\n\n.dark-theme h5{\n    color: #fff;\n}\n\n\n.dark-theme .content-description{\n    background: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
