import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Form,
  Dropdown,
  Table,
  Accordion,
  Card,
} from "react-bootstrap";
import DashboardLeftPanel from "../../../components/DashboardLeftPanel/dashboardLeftPanel";
import DashRightPanel from "../../../components/DashboardRightPanel/dashbaordrightpanel";
import axios from "../../../axios/axios";
import message from "../../../messages";
import { Audio } from "react-loader-spinner";

function RaiseTicket() {
  const [collapsed, setCollapsed] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [attachment, setAttachment] = useState("")
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const storedCollapsed = localStorage.getItem("collapsed");
    if (storedCollapsed) {
      setCollapsed(JSON.parse(storedCollapsed));
    }
  }, []);

  // Effect to save the state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("collapsed", JSON.stringify(collapsed));
  }, [collapsed]);

  // Effect to load the state from localStorage on initial render
  useEffect(() => {
    const storedExpanded = localStorage.getItem("expanded");
    if (storedExpanded) {
      setExpanded(JSON.parse(storedExpanded));
    }
  }, []);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
    setExpanded(!expanded);
  };

  function validateSubject(subject) {
    if (!subject) {
      return "Subject is required";
    }
    return "";
  }
  function validateDescription(description) {
    if (!description) {
      return "Description is required";
    }
    return "";
  }

  function handleSubjectChange(event) {
    const value = event.target.value;
    setSubject(value);
    const errorMessage = validateSubject(value);
    setErrors((prevErrors) => ({ ...prevErrors, subject: errorMessage }));
  }
  function handleDescriptionChange(event) {
    const value = event.target.value;
    setDescription(value);
    const errorMessage = validateDescription(value);
    setErrors((prevErrors) => ({ ...prevErrors, description: errorMessage }));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const subjectError = validateSubject(subject);
    const descriptionError = validateDescription(description);
    if (!subjectError && !descriptionError) {
      // let paramsData = {
      //   subject,
      //   description,
      //   attachment,
      // };
      const formData = new FormData();
      formData.append("subject", subject);
      formData.append("description", description);
      formData.append("attachment", attachment);
      setLoader(true);
      // Submit the form or perform other actions
      await axios
        .post("/create-ticket", formData)
        .then((res) => {
          setLoader(false);
          message.success(res.data.message);

          setTimeout(() => {
            window.open("/support", "_self");
          }, 2000);
        })
        .catch((err) => {
          setLoader(false);
          console.log("err", err);
          message.error(err.response.data.message);
        });
    }else{
        setErrors({
            subject: subjectError,
            description: descriptionError
        })
    }
  };

  return (
    <section className="dash_partts">
      <div className="dash_inner_partts">
        <div className={`sidebar ${collapsed ? "collapsed" : ""}`}>
          <DashboardLeftPanel />
        </div>

        <div className={`dash_right_panel ${expanded ? "expanded" : ""}`}>
          <div className="dash_right_inner_panel">
            <Row>
              <Col lg="9" md="12" sm="12">
                <div className="dash_middle_panel">
                  <Form onSubmit={handleSubmit}>
                    <Form.Group
                      className="type_email_box"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Subject<span style={{ color: "red" }}>*</span></Form.Label>
                      <Form.Control
                        type="text"
                        name="subject"
                        value={subject}
                        placeholder="Type your Subject"
                        className="type_email"
                        onChange={handleSubjectChange}
                      />
                      <div>
                    {errors.subject && (
                      <span style={{ color: "red" }}>{errors.subject}</span>
                    )}
                  </div>
                    </Form.Group>
                    <Form.Group
                      className="type_email_box"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Description<span style={{ color: "red" }}>*</span></Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name="description"
                        value={description}
                        placeholder="Type your Description"
                        className="type_email"
                        onChange={handleDescriptionChange}
                      />
                      <div>
                    {errors.description && (
                      <span style={{ color: "red" }}>{errors.description}</span>
                    )}
                  </div>
                    </Form.Group>
                    <Form.Group
                      className="type_email_box"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Upload Issue <span style={{color:"red", fontSize:"10px"}}>(Optional)</span></Form.Label>
                      <Form.Control
                        type="file" 
                        name="myfile" 
                        accept="image/*"
                        onChange={(event) => {
                          setAttachment(event.target.files[0]);
                        }}
                      />
                      <div>
                  </div>
                    </Form.Group>
                  <Button type="submit">Submit</Button>
                  </Form>
                </div>
              </Col>
              <Col lg="3" md="12" sm="12" className="pr-0">
                <div className="dash_right_paertts">
                  <DashRightPanel />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </section>
  );
}

export default RaiseTicket;
