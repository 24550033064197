import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom"
import { Container, Row, Col, Modal, Button, Form, Dropdown, Table, Accordion, Card, Tab, Nav } from 'react-bootstrap';
import DashboardLeftPanel from "../../../components/DashboardLeftPanel/dashboardLeftPanel";
import DashRightPanel from "../../../components/DashboardRightPanel/dashbaordrightpanel";
import './support.css';
import {
    Support1,
    SearchButtonIcon,
    Toggle,
    SupportChatts,
} from "../../../assets/images";

const SupportPage = () => {
    const history = useHistory()
    const [collapsed, setCollapsed] = useState(false);
    const [expanded, setExpanded] = useState(false);


    // Effect to load the state from localStorage on initial render
    useEffect(() => {
        const storedCollapsed = localStorage.getItem('collapsed');
        if (storedCollapsed) {
            setCollapsed(JSON.parse(storedCollapsed));
        }
    }, []);

    // Effect to save the state to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('collapsed', JSON.stringify(collapsed));
    }, [collapsed]);



    // Effect to load the state from localStorage on initial render
    useEffect(() => {
        const storedExpanded = localStorage.getItem('expanded');
        if (storedExpanded) {
            setExpanded(JSON.parse(storedExpanded));
        }
    }, []);

    // Effect to save the state to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('expanded', JSON.stringify(collapsed));
    }, [collapsed]);
    const steps = [
        {
            id: 'intro',
            message: 'Hello, how can I assist you today?',
            trigger: 'options',
        },
        {
            id: 'options',
            options: [
                {
                    value: '1',
                    label: 'Option 1',
                    trigger: 'response',
                },
                {
                    value: '2',
                    label: 'Option 2',
                    trigger: 'response',
                },
                {
                    value: '3',
                    label: 'Option 3',
                    trigger: 'response',
                },
            ],
        },
        {
            id: 'response',
            message: 'You selected option.',
            end: true,
        },
    ];


    const theme = {
        background: '#fff',
        headerBgColor: 'rgba(63, 62, 237, 1)',
        headerFontSize: '20px',
        botBubbleColor: 'blue',
        headerFontColor: '#ffffff',
        botFontColor: 'white',
        userBubbleColor: '#FF5733',
        userFontColor: 'white',
    };

    // Set some properties of the bot
    const config = {
        botAvatar: "img.png",
        floating: true,
    };
    const toggleSidebar = () => {
        setCollapsed(!collapsed);
        setExpanded(!expanded);
    };
    return (
        <section className="dash_partts">
            <div className='dash_inner_partts'>
                <div className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
                    <DashboardLeftPanel />
                </div>

                <div className={`dash_right_panel ${expanded ? 'expanded' : ''}`}>
                    <button onClick={toggleSidebar} className='toggle__button'><img src={Toggle} /></button>
                    <div className='dash_right_inner_panel'>
                        <Row>
                            <Col lg="9" md="12" sm="12">
                                <div className='dash_middle_panel'>
                                    <div className='support_page'>
                                        <h3>Support</h3>
                                        <p>Are you facing any issue? We are here to help you and solve your problems, please connect to our admin support through chat or call.</p>
                                        <div className='border_bottom'></div>
                                        <div className='helping_search_field'>
                                            <Form.Control type='text' className='search__field' placeholder='What we can help you with?'></Form.Control>
                                            <img src={SearchButtonIcon} className='search_iconns' />
                                        </div>

                                        <div className='call_chat'>
                                            <div className='call_chat_box' onClick={() => {
                                                history.push("/raise-tickets")
                                            }}>
                                                <div className='call_chat_box_inner'>
                                                    <div className='icon_holder'>
                                                        <img src={Support1} height={30} />
                                                    </div>
                                                    <div className='suppport_text'>
                                                        <h4>Raise Tickets</h4>
                                                        <p>We are here for you 24/7</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='call_chat_box' onClick={() => {
                                                history.push("/chat-with-us")
                                            }}>
                                                <div className='call_chat_box_inner'>
                                                    <div className='icon_holder holder_2'>
                                                        <img src={SupportChatts} height={30} />
                                                    </div>
                                                    <div className='suppport_text'>
                                                        <h4>Chat with Us</h4>
                                                        <p>Tell us about your issue or problem, we are here for you 24/7</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="3" md="12" sm="12" className='pr-0'>
                                <div className='dash_right_paertts'>
                                    <DashRightPanel />
                                </div>
                            </Col>
                        </Row>
                    </div>

                </div>
            </div>


        </section>
    );
};

export default SupportPage;