import React, { Component } from "react";
//import "./styles.css";
import { Container, Navbar, Nav, Dropdown } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
// import LeftpanelAdmin from "../../../components/leftpanel/left-panel-admin";
// import HeaderPart from "../../../components/layout/header-admin/header";
import "./header.css";
import {
  Logo,
  Grad1,
  Grad2,
  Grad3,
  FacebookSocial,
  InsSocial,
  Wp,
  LinkedSocial,
  TwitterSocial,
} from "../../../assets/images";

const Header = () => {
  return (
    <div className="header">
      <Container>
        <Navbar
          expand="lg"
          className="custom_navbar justify-content-between align-items-center"
        >
          <Navbar.Brand href="/">
            <img src={Logo} className="img-fluid" alt="logo" width={200} />
          </Navbar.Brand>
          <div className="topbarAction">
            <Link to="/login" className="login_anchor">
              Login
            </Link>
            <Link to="/registration" className="start_but">
              Start For Free
            </Link>
          </div>
        </Navbar>
      </Container>
      <div className="abs_image">
        <img src={Grad1} className="grad_1" width={800} />
        <img src={Grad2} className="grad_2" width={700} />
        <img src={Grad3} className="grad_3" width={700} />
      </div>
    </div>
  );
};

export default Header;
