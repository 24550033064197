import React, { useState, useEffect } from "react";
//import "./styles.css";
import {
  Container,
  Navbar,
  Nav,
  Dropdown,
  Button,
  Modal,
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
// import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
// import 'react-pro-sidebar/dist/css/styles.css';
// import LeftpanelAdmin from "../../../components/leftpanel/left-panel-admin";
// import HeaderPart from "../../../components/layout/header-admin/header";
import "./dashright.css";
import {
  DashLogo,
  TwitterIcon,
  FacebookIcon,
  InsIconn,
  MemberRight,
  no_image,
  no_prf,
  Toggle,
  FacebookSocial,
  InsSocial,
  Wp,
  LinkedSocial,
  TwitterSocial,
  Prf_Img_blank,
  White_right_arrow,
} from "../../assets/images";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

import axios from "../../axios/axios";
import message from "../../messages";
import moment from "moment";
import { useHistory } from "react-router-dom";
const localizer = momentLocalizer(moment);

// import 'react-pro-sidebar/dist/css/styles.css';
const DashboardRightPanel = () => {
  const history = useHistory();
  const [value, onChange] = useState(new Date());
  const [loader, setLoader] = useState(false);
  const [rightPanel, setRightPanel] = useState([]);
  const [orgInfo, setOrgInfo] = useState([]);
  const [member, setMember] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [calData, setCalData] = useState({});
  const [events, setEvents] = useState([
    {
      _id: "",
      start: null,
      end: null,
      title: "",
      description: "",
      images: [],
    },
    // Add more events as needed
  ]);
  const [authUserData, setauthUserData] = useState(
    localStorage.getItem("AuthUserData")
      ? JSON.parse(localStorage.getItem("AuthUserData"))
      : null
  );

  useEffect(() => {
    const scheduledPost = async () => {
      try {
        const posts = await axios.get("/scheduled-post");
        const { data } = posts.data;
        await Promise.all(
          data.map((post) => {
            setEvents((prevState) => [
              ...prevState,
              {
                _id: post._id,
                start: new Date(post.schedule_at),
                end: new Date(post.schedule_at),
                title: post.title,
                description: post.content,
                images: post.images,
                ...post,
              },
            ]);
          })
        );
      } catch (error) {
        // console.error(error, "<<-- Error in getting scheduled post")
        message.error("Error occured");
      }
    };
    scheduledPost();
  }, []);

  useEffect(() => {
    console.log(events, "<<-- events");
  }, [events]);

  const getMemberListing = async () => {
    try {
      setLoader(true);
      await axios
        .get("/member-list")
        .then((res) => {
          setLoader(false);
          setMember(res.data.data);
          // console.log(res.data.data);
        })
        .catch((err) => {
          setLoader(false);
          console.log("err", err);
        });
    } catch (err) {}
  };

  const getOrgInfo = async () => {
    try {
      setLoader(true);
      await axios
        .get("/org-info")
        .then((res) => {
          setLoader(false);
          setRightPanel(res.data.data.user_info);
          setOrgInfo(res.data.data);
        })
        .catch((err) => {
          setLoader(false);
          console.log("err", err);
        });
    } catch (err) {}
  };

  useEffect(() => {
    getMemberListing();
    getOrgInfo();
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleEditPost = () => {
    setShowModal(false);
    history.push(`/generate-article/${calData._id}`);
  };

  return (
    <section className="dash_rght_parrts">
      <div className="dash_logo_partts">
        <div className="container">
          {localStorage.getItem("role") !== "Individual" &&(
            <div className="down_arrow">
              <img
                src={
                  orgInfo.profile_picture
                    ? orgInfo.profile_picture
                    : Prf_Img_blank
                }
                className=""
              />
            </div>
          )}

          {localStorage.getItem("role") !== "Individual" &&(
            
            <div className="down_arrow">
              <h6 style={{ color: "white" }}>Organization Name:</h6>
              <p>{orgInfo.name}</p>
              <img src={White_right_arrow} alt="Right Arrow" />
            </div>
          )}

          <div className="down_arrow">
            <img
              src={
                rightPanel.profile_picture
                  ? rightPanel.profile_picture
                  : Prf_Img_blank
              }
              style={{ width: "30%", height: "auto" }}
            />
          </div>
        </div>
        <div className="logo_address">
          <h6 style={{ color: "white" }}>Your Name:</h6>
          <p>{rightPanel.name}</p>
          {/* <div className="social__iconnns">
            <Link to="#">
              <img src={TwitterIcon} />
            </Link>
            <Link to="#">
              <img src={InsIconn} />
            </Link>
            <Link to="#">
              <img src={FacebookIcon} />
            </Link>
          </div> */}
        </div>

        {authUserData != null ? (
          <>
            <div className="plan_expires">
              <div className="heading_plan mb-0 text-center">
                Remaining uses available
              </div>
              <div className="plan_iner_expires">
                <div className="dayss">
                  <span>
                    {localStorage.getItem("CreditDataBalance")
                      ? localStorage.getItem("CreditDataBalance")
                      : "0"}
                  </span>
                </div>
              </div>
              <div className="heading_plan mb-0 text-center">
                <Link to="/plan">
                  <Button>Buy Uses</Button>
                </Link>
              </div>
            </div>
          </>
        ) : (
          <>
            {/* <div className="plan_expires">
              <div className="heading_plan">Plan Expire</div>
              <div className="plan_iner_expires">
                <div className="dayss">
                  <span>20</span>
                  <label>Days</label>
                </div>
                <div className="dayss">
                  <span>10</span>
                  <label>Hours</label>
                </div>
                <div className="dayss">
                  <span>12</span>
                  <label>Minutes</label>
                </div>
                <div className="dayss">
                  <span>30</span>
                  <label>Seconds</label>
                </div>
              </div>
            </div> */}
          </>
        )}

        <div className="calendar right-calender">
          <Calendar
            localizer={localizer}
            events={events}
            // onChange={onChange}
            // value={value}
            onSelectEvent={(event) => {
              setShowModal(true);
              setCalData(event);
            }}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 400 }}
          />
        </div>
        {localStorage.getItem("role") == "Admin" ? (
          <div className="involved_member">
            <h3>Member's Involved</h3>

            <div className="member_partts_involved">
              {member.map((ele) => {
                return (
                  <div>
                    <div className="member_partts_inner">
                      <div className="member_left">
                        <img src={no_prf} />
                      </div>
                      <div className="member_right">
                        <h4>{ele.name}</h4>
                        <span>{ele.email}</span>
                      </div>
                    </div>
                  </div>
                );
              })}

              {/* <div className='member_partts_inner'>
                            <div className='member_left'>
                                <img src={MemberRight} />
                            </div>
                            <div className='member_right'>
                                <h4>Arishya Mishra</h4>
                                <span>UI/UX Designer</span>
                            </div>
                        </div>

                        <div className='member_partts_inner'>
                            <div className='member_left'>
                                <img src={MemberRight} />
                            </div>
                            <div className='member_right'>
                                <h4>Arishya Mishra</h4>
                                <span>UI/UX Designer</span>
                            </div>
                        </div>

                        <div className='member_partts_inner'>
                            <div className='member_left'>
                                <img src={MemberRight} />
                            </div>
                            <div className='member_right'>
                                <h4>Arishya Mishra</h4>
                                <span>UI/UX Designer</span>
                            </div>
                        </div> */}
              <div className="view_more d-flex justify-content-end">
                <Link to="/manage-members">View More...</Link>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      {localStorage.getItem("role") == "Admin" ? (
        <Link className="gen_article" to="/manage-members">
          Add Member +
        </Link>
      ) : (
        <></>
      )}
      <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
        <Modal.Header className="d-block">
          <Modal.Title className="text-center d-block">
            {calData.title}
          </Modal.Title>

          <Button
            variant="secondary"
            className="wp_close_modal"
            onClick={handleCloseModal}
          >
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="modal_body">
            <div className="modal-top">
              <div>
                <h5>Scheduled Date</h5>
                <p>{moment(calData.start).format("MM/DD/YYYY")}</p>
              </div>
              <div>
                <h5>Scheduled Time</h5>
                <p>{moment(calData.end).format("LTS")}</p>
              </div>
            </div>
            <div>
              <h5>Description</h5>
              <p
                className="content-description"
                style={{ textAlign: "justify" }}
              >
                {calData.description}
              </p>
            </div>
            <div>
              <h5>Scheduled platform</h5>
              <div className="man_username">
                <span>
                  {calData.wordpress?.length <= 0 ? (
                    <></>
                  ) : (
                    <img style={{ width: "30px", height: "30px" }} src={Wp} />
                  )}
                </span>
                <span>
                  {calData.facebook?.length <= 0 ? (
                    <></>
                  ) : (
                    <img
                      style={{ width: "30px", height: "30px" }}
                      src={FacebookSocial}
                    />
                  )}
                </span>
                <span>
                  {calData.twitter?.length <= 0 ? (
                    <></>
                  ) : (
                    <img
                      style={{ width: "30px", height: "30px" }}
                      src={TwitterSocial}
                    />
                  )}
                </span>
                <span>
                  {calData.instagram?.length <= 0 ? (
                    <></>
                  ) : (
                    <img
                      style={{ width: "30px", height: "30px" }}
                      src={InsSocial}
                    />
                  )}
                </span>
                <span>
                  {calData.linkedin?.length <= 0 ? (
                    <></>
                  ) : (
                    <img
                      style={{ width: "30px", height: "30px" }}
                      src={LinkedSocial}
                    />
                  )}
                </span>
              </div>
            </div>
            <div style={{ marginTop: "20px" }}>
              <h5>Post images</h5>
              <div className="socialll_images mt-5">
                <span>
                  {calData.images?.map((item) => {
                    return <img src={item} className="img-fluid" />;
                  })}
                  {/* {dragImg && <img className="img-fluid" src={URL.createObjectURL(dragImg)} />} */}
                </span>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEditPost}>
            Edit
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default DashboardRightPanel;
