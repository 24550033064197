import React, {useContext} from 'react'
import Header from './header/header';
import Footer from "./footer/footer";
import DashboardHeader from './header/DashboardHeader';
import { ThemeContext } from '../../ThemeContext';
function Layout(props){
  const { children, isDashboard } = props;
  const { isDarkTheme } = useContext(ThemeContext);
  return (
    <>
      {isDashboard ? <DashboardHeader/> : <Header />}
      <div className='content'>{children}</div>
      {isDashboard ? '' : <Footer />}
      
    </>
  );
}
export default Layout;