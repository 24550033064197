import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Form,
  Dropdown,
  Table,
  Accordion,
  Card,
} from "react-bootstrap";
import DashboardLeftPanel from "../../components/DashboardLeftPanel/dashboardLeftPanel";
import DashRightPanel from "../../components/DashboardRightPanel/dashbaordrightpanel";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import "./dashboard.css";
import axios from "../../axios/axios";
import message from "../../messages";
import { Audio } from "react-loader-spinner";
import moment from "moment";

import {
  LoginLogo,
  Art1,
  Art2,
  Art3,
  Art4,
  Gadget,
  Facebook,
  Clock,
  InsIcon,
  TwitterIcon,
  FacebookIcon,
  InsIconn,
  Toggle,
  Wp,
  LinkedSocial,
  FacebookSocial,
  TwitterSocial,
  InsSocial,
  no_image,
    no_prf
} from "../../assets/images";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { faker } from "@faker-js/faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const DashboardPage = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [loader, setLoader] = useState(false);
  const [mediaData, setMediaData] = useState([]);
  const [recentPost, setRecentPost] = useState([]);
  const [weeklyPost, setWeeklyPost] = useState([]);
  const [socialEngagement, setSocialEngagement] = useState([]);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
    setExpanded(!expanded);
  };
// Effect to load the state from localStorage on initial render
useEffect(() => {
  const storedCollapsed = localStorage.getItem('collapsed');
  if (storedCollapsed) {
      setCollapsed(JSON.parse(storedCollapsed));
  }
}, []);

// Effect to save the state to localStorage whenever it changes
useEffect(() => {
  localStorage.setItem('collapsed', JSON.stringify(collapsed));
}, [collapsed]);



// Effect to load the state from localStorage on initial render
useEffect(() => {
  const storedExpanded = localStorage.getItem('expanded');
  if (storedExpanded) {
      setExpanded(JSON.parse(storedExpanded));
  }
}, []);

// Effect to save the state to localStorage whenever it changes
useEffect(() => {
  localStorage.setItem('expanded', JSON.stringify(collapsed));
}, [collapsed]);
  const getDashboardReport = async () => {
    try{
      setLoader(true);
      await axios
        .get("/dashboard-reports")
        .then((res) => {
          setLoader(false);
          setMediaData(res.data.data);
          setRecentPost(res.data.data.recent_post_articles);
          setWeeklyPost(res.data.data.weekly_post_reports);
          setSocialEngagement(res.data.data.social_engagement);
        })
        .catch((err) => {
          setLoader(false);
          console.log("err", err);
          message.error(err.response.data.message);
        });
    }catch(err){
      
    }
  };

  useEffect(() => {
    getDashboardReport();
  }, []);

  const labels = ["S", "M", "T", "W", "T", "F", "S"];
  const options = {
    responsive: true,
    plugins: {
      legend: {
        // position: 'top' as const,
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: "Weekly Post",
        data: weeklyPost.map((ele) => ele),
        backgroundColor: "rgba(63, 62, 237, 1)",
      },
    ],
  };

  // const data1 = [
  //     {
  //       id: 1,
  //       campaign: (
  //         <div className="d-flex align-items-center campaign_text">
  //           <span>
  //             <img src={FacebookIcon} />
  //           </span>
  //           Facebook
  //         </div>
  //       ),
  //       articles: <div className="campaign_text">98</div>,
  //       views: <div className="campaign_text">22,895</div>,
  //       likes: <div className="campaign_text">11,121</div>,
  //       comments: <div className="campaign_text">7,654</div>,
  //     },
  //     {
  //       id: 2,
  //       campaign: (
  //         <div className="d-flex align-items-center campaign_text">
  //           <span>
  //             <img src={InsIconn} />
  //           </span>
  //           Instagram
  //         </div>
  //       ),
  //       articles: <div className="campaign_text">128</div>,
  //       views: <div className="campaign_text">17,653</div>,
  //       likes: <div className="campaign_text">6,765</div>,
  //       comments: <div className="campaign_text">3,899</div>,
  //     },

  //     {
  //       id: 2,
  //       campaign: (
  //         <div className="d-flex align-items-center campaign_text">
  //           <span>
  //             <img src={InsIconn} />
  //           </span>
  //           Instagram
  //         </div>
  //       ),
  //       articles: <div className="campaign_text">128</div>,
  //       views: <div className="campaign_text">17,653</div>,
  //       likes: <div className="campaign_text">6,765</div>,
  //       comments: <div className="campaign_text">3,899</div>,
  //     },
  //     {
  //       id: 2,
  //       campaign: (
  //         <div className="d-flex align-items-center campaign_text">
  //           <span>
  //             <img src={InsIconn} />
  //           </span>
  //           Instagram
  //         </div>
  //       ),
  //       articles: <div className="campaign_text">128</div>,
  //       views: <div className="campaign_text">17,653</div>,
  //       likes: <div className="campaign_text">6,765</div>,
  //       comments: <div className="campaign_text">3,899</div>,
  //     },
  //     {
  //       id: 2,
  //       campaign: (
  //         <div className="d-flex align-items-center campaign_text">
  //           <span>
  //             <img src={InsIconn} />
  //           </span>
  //           Instagram
  //         </div>
  //       ),
  //       articles: <div className="campaign_text">128</div>,
  //       views: <div className="campaign_text">17,653</div>,
  //       likes: <div className="campaign_text">6,765</div>,
  //       comments: <div className="campaign_text">3,899</div>,
  //     },
  //   ];

  const getEngagement = () => {
    let engagement = [];
    socialEngagement.map((item, i) => {
      Object.keys(item).map((ele, ind) => {
        engagement.push({
          id: i + 1,
          campaign: (
            <div className="d-flex align-items-center campaign_text">
              <span>
                {ele == "facebook" ? (
                  <img
                    style={{ width: "30px", height: "30px" }}
                    src={FacebookSocial}
                  />
                ) : ele == "twitter" ? (
                  <img
                    style={{ width: "30px", height: "30px" }}
                    src={TwitterSocial}
                  />
                ) : ele == "instagram" ? (
                  <img
                    style={{ width: "30px", height: "30px" }}
                    src={InsSocial}
                  />
                ) : ele == "wordpress" ? (
                  <img style={{ width: "30px", height: "30px" }} src={Wp} />
                ) : ele == "linkedin" ? (
                  <img
                    style={{ width: "30px", height: "30px" }}
                    src={LinkedSocial}
                  />
                ) : (
                  <></>
                )}
              </span>
              {ele}
            </div>
          ),
          articles: <div className="campaign_text">{item[ele].posts}</div>,
          likes: <div className="campaign_text">{item[ele].likes}</div>,
          comments: <div className="campaign_text">{item[ele].comments}</div>,
        });
      });
    });
    return engagement;
  };

  const columns = [
    {
      name: "Campaign",
      selector: (row) => row.campaign,
      width: "200px",
      style: {
        padding: "10px 7px",
        // height: "50px",
        // display: "block",
      },
    },
    {
      name: "Total no. of articles",
      selector: (row) => row.articles,
      width: "180px",
      style: {
        padding: "10px 7px",
        // height: "50px",
        // display: "block",
      },
    },
    {
      name: "Total likes",
      selector: (row) => row.likes,
      style: {
        padding: "10px 7px",
        // height: "50px",
        // display: "block",
      },
    },
    {
      name: "Total Comments",
      selector: (row) => row.comments,
      style: {
        padding: "10px 7px",
        // height: "50px",
        // display: "block",
      },
    },
  ];
  return (
    <section className="dash_partts">
      {loader == true && (
        <div className="loader-wrapper">
          <Audio
            height="80"
            width="80"
            radius="9"
            color="green"
            ariaLabel="loading"
            wrapperStyle
            wrapperClass
          />
        </div>
      )}
      <div className="dash_inner_partts">
        <div className={`sidebar ${collapsed ? "collapsed" : ""}`}>
          <DashboardLeftPanel />
        </div>

        <div className={`dash_right_panel ${expanded ? "expanded" : ""}`}>
          <button onClick={toggleSidebar} className="toggle__button">
            <img src={Toggle} />
          </button>
          <div className="dash_right_inner_panel">
            <Row>
              <Col lg="9" md="12" sm="12">
                <div className="dash_middle_panel">
                  <div className="art_generate">
                    <div className="art_gen_inner">
                      <div className="art_gen_inner_inner">
                        <div className="art_gen_inner_box">
                          <div className="generate_topper">
                            <div className="generate_top">
                              <img src={Art1} />
                            </div>
                            <div className="generate_no">
                              {mediaData.article_generate}
                            </div>
                          </div>
                          <div className="generate_bottom">
                          Article Generated
                          </div>
                        </div>
                      </div>

                      <div className="art_gen_inner_inner">
                        <div className="art_gen_inner_box">
                          <div className="generate_topper">
                            <div className="generate_top">
                              <img src={Art2} />
                            </div>
                            <div className="generate_no">
                              {mediaData.article_shared}
                            </div>
                          </div>
                          <div className="generate_bottom">Article Shared</div>
                        </div>
                      </div>

                      <div className="art_gen_inner_inner">
                        <div className="art_gen_inner_box">
                          <div className="generate_topper">
                            <div className="generate_top">
                              <img src={Art3} />
                            </div>
                            <div className="generate_no">
                              {mediaData.members}
                            </div>
                          </div>
                          <div className="generate_bottom">Members</div>
                        </div>
                      </div>

                      <div className="art_gen_inner_inner">
                        <div className="art_gen_inner_box">
                          <div className="generate_topper">
                            <div className="generate_top">
                              <img src={Art4} />
                            </div>
                            <div className="generate_no">
                              {mediaData.article_scheduled}
                            </div>
                          </div>
                          <div className="generate_bottom">Scheduled</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="art_analytics">
                    <Row>
                      <Col lg="6" md="6">
                        <div className="post_analytics">
                          <div className="analytic_heading">
                            Post shared Analytics
                          </div>
                          <Bar options={options} data={data} />
                        </div>
                      </Col>
                      <Col lg="6" md="6">
                        <div className="post_analytics">
                          <div className="analytic_heading">
                            Recent Generated Article
                          </div>
                          <div className="online_study custom_scrollbar">
                            {recentPost.map((ele) => {
                              let mediaLogo =
                                ele.facebook != "" ? (
                                  <img
                                    style={{
                                      width: "15px",
                                      height: "15px",
                                    }}
                                    src={Facebook}
                                  />
                                ) : ele.twitter != "" ? (
                                  <img
                                    style={{
                                      width: "15px",
                                      height: "15px",
                                    }}
                                    src={TwitterIcon}
                                  />
                                ) : ele.instagram != "" ? (
                                  <img
                                    style={{
                                      width: "15px",
                                      height: "15px",
                                    }}
                                    src={InsIcon}
                                  />
                                ) : ele.wordpress != "" ? (
                                  <img
                                    style={{
                                      width: "15px",
                                      height: "15px",
                                    }}
                                    src={Wp}
                                  />
                                ) : ele.linkedin != "" ? (
                                  <img
                                    style={{
                                      width: "15px",
                                      height: "15px",
                                    }}
                                    src={LinkedSocial}
                                  />
                                ) : (
                                  <></>
                                );
                              return (
                                <Link className="gaget_box" to="#">
                                  <div className="gaget_box_left">
                                    <img src={ele.images[0]==""?no_image:ele.images[0]} />
                                  </div>
                                  <div className="gaget_box_right">
                                    <h3>{ele.title}</h3>
                                    <label>
                                      Share: <Link to="#">{mediaLogo}</Link>
                                    </label>
                                    <label>
                                      <img src={Clock} />
                                      {moment(ele.createdAt).format(
                                        "DD-MM-YYYY LTS"
                                      )}
                                    </label>
                                  </div>
                                </Link>
                              );
                            })}

                            {/* <Link className="gaget_box" to="#">
                              <div className="gaget_box_left">
                                <img src={Gadget} />
                              </div>
                              <div className="gaget_box_right">
                                <h3>Which gadget is used for online study?</h3>
                                <label>
                                  Share:{" "}
                                  <Link to="#">
                                    <img src={InsIcon} />
                                  </Link>
                                </label>
                                <label>
                                  <img src={Clock} />
                                  June 12, 2023
                                </label>
                              </div>
                            </Link>

                            <Link className="gaget_box" to="#">
                              <div className="gaget_box_left">
                                <img src={Gadget} />
                              </div>
                              <div className="gaget_box_right">
                                <h3>Which gadget is used for online study?</h3>
                                <label>
                                  Share:{" "}
                                  <Link to="#">
                                    <img src={TwitterIcon} />
                                  </Link>
                                </label>
                                <label>
                                  <img src={Clock} />
                                  June 12, 2023
                                </label>
                              </div>
                            </Link>

                            <Link className="gaget_box" to="#">
                              <div className="gaget_box_left">
                                <img src={Gadget} />
                              </div>
                              <div className="gaget_box_right">
                                <h3>Which gadget is used for online study?</h3>
                                <label>
                                  Share:{" "}
                                  <Link to="#">
                                    <img src={Facebook} />
                                  </Link>
                                </label>
                                <label>
                                  <img src={Clock} />
                                  June 12, 2023
                                </label>
                              </div>
                            </Link>

                            <Link className="gaget_box" to="#">
                              <div className="gaget_box_left">
                                <img src={Gadget} />
                              </div>
                              <div className="gaget_box_right">
                                <h3>Which gadget is used for online study?</h3>
                                <label>
                                  Share:{" "}
                                  <Link to="#">
                                    <img src={Facebook} />
                                  </Link>
                                </label>
                                <label>
                                  <img src={Clock} />
                                  June 12, 2023
                                </label>
                              </div>
                            </Link>

                            <Link className="gaget_box" to="#">
                              <div className="gaget_box_left">
                                <img src={Gadget} />
                              </div>
                              <div className="gaget_box_right">
                                <h3>Which gadget is used for online study?</h3>
                                <label>
                                  Share:{" "}
                                  <Link to="#">
                                    <img src={Facebook} />
                                  </Link>
                                </label>
                                <label>
                                  <img src={Clock} />
                                  June 12, 2023
                                </label>
                              </div>
                            </Link> */}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="custom_scrollbar post_analytics mt-4">
                      <div className="analytic_heading">
                        Social Platform Engagement
                      </div>

                      <div className="react_data_table">
                        <DataTable columns={columns} data={getEngagement()} />
                      </div>
                    </div>

                    {/* <div className="post_analytics mt-4">
                      <div className="analytic_heading">
                        Recent posts update
                      </div>
                      <div className="post_partts custom_scrollbar">
                        <div className="post_iner_partts">
                          <div className="post_heading">
                            <h3>Marielia</h3>
                            <span>5 Minutes ago</span>
                          </div>
                          <h4>Magnificent Churches in India</h4>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Quisque scelerisque Lorem ipsum dolor sit
                            amet, consectetur adipiscing elit. Quisq
                          </p>
                          <div className="post_link">
                            <Link to="https://www.facebook.com/">
                              https://www.facebook.com/
                            </Link>
                            <Link to="https://www.linkedin.com/">
                              https://www.linkedin.com/
                            </Link>
                          </div>
                        </div>

                        <div className="post_iner_partts">
                          <div className="post_heading">
                            <h3>Marielia</h3>
                            <span>5 Minutes ago</span>
                          </div>
                          <h4>Magnificent Churches in India</h4>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Quisque scelerisque Lorem ipsum dolor sit
                            amet, consectetur adipiscing elit. Quisq
                          </p>
                          <div className="post_link">
                            <Link to="https://www.facebook.com/">
                              https://www.facebook.com/
                            </Link>
                            <Link to="https://www.linkedin.com/">
                              https://www.linkedin.com/
                            </Link>
                          </div>
                        </div>

                        <div className="post_iner_partts">
                          <div className="post_heading">
                            <h3>Marielia</h3>
                            <span>5 Minutes ago</span>
                          </div>
                          <h4>Magnificent Churches in India</h4>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Quisque scelerisque Lorem ipsum dolor sit
                            amet, consectetur adipiscing elit. Quisq
                          </p>
                          <div className="post_link">
                            <Link to="https://www.facebook.com/">
                              https://www.facebook.com/
                            </Link>
                            <Link to="https://www.linkedin.com/">
                              https://www.linkedin.com/
                            </Link>
                          </div>
                        </div>

                        <div className="post_iner_partts">
                          <div className="post_heading">
                            <h3>Marielia</h3>
                            <span>5 Minutes ago</span>
                          </div>
                          <h4>Magnificent Churches in India</h4>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Quisque scelerisque Lorem ipsum dolor sit
                            amet, consectetur adipiscing elit. Quisq
                          </p>
                          <div className="post_link">
                            <Link to="https://www.facebook.com/">
                              https://www.facebook.com/
                            </Link>
                            <Link to="https://www.linkedin.com/">
                              https://www.linkedin.com/
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </Col>
              <Col lg="3" md="12" sm="12" className="pr-0">
                <div className="dash_right_paertts">
                  <DashRightPanel />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DashboardPage;
