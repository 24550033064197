import React, { Component } from 'react';
//import "./styles.css";
import { Container, Row, Col, Modal, Button, Form, Dropdown, Table } from 'react-bootstrap';
import { Link } from "react-router-dom";
// import LeftpanelAdmin from "../../../components/leftpanel/left-panel-admin";
// import HeaderPart from "../../../components/layout/header-admin/header";
import './footer.css';
import {
    Logo,
    FaceBook,
    Twitter,
    Instagram,
    Linkedin,
} from "../../../assets/images";

const Footer = () => {
    return (
      <div className='footer_partts'>
          <Container>
              <div className='footer_inner_partts'>
                  <Row>
                    <Col lg="3" md="4">
                        <div className='footer_logo_partts'>
                            <img src={Logo} className="img-fluid" alt="logo" width={200}/>
                            <p>Automatically genarate blog articles, website copy, landing pages & digital ads for your organization in seconds. Unleash the most advanced AI writer to boost your traffic and productitvity.</p>
                            <div className='social_iconss'>
                                <Link to="#"><img src={FaceBook} /></Link>
                                <Link to="#"><img src={Twitter} /></Link>
                                <Link to="#"><img src={Instagram} /></Link>
                                <Link to="#"><img src={Linkedin} /></Link>
                            </div>
                        </div>
                    </Col>
                    <Col lg="3" md="4">
                        <div className='company_linksss'>
                            <h2>Company</h2>
                            <ul>
                              <li><Link to="#">Features</Link></li>
                              <li><Link to="#">Channels</Link></li>
                              <li><Link to="#">Plans</Link></li>
                            </ul>
                        </div>
                    </Col>
                    <Col lg="3" md="4">
                        <div className='company_linksss'>
                            <h2>About GPT GoodNews</h2>
                            <ul>
                              <li><Link to="#">About Us</Link></li>
                              <li><Link to="#">Terms and Conditions</Link></li>
                              <li><Link to="#">Privacy Policy</Link></li>
                              <li><Link to="#">Security</Link></li>
                            </ul>
                        </div>
                    </Col>
                    <Col lg="3" md="4">
                        <div className='company_linksss'>
                            <h2>Subscribe Our Newsletter</h2>
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                {/* <Form.Label>Email address</Form.Label> */}
                                <Form.Control type="email" placeholder="Enter your Email"  className='email_field'/>
                              </Form.Group>
                              <Button variant="default" type="submit" className='btn_primary btn-block'>
                                Submit
                              </Button>
                        </div>
                    </Col>
                  </Row>
              </div>
          </Container>
      </div>
    );
  };

  export default Footer;