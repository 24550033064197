import React, { useState, useEffect } from "react";
//import "./styles.css";
import { Form, Button, Container, Row, Col } from "react-bootstrap";

import Slider from "react-slick";
import axios from "../../axios/axios";
import message from "../../messages";
import { Audio } from "react-loader-spinner";
import "./ForgotPassword.css";
import { LoginLogo, YellowStar, Blogger } from "../../assets/images";
import { useParams } from "react-router-dom";
const ResetPassword = () => {
  const { code } = useParams();
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [cpassword, setCpassword] = useState("");
  const [cpasswordError, setCpasswordError] = useState("");
  const [loader, setLoader] = useState(false);

  const passwordValidate = () => {
    setPasswordError("");
    setCpasswordError("");

    if (!password) {
      setPasswordError("Please enter your password");
      return false;
    }
    if (!cpassword) {
      setCpasswordError("Please enter your confirm password");
      return false;
    } else if (password.length < 6) {
      setCpasswordError("Minimum password length is 6");
      return false;
    } else if (password !== cpassword) {
      setCpasswordError("Confirm password doesn't match");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (passwordValidate()) {
      setLoader(true);
      // Submit the form or perform other actions
      await axios
        .post("/reset-password", { resetToken: code, newPassword: password })
        .then((res) => {
          setLoader(false);
          message.success(res.data.message);
          setTimeout(() => {
            window.open("/login", "_self");
          }, 2000);
        })
        .catch((err) => {
          setLoader(false);
          console.log("err", err);
          message.error(err.response.data.message);
        });
    }
  };
  const settings = {
    dots: false,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    speed: 400,
    swipeToSlide: true,
  };


  return (
    <div className="login_page">
      {loader == true && (
        <div className="loader-wrapper">
          <Audio
            height="80"
            width="80"
            radius="9"
            color="green"
            ariaLabel="loading"
            wrapperStyle
            wrapperClass
          />
        </div>
      )}
      <Container>
        <Row className="align-items-center">        
          <Col md={6}>
            <div className="login_left">
              <div className="login_inner_left">
                <div className="login_formms reg_formms">
                  <h2 className="form-title">Reset Password</h2>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="type_email_box" controlId="formBasicEmail">
                      <Form.Control
                        type="password"
                        placeholder="New Password"
                        className="type_email"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <div>
                        {passwordError && <p style={{ color: "red" }}>{passwordError}</p>}
                      </div>
                    </Form.Group>

                    <Form.Group className="type_email_box" controlId="formBasicEmail">
                      <Form.Control
                        type="password"
                        placeholder="Confirm New Password"
                        className="type_email"
                        value={cpassword}
                        onChange={(e) => setCpassword(e.target.value)}
                      />
                      <div>
                        {cpasswordError && <p style={{ color: "red" }}>{cpasswordError}</p>}
                      </div>
                    </Form.Group>
                    <Button 
                      variant="default" 
                      type="submit" 
                      className="btn_primary btn-block"
                      size="lg"
                      >
                      Submit
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="login_right">
              <div className="login_inner_right">
                <Slider className="testimonial_slider" {...settings}>
                  <div className="testimonial_item">
                    <div className="yellow_star">
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                    </div>
                    <p>
                      I really like what GPT GoodNews generates. I've tried a few of
                      AIs and some of them generate lots of content, but often out of
                      topic, or with phrases which have really no meaning. Especially
                      for technical content.
                    </p>
                    <div className="blog_writer d-flex align-items-center">
                      <div className="blogger_name">
                        <img src={Blogger} />
                      </div>
                      <div className="blogger_des">
                        <h3>Sonam Mitra</h3>
                        <span>Blog Writer</span>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial_item">
                    <div className="yellow_star">
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                    </div>
                    <p>
                      I really like what GPT GoodNews generates. I've tried a few of
                      AIs and some of them generate lots of content, but often out of
                      topic, or with phrases which have really no meaning. Especially
                      for technical content.
                    </p>
                    <div className="blog_writer d-flex align-items-center">
                      <div className="blogger_name">
                        <img src={Blogger} />
                      </div>
                      <div className="blogger_des">
                        <h3>Sonam Mitra</h3>
                        <span>Blog Writer</span>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial_item">
                    <div className="yellow_star">
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                    </div>
                    <p>
                      I really like what GPT GoodNews generates. I've tried a few of
                      AIs and some of them generate lots of content, but often out of
                      topic, or with phrases which have really no meaning. Especially
                      for technical content.
                    </p>
                    <div className="blog_writer d-flex align-items-center">
                      <div className="blogger_name">
                        <img src={Blogger} />
                      </div>
                      <div className="blogger_des">
                        <h3>Sonam Mitra</h3>
                        <span>Blog Writer</span>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial_item">
                    <div className="yellow_star">
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                    </div>
                    <p>
                      I really like what GPT GoodNews generates. I've tried a few of
                      AIs and some of them generate lots of content, but often out of
                      topic, or with phrases which have really no meaning. Especially
                      for technical content.
                    </p>
                    <div className="blog_writer d-flex align-items-center">
                      <div className="blogger_name">
                        <img src={Blogger} />
                      </div>
                      <div className="blogger_des">
                        <h3>Sonam Mitra</h3>
                        <span>Blog Writer</span>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial_item">
                    <div className="yellow_star">
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                      <img src={YellowStar} />
                    </div>
                    <p>
                      I really like what GPT GoodNews generates. I've tried a few of
                      AIs and some of them generate lots of content, but often out of
                      topic, or with phrases which have really no meaning. Especially
                      for technical content.
                    </p>
                    <div className="blog_writer d-flex align-items-center">
                      <div className="blogger_name">
                        <img src={Blogger} />
                      </div>
                      <div className="blogger_des">
                        <h3>Sonam Mitra</h3>
                        <span>Blog Writer</span>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ResetPassword;
