import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Modal, Button, Form, Dropdown, Table, Accordion, Card } from 'react-bootstrap';
import DashboardLeftPanel from "../../../components/DashboardLeftPanel/dashboardLeftPanel";
import DashRightPanel from "../../../components/DashboardRightPanel/dashbaordrightpanel";
import { Link } from "react-router-dom";
import DataTable from 'react-data-table-component';
import './affiliates.css';
import Switch from "react-switch";
import {
    Icon11,
    Icon2,
    Toggle,
    UpArrow,
    NameView,
    action,
    Comm,
    Lifetime,
    DownArrow1,
    Social1,
    Social2,
    Social3,
    Social4,
    Social5,
  } from "../../../assets/images";
  import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';
  import faker from 'faker';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
const AffiliatePage = () => {


    const [checked, setChecked] = useState(false);
  const handleChange = nextChecked => {
    setChecked(nextChecked);
  };
    const [collapsed, setCollapsed] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
    setExpanded(!expanded);
  };



  // Effect to load the state from localStorage on initial render
  useEffect(() => {
    const storedCollapsed = localStorage.getItem('collapsed');
    if (storedCollapsed) {
        setCollapsed(JSON.parse(storedCollapsed));
    }
  }, []);

  // Effect to save the state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('collapsed', JSON.stringify(collapsed));
  }, [collapsed]);



// Effect to load the state from localStorage on initial render
useEffect(() => {
    const storedExpanded = localStorage.getItem('expanded');
    if (storedExpanded) {
        setExpanded(JSON.parse(storedExpanded));
    }
  }, []);

  // Effect to save the state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('expanded', JSON.stringify(collapsed));
  }, [collapsed]);


  const columns = [
    {
        name: 'No.',
        selector: row => row.no,
        width: '70px',
        sortable: true,
        style: {
            padding: "10px 7px",
            // height: "50px",
            // display: "block",
          },
    },
    {
        name: 'Name',
        selector: row => row.name,
        width: '200px',
        sortable: true,
        style: {
            padding: "10px 7px",
            // height: "50px",
            // display: "block",
          },
    },
    {
        name: 'Email',
        selector: row => row.email,
        width: '200px',
        sortable: true,
        style: {
            padding: "10px 7px",
            // height: "50px",
            // display: "block",
          },
    },
    {
        name: 'Signup Date',
        selector: row => row.signdate,
        width: '150px',
        sortable: true,
        style: {
            padding: "10px 7px",
            // height: "50px",
            // display: "block",
          },
    },
    {
        name: 'Status',
        selector: row => row.status,
        width: '140px',
        sortable: true,
        style: {
            padding: "10px 7px",
            // height: "50px",
            // display: "block",
          },
    },
];

const data1 = [
    {
        id: 1,
        no: '01',
        name: <div className='name_text d-flex align-items-center'>
                    {/* <div className='name_view_sec'><img src={NameView} /></div> */}
                    <div className='name_div'>Sukumar Roy</div>
              </div>,
        email: <div className='mail'>dip.wgt@gmail.com</div>,
        signdate: <div className='mail'>12/06/23</div>,
        status: <Switch
        onChange={handleChange}
        checked={checked}
        className="react-switch"
      />,
    },
    {
        id: 2,
        no: '02',
        name: <div className='name_text d-flex align-items-center'>
                    {/* <div className='name_view_sec'><img src={NameView} /></div> */}
                    <div className='name_div'>Sukumar Roy</div>
              </div>,
        email: <div className='mail'>dip.wgt@gmail.com</div>,
        signdate: <div className='mail'>12/06/23</div>,
        status: <Switch
        onChange={handleChange}
        checked={checked}
        className="react-switch"
      />,
    },

    {
        id: 2,
        no: '03',
        name: <div className='name_text d-flex align-items-center'>
                    {/* <div className='name_view_sec'><img src={NameView} /></div> */}
                    <div className='name_div'>Sukumar Roy</div>
              </div>,
        email: <div className='mail'>dip.wgt@gmail.com</div>,
        signdate: <div className='mail'>12/06/23</div>,
        status: <Switch
        onChange={handleChange}
        checked={checked}
        className="react-switch"
      />,
    },
    {
        id: 2,
        no: '04',
        name: <div className='name_text d-flex align-items-center'>
                    {/* <div className='name_view_sec'><img src={NameView} /></div> */}
                    <div className='name_div'>Sukumar Roy</div>
              </div>,
        email: <div className='mail'>dip.wgt@gmail.com</div>,
        signdate: <div className='mail'>12/06/23</div>,
        status: <Switch
        onChange={handleChange}
        checked={checked}
        className="react-switch"
      />,
    },
    {
        id: 2,
        no: '05',
        name: <div className='name_text d-flex align-items-center'>
                    {/* <div className='name_view_sec'><img src={NameView} /></div> */}
                    <div className='name_div'>Sukumar Roy</div>
              </div>,
        email: <div className='mail'>dip.wgt@gmail.com</div>,
        signdate: <div className='mail'>12/06/23</div>,
        status: <Switch
        onChange={handleChange}
        checked={checked}
        className="react-switch"
      />,
    },
]


const options = {
    responsive: true,
    plugins: {
      legend: {
        //position: 'top' as const,
      },
      title: {
        display: true,
        //text: '',
      },
    },
  };
  
  const labels = ['10/06/23', '11/06/23', '12/06/23', '13/06/23', '14/06/23', '15/06/23', '16/06/23', '17/06/23'];
  
const data = {
    labels,
    datasets: [
      {
        label: 'Commission earned($)',
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: 'rgba(150, 150, 255, 1)',
      },
    //   {
    //     label: 'Dataset 2',
    //     data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
    //     backgroundColor: 'rgba(53, 162, 235, 0.5)',
    //   },
    ],
  };
    return (
        <section className="dash_partts">
            <div className='dash_inner_partts'>
                <div className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
                    <DashboardLeftPanel />
                </div>
                
                <div className={`dash_right_panel ${expanded ? 'expanded' : ''}`}>
                <button onClick={toggleSidebar} className='toggle__button'><img src={Toggle} /></button>
                    <div className='dash_right_inner_panel'>
                        <Row>
                            <Col lg="9" md="12" sm="12">
                                <div className='dash_middle_panel'>
                                    <div className='affiliates_heading'>
                                        <h2>Affiliates</h2>
                                    </div>
                                    <div className='affiliates_boxxx'>
                                        <div className='affiliate_boxx_inner'>
                                            <div className='affiliate_box_inner_inner'>
                                                <div className='affiliates_box_topper'>
                                                    <div className='tooper_box'><img src={Icon11} /></div>
                                                    <span className='topper_heading'>Total Clicks</span>
                                                </div>
                                                <div className='aff_box_bottom'>
                                                    <label>234</label>
                                                    <span><img src={UpArrow} className='mr-1'/> 4.0%</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='affiliate_boxx_inner'>
                                            <div className='affiliate_box_inner_inner'>
                                                <div className='affiliates_box_topper'>
                                                    <div className='tooper_box'><img src={Icon2} /></div>
                                                    <span className='topper_heading'>Enrollments</span>
                                                </div>
                                                <div className='aff_box_bottom'>
                                                    <label>123</label>
                                                    <span><img src={UpArrow} className='mr-1'/> 2.3%</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='affiliate_boxx_inner'>
                                            <div className='affiliate_box_inner_inner'>
                                                <div className='affiliates_box_topper'>
                                                    <div className='tooper_box'><img src={Comm} /></div>
                                                    <span className='topper_heading'>Weekly Commission</span>
                                                </div>
                                                <div className='aff_box_bottom'>
                                                    <label>$23</label>
                                                    <span><img src={UpArrow} className='mr-1'/> 5.0%</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='affiliate_boxx_inner'>
                                            <div className='affiliate_box_inner_inner'>
                                                <div className='affiliates_box_topper'>
                                                    <div className='tooper_box'><img src={Lifetime} /></div>
                                                    <span className='topper_heading'>Lifetime Commission</span>
                                                </div>
                                                <div className='aff_box_bottom'>
                                                    <label>$560</label>
                                                    <span className='down_partts'><img src={DownArrow1} className='mr-1'/> -8.0%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='affiliate_linkks'>
                                        <h3>GPT GoodNews affiliate link</h3>
                                        <div className='affiliates_link_inner'>
                                            <label className='afflinkks'>https://gpt.goodnews/4fdewf2bngb/affiliate/deeya_sur_01</label>
                                            <Button type='button' className='aff_copy'>Copy</Button>
                                        </div>
                                        <div className='share_link_on'>
                                            Share the link on: 
                                            <Link to="" className="mx-2"><img src={Social1} height={30}/></Link>
                                            <Link to="" className="mx-2"><img src={Social2} height={30}/></Link>
                                            <Link to="" className="mx-2"><img src={Social3} height={30}/></Link>
                                            <Link to="" className="mx-2"><img src={Social4} height={30}/></Link>
                                            <Link to="" className="mx-2"><img src={Social5} height={30}/></Link>
                                        </div>
                                    </div>

                                    <div className='commission_chartts'>
                                        <div className='commmission_heading'>
                                            <h3>Commission Chart</h3>
                                            <div className='from_to_date'>
                                                <div className='from_date'>
                                                    <label>From Date</label>
                                                    <Form.Control type='date' className='date_fild'></Form.Control>
                                                </div>

                                                <div className='from_date ml-3'>
                                                    <label>To Date</label>
                                                    <Form.Control type='date' className='date_fild'></Form.Control>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='commission_chart_partts'>
                                            <Bar options={options} data={data} />
                                        </div>
                                    </div>



                                    <div className='commission_chartts'>
                                        <div className='commmission_heading'>
                                            <h3>My affiliates</h3>
                                            
                                        </div>

                                        <div className='affiliate_data'>
                                                    <DataTable
                                                        columns={columns}
                                                        data={data1}
                                                        pagination
                                                    />
                                            </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="3" md="12" sm="12" className='pr-0'>
                                <div className='dash_right_paertts'>
                                    <DashRightPanel />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    
                </div>
            </div>

            
        </section>
        );
    };
  
export default AffiliatePage;