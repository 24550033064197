import React, { useState, useContext, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Form,
  Dropdown,
  Table,
  Accordion,
  Card,
  OverlayTrigger,
  Tooltip as BootstrapTooltip,
} from "react-bootstrap";
import DashboardLeftPanel from "../../../components/DashboardLeftPanel/dashboardLeftPanel";
import DashRightPanel from "../../../components/DashboardRightPanel/dashbaordrightpanel";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import CustomTooltip from "../../Dashboard/customTooltip/custom-tooltip";
import "./socialconnect.css";
import Switch from "react-switch";
import {
  FacebookSocial,
  InsSocial,
  TwitterSocial,
  LinkedSocial,
  Toggle,
  SearchIcon,
  NameView,
  action,
  Wp,
  TwitterIconn,
  Information,
} from "../../../assets/images";
import axios from "../../../axios/axios";
import message from "../../../messages";
import { Audio } from "react-loader-spinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { ThemeContext } from "../../../ThemeContext";
const SocialConnectPage = () => {
  const { isDarkTheme } = useContext(ThemeContext);
  const [checked, setChecked] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const [isCollapsed1, setIsCollapsed1] = useState(false);
  const [isExpanded1, setIsExpanded1] = useState(false);
  const tooltip = "If you want to post in your Profile and groups, Please copy the text and share it manually";

  const [expanded, setExpanded] = useState(false);
  // const [selectedMedia, setSelectedMedia] = useState("facebook");
  const [loader, setLoader] = useState(false);
  const [connectedAcc, setConnectedAcc] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showInsModal, setShowInsModal] = useState(false);
  const [wpData, setWpData] = useState({
    url: "",
    app_id: "",
    app_password: "",
  });
  const [credsError, setCredsError] = useState({
    urlErr: "",
    appIdErr: "",
    appPassErr: "",
  });
  const [selectDisAc, setSelectDisAc] = useState([]);
  // Effect to load the state from localStorage on initial render
  useEffect(() => {
    const storedCollapsed = localStorage.getItem("collapsed");
    if (storedCollapsed) {
      setCollapsed(JSON.parse(storedCollapsed));
    }
  }, []);

  // Effect to save the state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("collapsed", JSON.stringify(collapsed));
  }, [collapsed]);

  // Effect to load the state from localStorage on initial render
  useEffect(() => {
    const storedExpanded = localStorage.getItem("expanded");
    if (storedExpanded) {
      setExpanded(JSON.parse(storedExpanded));
    }
  }, []);

  // Effect to save the state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("expanded", JSON.stringify(collapsed));
  }, [collapsed]);

  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleInsModal = () => {
    setShowInsModal(true);
  };

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setShowInsModal(false);
  };
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
    setExpanded(!expanded);
  };

  const toggleCollapse = () => {
    setIsCollapsed1(!isCollapsed1);
    setIsExpanded1(!isExpanded1);
  };
  const getAllSocialAccount = async () => {
    setLoader(true);
    await axios
      .get("/social/accounts")
      .then((res) => {
        setLoader(false);
        setConnectedAcc(res.data.data);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  React.useEffect(() => {
    getAllSocialAccount();
  }, []);

  const connectedAcListing = () => {
    let connAc = [];
    connectedAcc.map((item, i) => {
      connAc.push({
        platform: (
          <div className="management_list_name">
            {item.account_type == "Facebook" ? (
              <img
                style={{ width: "30px", height: "30px" }}
                src={FacebookSocial}
              />
            ) : item.account_type == "Twitter" ? (
              <img
                style={{ width: "30px", height: "30px" }}
                src={TwitterSocial}
              />
            ) : item.account_type == "Instagram" ? (
              <img style={{ width: "30px", height: "30px" }} src={InsSocial} />
            ) : item.account_type == "WordPress" ? (
              <img style={{ width: "30px", height: "30px" }} src={Wp} />
            ) : item.account_type == "LinkedIn" ? (
              <img
                style={{ width: "30px", height: "30px" }}
                src={LinkedSocial}
              />
            ) : (
              <></>
            )}
          </div>
        ),
        name: <div className="man_username">{item.accounts[0]?.name}</div>,
        status: <div className="man_username">{item.status}</div>,
        action: (
          <div className="action_partts">
            <Button
              type="button"
              class="btn btn-danger"
              onClick={(e) => disconnectSocialMedia(e, item.account_type)}
            >
              Disconnect
            </Button>
          </div>
        ),
      });
    });
    return connAc;
  };

  const handleRefresh = () => {
    window.location.reload(); // Reload the current page
  };

  const columns = [
    {
      name: "Sl No.",
      selector: (row, ind) => ind + 1,
      width: "70px",
      sortable: true,
      style: {
        padding: "10px 7px",
        // height: "50px",
        // display: "block",
      },
    },
    {
      name: "Platform",
      selector: (row) => row.platform,
      width: "100px",
      sortable: true,
      style: {
        padding: "10px 7px",
        // height: "50px",
        // display: "block",
      },
    },
    {
      name: "Social Name",
      selector: (row) => row.name,
      width: "150px",
      sortable: true,
      style: {
        padding: "10px 7px",
        // height: "50px",
        // display: "block",
      },
    },
    {
      name: "Status",
      selector: (row) => row.status,
      width: "150px",
      sortable: true,
      style: {
        padding: "10px 7px",
        // height: "50px",
        // display: "block",
      },
    },
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: true,
      width: "200px",
      style: {
        padding: "10px 7px",
        // height: "50px",
        // display: "block",
      },
    },
  ];

  // const data1 = [
  //     {
  //         id: 1,
  //         no: '01',
  //         platform: <div className='name_text d-flex align-items-center'>
  //                     <div className='name_view_sec s_connect'><img src={Wp}  width={40}/></div>
  //                   </div>,
  //         url: <div className='mail'>https://wptest.webgen.work/openai</div>,
  //         status: <Switch
  //         onChange={handleChange}
  //         checked={checked}
  //         className="react-switch"
  //       />,
  //         action: <div className='action_partts'>
  //                 <Dropdown>
  //                 <Dropdown.Toggle variant="success" id="dropdown-basic">
  //                     <img src={action} />
  //                 </Dropdown.Toggle>

  //                 <Dropdown.Menu>
  //                     <Dropdown.Item href="#/action-1">Edit</Dropdown.Item>
  //                     <Dropdown.Item href="#/action-2">Delete</Dropdown.Item>
  //                 </Dropdown.Menu>
  //                 </Dropdown>
  //                 </div>,
  //     },
  //     {
  //         id: 2,
  //         no: '02',
  //         platform: <div className='name_text d-flex align-items-center'>
  //                     <div className='name_view_sec s_connect'><img src={TwitterIconn} width={40}/></div>
  //                   </div>,
  //         url: <div className='mail'>https://wptest.webgen.work/openai2</div>,
  //         status: <Switch
  //         onChange={handleChange}
  //         checked={checked}
  //         className="react-switch"
  //       />,
  //         action: <div className='action_partts'>
  //                 <Dropdown>
  //                 <Dropdown.Toggle variant="success" id="dropdown-basic">
  //                     <img src={action} />
  //                 </Dropdown.Toggle>

  //                 <Dropdown.Menu>
  //                     <Dropdown.Item href="#/action-1">Edit</Dropdown.Item>
  //                     <Dropdown.Item href="#/action-2">Delete</Dropdown.Item>
  //                 </Dropdown.Menu>
  //                 </Dropdown>
  //                 </div>,
  //     },

  //     {
  //         id: 2,
  //         no: '03',
  //         platform: <div className='name_text d-flex align-items-center'>
  //                     <div className='name_view_sec s_connect'><img src={Wp}  width={40}/></div>
  //                   </div>,
  //         url: <div className='mail'>https://wptest.webgen.work/openai</div>,
  //         status: <Switch
  //         onChange={handleChange}
  //         checked={checked}
  //         className="react-switch"
  //       />,
  //         action: <div className='action_partts'>
  //                 <Dropdown>
  //                 <Dropdown.Toggle variant="success" id="dropdown-basic">
  //                     <img src={action} />
  //                 </Dropdown.Toggle>

  //                 <Dropdown.Menu>
  //                     <Dropdown.Item href="#/action-1">Edit</Dropdown.Item>
  //                     <Dropdown.Item href="#/action-2">Delete</Dropdown.Item>
  //                 </Dropdown.Menu>
  //                 </Dropdown>
  //                 </div>,
  //     },
  //     {
  //         id: 2,
  //         no: '04',
  //         platform: <div className='name_text d-flex align-items-center'>
  //                     <div className='name_view_sec s_connect'><img src={TwitterIconn} width={40}/></div>
  //               </div>,
  //         url: <div className='mail'>https://wptest.webgen.work/openai2</div>,
  //         status: <Switch
  //         onChange={handleChange}
  //         checked={checked}
  //         className="react-switch"
  //       />,
  //         action: <div className='action_partts'>
  //                 <Dropdown>
  //                 <Dropdown.Toggle variant="success" id="dropdown-basic">
  //                     <img src={action} />
  //                 </Dropdown.Toggle>

  //                 <Dropdown.Menu>
  //                     <Dropdown.Item href="#/action-1">Edit</Dropdown.Item>
  //                     <Dropdown.Item href="#/action-2">Delete</Dropdown.Item>
  //                 </Dropdown.Menu>
  //                 </Dropdown>
  //                 </div>,
  //     },
  //     {
  //         id: 2,
  //         no: '05',
  //         platform: <div className='name_text d-flex align-items-center'>
  //                     <div className='name_view_sec s_connect'><img src={FacebookSocial}  width={40}/></div>
  //               </div>,
  //         url: <div className='mail'>https://wptest.webgen.work/openai2</div>,
  //         status: <Switch
  //         onChange={handleChange}
  //         checked={checked}
  //         className="react-switch"
  //       />,
  //         action: <div className='action_partts'>
  //                 <Dropdown>
  //                 <Dropdown.Toggle variant="success" id="dropdown-basic">
  //                     <img src={action} />
  //                 </Dropdown.Toggle>

  //                 <Dropdown.Menu>
  //                     <Dropdown.Item href="#/action-1">Edit</Dropdown.Item>
  //                     <Dropdown.Item href="#/action-2">Delete</Dropdown.Item>
  //                 </Dropdown.Menu>
  //                 </Dropdown>
  //                 </div>,
  //     },
  // ]

  //Facebook API
  //   const facebookAuth =() => {
  //     axios.get('/facebook/auth').then( (res) => {
  //         console.log(res)
  //         if(res.status === 200){
  //             window.location.replace(res.data.data.url)
  //         }
  //     })
  //   }

  //   const twitterAuth =() => {
  //     axios.get('/twitter/auth').then( (res) => {
  //         console.log(res)
  //         if(res.status === 200){
  //             localStorage.setItem('twitter_secret', res.data.data.secret)
  //             window.location.replace(res.data.data.url)
  //         }
  //     })
  //   }
  // const linkedinAuth =() => {
  //   axios.get('/linkedin/auth').then( (res) => {
  //       console.log(res)
  //       if(res.status === 200){
  //           window.location.replace(res.data.data.url)
  //       }
  //   })
  // }
  const facebookAuth = async () => {
    setLoader(true);
    await axios
      .get("/facebook/auth")
      .then((res) => {
        setLoader(false);
        // console.log("data", res);
        window.open(res.data.data.url, "_self");
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };

  const instagramAuth = async () => {
    setLoader(true);
    await axios
      .get("/instagram/auth")
      .then((res) => {
        setLoader(false);
        // console.log("data", res);
        window.open(res.data.data.url, "_self");
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };

  const twitterAuth = async () => {
    setLoader(true);
    await axios
      .get("/twitter/auth")
      .then((res) => {
        setLoader(false);
        // console.log("data", res);
        localStorage.setItem("twitter_secret", res.data.data.secret);
        window.open(res.data.data.url, "_self");
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };

  const linkedinAuth = async () => {
    setLoader(true);
    await axios
      .get("/linkedin/auth")
      .then((res) => {
        setLoader(false);
        // console.log("data", res);
        window.open(res.data.data.url, "_self");
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };

  const wordpressAuth = async () => {
    setLoader(true);

    if (!wpData.app_id) {
      setCredsError({ ...credsError, appIdErr: "AppId is required" });
      return;
    }
    if (!wpData.url) {
      setCredsError({ ...credsError, urlErr: "Url is required" });
      return;
    }
    if (!wpData.app_password) {
      setCredsError({ ...credsError, appPassErr: "AppId is required" });
      return;
    } else {
      await axios
        .post("/wordpress/auth", wpData)
        .then((res) => {
          setLoader(false);
          // console.log("data", res);
          // window.open(res.data.data.url, "_self");
          handleCloseModal();
        })
        .catch((err) => {
          setLoader(false);
          console.log("err", err);
          message.error(err.response.data.message);
        });
    }
  };

  const disconnectSocialMedia = async (e, acc_type) => {
    const findClickAc = connectedAcc.filter((acc) => {
      return acc.account_type === acc_type;
    });
    const Ac_Id = findClickAc[0].accounts[0].id;

    let paramsData = {
      type: acc_type,
      account_id: Ac_Id,
    };
    setLoader(true);
    await axios
      .post("/disconnect-social-account", paramsData)
      .then((res) => {
        setLoader(false);
        message.success("Disconnected");
        getAllSocialAccount();
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };

  const checkedConnAc = (ac_Type) => {
    return connectedAcc.filter((ac) => ac.account_type === ac_Type);
  };

  const handleWpChange = (event) => {
    const { name, value } = event.target;
    setWpData({ ...wpData, [name]: value });
  };

  return (
    <section className="dash_partts">
      <div className="dash_inner_partts">
        <div className={`sidebar ${collapsed ? "collapsed" : ""}`}>
          <DashboardLeftPanel />
        </div>

        <div className={`dash_right_panel ${expanded ? "expanded" : ""}`}>
          <button onClick={toggleSidebar} className="toggle__button">
            <img src={Toggle} />
          </button>
          <div className="dash_right_inner_panel">
            <Row>
              <Col lg="9" md="12" sm="12">
                <div className="dash_middle_panel">
                  <div className="connect_media_account">
                    <h3>Connect your social media account</h3>
                    <div className="social_connect_inner_sec">
                      <div className="social_inner_inner_sec">
                        <div className="social_white_sec">
                          <img src={FacebookSocial} width={35} />
                          <h4>
                            Facebook{" "}
                            <OverlayTrigger
                                overlay={
                                  <BootstrapTooltip>{tooltip}</BootstrapTooltip>
                                }
                              >
                                <FontAwesomeIcon icon={faCircleInfo} />
                              </OverlayTrigger>
                          </h4>
                          <span>Pages</span>
                          <button
                            type="button"
                            className={
                              checkedConnAc("Facebook").length > 0
                                ? "dis_button"
                                : "connect_button"
                            }
                            onClick={(e) =>
                              checkedConnAc("Facebook").length > 0
                                ? disconnectSocialMedia(e, "Facebook")
                                : facebookAuth()
                            }
                          >
                            {checkedConnAc("Facebook").length > 0
                              ? "Disconnect"
                              : "Connect"}
                          </button>
                        </div>
                      </div>

                      {/* <div className="social_inner_inner_sec">
                        <div className="social_white_sec">
                          <img src={InsSocial} width={35} />
                          <h4>Instagram</h4>
                          <span>Profile</span>
                          <button
                            type="button"
                            className={
                              checkedConnAc("Instagram").length > 0
                                ? "dis_button"
                                : "connect_button"
                            }
                            onClick={(e) =>
                              checkedConnAc("Instagram").length > 0
                                ? disconnectSocialMedia(e, "Instagram")
                                : handleInsModal()
                            }
                          >
                            {checkedConnAc("Instagram").length > 0
                              ? "Disconnect"
                              : "Connect"}
                          </button>

                          <Modal
                            show={showInsModal}
                            onHide={handleCloseModal}
                            size="lg"
                            centered
                          >
                            <Modal.Header className="d-block">
                              <Modal.Title className="text-center d-block">
                                Authenticate With Facebook
                              </Modal.Title>

                              <Button
                                variant="secondary"
                                className="wp_close_modal"
                                onClick={handleCloseModal}
                              >
                                X
                              </Button>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="login_wp_partts">
                                <h4>
                                  Wait...why do I have to authenticate with
                                  Facebook?
                                </h4>
                                <br />
                                <br />
                                <p>
                                  Facebook requires Instagram Business or
                                  Creator accounts to be connected to a Facebook
                                  Page. For Buffer to work as intended, we need
                                  to authenticate through Facebook.
                                </p>
                                <br />
                                <br />
                                <h4>What happens next?</h4>
                                <p>
                                  Once we transfer you, sign in to the Facebook
                                  profile that has Admin permissions for the
                                  Facebook Page connected with your Instagram
                                  account. Check out our
                                  <Link
                                    to={`https://www.facebook.com/help/1148909221857370`}
                                    target="_blank"
                                  >
                                    {" "}
                                    Instagram connection guide
                                  </Link>{" "}
                                  for more.
                                </p>

                                <Button
                                  variant="primary"
                                  type="submit"
                                  className="wp_submit"
                                  onClick={instagramAuth}
                                >
                                  Authenticate With Facebook
                                </Button>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                      </div> */}

                      {/* <div className="social_inner_inner_sec">
                        <div className="social_white_sec">
                          <img src={TwitterSocial} width={35} />
                          <h4>Twitter</h4>
                          <span>Profile</span>
                          <button
                            type="button"
                            className={
                              checkedConnAc("Twitter").length > 0
                                ? "dis_button"
                                : "connect_button"
                            }
                            onClick={(e) =>
                              checkedConnAc("Twitter").length > 0
                                ? disconnectSocialMedia(e, "Twitter")
                                : twitterAuth()
                            }
                          >
                            {checkedConnAc("Twitter").length > 0
                              ? "Disconnect"
                              : "Connect"}
                          </button>
                        </div>
                      </div> */}

                      {/* <div className="social_inner_inner_sec">
                        <div className="social_white_sec">
                          <img src={LinkedSocial} width={35} />
                          <h4>Linkedin</h4>
                          <span>Profile</span>
                          <button
                            type="button"
                            className={
                              checkedConnAc("LinkedIn").length > 0
                                ? "dis_button"
                                : "connect_button"
                            }
                            onClick={(e) =>
                              checkedConnAc("LinkedIn").length > 0
                                ? disconnectSocialMedia(e, "LinkedIn")
                                : linkedinAuth()
                            }
                          >
                            {checkedConnAc("LinkedIn").length > 0
                              ? "Disconnect"
                              : "Connect"}
                          </button>
                        </div>
                      </div> */}

                      <div className="social_inner_inner_sec">
                        <div className="social_white_sec">
                          <img src={Wp} width={35} />
                          <h4>Wordpress Blog</h4>
                          <span>Profile</span>
                          <button
                            type="button"
                            className={
                              checkedConnAc("WordPress").length > 0
                                ? "dis_button"
                                : "connect_button"
                            }
                            onClick={(e) =>
                              checkedConnAc("WordPress").length > 0
                                ? disconnectSocialMedia(e, "WordPress")
                                : handleShowModal()
                            }
                          >
                            {checkedConnAc("WordPress").length > 0
                              ? "Disconnect"
                              : "Connect"}
                          </button>
                          <Modal
                            show={showModal}
                            onHide={handleCloseModal}
                            size="lg"
                            centered
                          >
                            <Modal.Header className="d-block">
                              <Modal.Title className="text-center d-block">
                                Wordpress Login
                              </Modal.Title>

                              <Button
                                variant="secondary"
                                className="wp_close_modal"
                                onClick={handleCloseModal}
                              >
                                X
                              </Button>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="d-flex justify-content-between flex-wrap">
                                <div
                                  className={`login_wp_partts px-4 w-50 ${
                                    isExpanded1 ? "expanded" : ""
                                  }`}
                                >
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formBasicEmail"
                                  >
                                    <Form.Label>
                                      Url
                                      <CustomTooltip text="This Will be your blog URL.">
                                        <span className="custom_tooltip"><img src={Information} /></span>
                                      </CustomTooltip>
                                    </Form.Label>
                                    <Form.Control
                                      name="url"
                                      type="text"
                                      className="emailfield"
                                      placeholder="Enter url"
                                      onChange={handleWpChange}
                                    />
                                    <p style={{ color: "red" }}>
                                      {credsError.urlErr}
                                    </p>
                                  </Form.Group>

                                  <Form.Group
                                    className="mb-3"
                                    controlId="formBasicEmail"
                                  >
                                    <Form.Label>App Id</Form.Label>
                                    <CustomTooltip text="Please Visit your User tab under admin panel.">
                                        <span className="custom_tooltip"><img src={Information} /></span>
                                      </CustomTooltip>
                                    <Form.Control
                                      name="app_id"
                                      type="text"
                                      className="emailfield"
                                      placeholder="Enter app id"
                                      onChange={handleWpChange}
                                    />
                                    <p style={{ color: "red" }}>
                                      {credsError.appIdErr}
                                    </p>
                                  </Form.Group>

                                  <Form.Group
                                    className="mb-3"
                                    controlId="formBasicPassword"
                                  >
                                    <Form.Label>Password
                                    <CustomTooltip text="Generate App password under user tab in admin panel.">
                                        <span className="custom_tooltip"><img src={Information} /></span>
                                      </CustomTooltip>
                                    </Form.Label>
                                    <Form.Control
                                      name="app_password"
                                      type="password"
                                      className="emailfield"
                                      placeholder="Enter password"
                                      onChange={handleWpChange}
                                    />
                                    <p style={{ color: "red" }}>
                                      {credsError.appPassErr}
                                    </p>
                                  </Form.Group>
                                  <Button
                                    variant="primary"
                                    type="submit"
                                    className="wp_submit"
                                    onClick={wordpressAuth}
                                  >
                                    Submit
                                  </Button>

                                  <button
                                    onClick={toggleCollapse}
                                    className="toggle__button1"
                                  >
                                    <img src={Toggle} />
                                  </button>
                                </div>

                                {isCollapsed1 ? null : (
                                  <div
                                    className={`w-50 wp_procedure px-4  ${
                                      isCollapsed1 ? "collapsed" : ""
                                    }`}
                                  >
                                    <h5 class="mb-3">
                                      To connect your WordPress site, please
                                      follow these steps:
                                    </h5>
                                    <ol>
                                      <li class="mb-2">
                                        Login to your WordPress Admin Dashboard
                                        where you want to publish your content.
                                      </li>
                                      <li class="mb-2">
                                        Proceed to the "Users" section located
                                        within the left main navigation page.
                                      </li>
                                      <li class="mb-2">
                                        Select the User account that you intend
                                        to use for publishing.
                                      </li>
                                      <li class="mb-2">
                                        After selecting User, go to Profile and
                                        navigate to the “Application Password”
                                        section.
                                      </li>
                                      <li class="mb-2">
                                        You need to enter name in the field “New
                                        Application Password Name”.
                                      </li>
                                      <li class="mb-2">
                                        Now click on “Add New Application
                                        Password” for password generation.
                                      </li>
                                      <li class="mb-2">
                                        After clicking, your Application
                                        Password will be generated.
                                      </li>
                                      <li class="mb-2">
                                        Now fill up the details - WordPress URL,
                                        Application ID, and Application Password
                                        in the form on the left.
                                      </li>
                                      <li class="mb-2">
                                        Lastly, click on “Connect” to complete
                                        the process and your WordPress site will
                                        be connected.
                                      </li>
                                    </ol>
                                  </div>
                                )}
                              </div>
                            </Modal.Body>
                       </Modal>
                      </div>
                      </div>
                    </div>
                  </div>

                  <div className="manage_members_section mt-5">
                    <div className="add_mem_ber_heading">
                      <h3>Social Platform Engagement</h3>
                      <div className="search_member_panel d-flex">
                        <div className="search_text_field">
                          <Form.Control
                            type="text"
                            placeholder="Search"
                            className="search_control_field"
                          />
                          <img src={SearchIcon} className="search_iconnn" />
                        </div>
                        <Button type="button" onClick={handleRefresh}>
                          Refresh
                        </Button>
                      </div>
                    </div>

                    <div className="manage_data">
                      <DataTable
                        columns={columns}
                        data={connectedAcListing()}
                        pagination
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg="3" md="12" sm="12" className="pr-0">
                <div className="dash_right_paertts">
                  <DashRightPanel />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SocialConnectPage;
