import React, { useState, useRef } from "react";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Form,
  Dropdown,
  Table,
  Accordion,
  Card,
  Tab,
  Nav,
} from "react-bootstrap";
import DashboardLeftPanel from "../../../components/DashboardLeftPanel/dashboardLeftPanel";
import DashRightPanel from "../../../components/DashboardRightPanel/dashbaordrightpanel";
import { Link, useHistory, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Audio } from "react-loader-spinner";
import axios from "../../../axios/axios";
import message from "../../../messages";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./generatearticle.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import Moment from "moment";
import moment from "moment";
import {
  SquareImage,
  ProtraitImage,
  LandscapeImage,
  // FacebookSocial,
  // InsSocial,
  // TwitterSocial,
  // LinkedSocial,
  FacebookSocial,
  InsSocial,
  TwitterSocial,
  LinkedSocial,
  PrevImg,
  Wp,
  PrevCross,
  FbLogo,
  Social1,
  Social2,
  Social3,
  Social4,
  Social5,
  SocialTop,
  DemoLogo,
  SocialImggg,
  Calendar11,
  Book22,
  Search1,
  Toggle,
  SearchIcon121,
  Folder,
  action,
} from "../../../assets/images";
import { format, parseISO, isValid, isFuture } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { FileUploader } from "react-drag-drop-files";
import { useEffect } from "react";
import CreateFolderModal from "../../../components/FolderCreate/CreateFolderModal";
const fileTypes = ["JPG", "PNG", "GIF"];

const roundTimeOptions = [
  "12:00 AM",
  "12:30 AM",
  "1:00 AM",
  "1:30 AM",
  "2:00 AM",
  "2:30 AM",
  "3:00 AM",
  "3:30 AM",
  "4:00 AM",
  "4:30 AM",
  "5:00 AM",
  "5:30 AM",
  "6:00 AM",
  "6:30 AM",
  "7:00 AM",
  "7:30 AM",
  "8:00 AM",
  "8:30 AM",
  "9:00 AM",
  "9:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",

  "12:00 PM",
  "12:30 PM",
  "1:00 PM",
  "1:30 PM",
  "2:00 PM",
  "2:30 PM",
  "3:00 PM",
  "3:30 PM",
  "4:00 PM",
  "4:30 PM",
  "5:00 PM",
  "5:30 PM",
  "6:00 PM",
  "6:30 PM",
  "7:00 PM",
  "7:30 PM",
  "8:00 PM",
  "8:30 PM",
  "9:00 PM",
  "9:30 PM",
  "10:00 PM",
  "10:30 PM",
  "11:00 PM",
  "11:30 PM",
];

const TwitterGenerate = (props) => {
  const params = useParams();
  const [collapsed, setCollapsed] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [showParentDiv, setShowParentDiv] = useState(true);
  const [showNextDiv, setShowNextDiv] = useState(false);
  //const [showParentDiv1, setShowParentDiv1] = useState(true);
  const [showNextDiv1, setShowNextDiv1] = useState(false);
  const [showNextDiv2, setShowNextDiv2] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const [keywords, setKeywords] = useState("");
  const [keywordsError, setKeywordsError] = useState("");
  const [value, setValue] = useState(0);
  const [valueError, setValueError] = useState("");
  const [content, setContent] = useState("");
  const [twitterContent, setTwitterContent] = useState("");
  const [imgTopic, setimgTopic] = useState("");
  const [loader, setLoader] = useState(false);
  const [selectedImage, setSelectedImage] = useState([]);
  const [img, setimg] = useState([]);
  const [regenerateImg, setRegenerateImg] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState("linkedin");
  const [showDiv1, setShowDiv1] = useState(false);
  const [startDate, setStartDate] = useState(""); /////////////
  const [dateError, setDateError] = useState(""); ///////////
  const [time, setTime] = useState(""); ///////////////
  const [timeError, setTimeError] = useState("");/////////////
  const [tags, setTags] = useState([]);
  const [dragImg, setDragImg] = useState(null);
  const [connectedAcc, setConnectedAcc] = useState([]);
  const [selectedAcc, setSelectedAcc] = useState([]);
  const history = useHistory();
  let today = Moment(new Date()).format("YYYY-MM-DD");
  const [publishedPost, setPublishedPost] = useState([]);
  const [schedulePost, setSchedulePost] = useState([]);
  const scrollTargetRef = useRef(null);
  const scrollTargetRefSchedule = useRef(null);
  const [selectedTabs, setSelectedTabs] = useState([]);
  const orgName = JSON.parse(localStorage.getItem("org_info"))?.name;
  const [showMore, setShowMore] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState("")

  const [showCreateModal, setShowCreateModal] = useState(false)
  const [folderName, setFolderName] = useState("")
  const [showModal, setShowModal] = useState(false)
  const [showWpModal, setShowWpModal] = useState(false)
  const [folders, setFolders] = useState([])
  const [errorMsg, setErrorMsg] = useState("")
  const [wpData, setWpData] = useState({ url: "", app_id: "", app_password: "" })
  const [credsError, setCredsError] = useState({
    urlErr: "",
    appIdErr: "",
    appPassErr: ""
  });
  const [ ckEditorData, setCkEditorData ] = useState("")
  const [genCreditModal, setGenCreditModal] = useState(false) 
  const [resError, setResError] = useState("")

  const handleSeeMore = () => {
    setShowMore(true);
  };

  useEffect(() => {
    if (params?.postId) {
      postDetails(params.postId);
    } else {
      setTitle("");
      setIsOpen(false);
      setContent("");
      setSelectedImage([]);
      setTags([]);
      setValue(0);
    }
  }, [params]);

  function countWordsFromString(str) {
    const words = str.split(/\s+/).filter((word) => word !== "");
    return words.length;
  }
  // Effect to load the state from localStorage on initial render
  useEffect(() => {
    const storedCollapsed = localStorage.getItem("collapsed");
    if (storedCollapsed) {
      setCollapsed(JSON.parse(storedCollapsed));
    }
  }, []);

  // Effect to save the state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("collapsed", JSON.stringify(collapsed));
  }, [collapsed]);

  // Effect to load the state from localStorage on initial render
  useEffect(() => {
    const storedExpanded = localStorage.getItem("expanded");
    if (storedExpanded) {
      setExpanded(JSON.parse(storedExpanded));
    }
  }, []);

  // Effect to save the state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("expanded", JSON.stringify(collapsed));
  }, [collapsed]);

  const postDetails = async (postId) => {
    await axios
      .get(`post/${postId}`)
      .then((res) => {
        console.log(res.data.data, "<<-- responseData");
        const data = res.data.data;
        setTitle(data.title);
        setIsOpen(true);
        setContent(data.content);
        setimg(data.images);
        setSelectedImage(data.images);
        setTags(data.tags);
        setKeywords(data.tags);
        setValue(countWordsFromString(data.content));
        setTwitterContent(data?.twitterContent);
      })
      .catch((err) => {
        console.error(err, "<<-- Error in get post details");
      });
  };

  const handleGoBack = (id) => {
    if (id === 2) {
      setShowParentDiv(true);
      setShowNextDiv(false);
    } else if (id === 3) {
      setShowNextDiv(true);
      setShowNextDiv1(false);
    } else if (id === 4) {
      setShowNextDiv1(true);
      setShowNextDiv2(false);
    }
  };

  const handleNextClick = () => {
    setShowParentDiv(false);
    setShowNextDiv(true);
  };

  const handleNextClick1 = () => {
    setShowNextDiv(false);
    setShowNextDiv1(true);
  };

  const handleNextClick2 = () => {
 
    if (selectedAcc.length > 0) {
      setShowNextDiv1(false);
      setShowNextDiv2(true);
    } else {
      message.error("Account selection is mandatory");
    }
  };

  const handleButtonClick = () => {
    setIsOpen(true);
    setIsOpen1(true);
  };

  const handleButtonClick1 = () => {
    setShowDiv1(!showDiv1);
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setCkEditorData(data)

  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
    setExpanded(!expanded);
  };

  // const handleFileChange = (event) => {
  //   setDragImg(event.target.files[0]);
  // };

  const publishScroll = () => {
    // Scroll to the target element
    scrollTargetRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const scheduleScroll = () => {
    // Scroll to the target element
    if (DateAndTimeValidate()) {
      scrollTargetRefSchedule.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleRegenerateImg = async (e) => {
    e.preventDefault();

    let paramsData = {
      title: title,
      no_of_images: 5,
    };
    setLoader(true);
    await axios
      .post("/image-generate", paramsData)
      .then((res) => {
        setLoader(false);
        setimg([...img, ...res.data.data]);
        setRegenerateImg(res.data.data);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };



  // const handleClickMedia = async (event, eventKey) => {
  //   // Access the eventKey value here
  //   setSelectedMedia(eventKey);

  //   if (eventKey === "twitter") {
  //     let paramsData = {
  //       title,
  //       keywords,
  //       no_of_words: 45,
  //     };
  //     // Submit the form or perform other actions
  //     setLoader(true);
  //     await axios
  //       .post("/content-generate", paramsData)
  //       .then((res) => {
  //         setLoader(false);
  //         setTwitterContent(res.data.data.content);
  //         handleButtonClick();
  //       })
  //       .catch((err) => {
  //         setLoader(false);
  //         console.log("err", err);
  //         message.error(err.response.data.message);
  //       });
  //   }
  // };

  const validateFrom = () => {
    setTitleError("");
    setKeywordsError("");
    setValueError("");
    if (!title) {
      setTitleError("Please enter your topic");
      return false;
    }
    if (!keywords) {
      setKeywordsError("Please enter your keywords");
      return false;
    }
    if (value == 0) {
      setValueError("Please enter number of word");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateFrom()) {
      let paramsData = {
        title,
        keywords,
        no_of_words: value,
      };
      // Submit the form or perform other actions
      setLoader(true);
      await axios
        .post("/content-generate", paramsData)
        .then((res) => {
          setLoader(false);
          setResError(res.data.message)
          if(res.data.status === true){
          setContent(res.data.data.content);
          handleButtonClick();
        }else{
            setGenCreditModal(true)
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log("err", err);
          message.error(err.response.data.message);
        });
    }
  };

  const handleClose = () => {
    setShowCreateModal(false)
    setFolderName("")
  }

  const handleCreate = async (e) => {
    e.preventDefault()
    console.log(folderName, "<<-- folderName")
    if (!folderName) {
      setErrorMsg("Folder name is required")
      return;
    } else {
      await axios.post("/create-folder", { "name": folderName }).then(res => {
        getFolders()
        message.success("Folder created successfully")
        setFolderName("")
        handleClose()
      }).catch(err => {
        console.error(err, "<<-- Error in create folder")
        if (err.code === "ERR_BAD_REQUEST") {
          message.error(err.response.data.message)
        }
      })
    }
  }

  const handleImgSubmit = async (e) => {
    e.preventDefault();

    let paramsData = {
      title: title,
      no_of_images: 5,
    };
    setLoader(true);
    await axios
      .post("/image-generate", paramsData)
      .then((res) => {
        setLoader(false);
        setimg(res.data.data);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };

  const handleRadioChange = (event) => {
    setSelectedImage(event.target.value);
  };
  // const handleCheckboxChange = (event) => {
  //   setSelectedImage([...selectedImage, event.target.value]);
  // };

  const handleCheckboxChange = (event) => {
    const checkboxValue = event.target.value;
    const isChecked = selectedImage.includes(checkboxValue);

    if (isChecked) {
      // If the checkbox value is already in the array, remove it to uncheck
      setSelectedImage(
        selectedImage.filter((value) => value !== checkboxValue)
      );
    } else {
      // If the checkbox value is not in the array, add it to check
      setSelectedImage([...selectedImage, checkboxValue]);
    }
  };

  const handleTimeChange = (event) => {
    const newTime = event.target.value;
    setTime(newTime);
  };

  const DateAndTimeValidate = () => {
    setDateError("");
    setTimeError("");

    if (!startDate) {
      setDateError("Date field is required");
      return false;
    } else if (startDate < today) {
      setDateError("Please select a date that is today or in the future");
      return false;
    }
    if (!time) {
      setTimeError("Time field is required");
      return false;
    }

    return true;
  };

  const handleSchedule = async (e) => {
    scheduleScroll();
    e.preventDefault();
    if (DateAndTimeValidate()) {
      let paramsData = {
        title: title,
        content: content,
        twitter_content: twitterContent,
        images: !dragImg ? selectedImage : [URL.createObjectURL(dragImg)],
        schedule_at: `${startDate} ${time}`,
        tags: tags,
        facebook_ids: selectedAcc
          .filter((account) => account.account_type === "Facebook")
          .map((account) => account.id),
        twitter_ids: selectedAcc
          .filter((account) => account.account_type === "Twitter")
          .map((account) => account.id),
        instagram_ids: selectedAcc
          .filter((account) => account.account_type === "Instagram")
          .map((account) => account.id),
        linkedin_ids: selectedAcc
          .filter((account) => account.account_type === "LinkedIn")
          .map((account) => account.id),
        wordpress_ids: selectedAcc
          .filter((account) => account.account_type === "WordPress")
          .map((account) => account.id),
      };

      if (params.postId) {
        paramsData.postId = params.postId;
      }
      // Submit the form or perform other actions
      setLoader(true);
      await axios
        .post("/post-schedule", paramsData)
        .then((res) => {
          setLoader(false);
          handleButtonClick1();
          getSchedulePost()
          message.success(res.data.message);
        })
        .catch((err) => {
          setLoader(false);
          console.log("err", err);
          message.error(err.response.data.message);
        });
    }
  };

  const handleRemoveImage = (item) => {
    setSelectedImage(selectedImage.filter((img) => img !== item));
    setRegenerateImg("");
    setDragImg("");
  };

  const TagsInput = ({ tags, onChange }) => {
    const [inputValue, setInputValue] = useState("");

    const handleInputChange = (event) => {
      setInputValue(event.target.value);
    };

    const handleInputKeyDown = (event) => {
      if (event.key === "Enter" || event.key === ",") {
        event.preventDefault();
        const newTag = inputValue.trim();
        if (newTag !== "" && !tags.includes(newTag)) {
          onChange([...tags, newTag]);
        }
        setInputValue("");
      } else if (event.key === "Backspace" && inputValue === "") {
        onChange(tags.slice(0, -1));
      }
    };

    const handleTagRemove = (tagToRemove) => {
      const updatedTags = tags.filter((tag) => tag !== tagToRemove);
      onChange(updatedTags);
    };

    return (
      <div className="keywords">
        <label>Keywords</label>
        <div className="tags-input-container">
          <div className="tags">
            {tags.map((tag) => (
              <span key={tag} className="tag">
                {tag}
                <span
                  className="tag-remove"
                  onClick={() => handleTagRemove(tag)}
                >
                  &times;
                </span>
              </span>
            ))}
          </div>
          <input
            type="text"
            className="tags-input"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
            placeholder="Use comma or enter to add tags..."
          />
        </div>
      </div>
    );
  };

  const handleTagsChange = (newTags) => {
    setTags(newTags);
    setKeywords(newTags);
  };

  const handleTabSelect = (tab) => {
    setSelectedTabs((prevSelected) => {
      // Check if the tab is already selected, and toggle the selection accordingly
      if (prevSelected.includes(tab)) {
        return prevSelected.filter((t) => t !== tab);
      } else {
        return [...prevSelected, tab];
      }
    });
  };

  const facebookAuth = async () => {
    setLoader(true);
    await axios
      .get("/facebook/auth")
      .then((res) => {
        setLoader(false);
        // console.log("data", res);
        window.open(res.data.data.url, "_blank");
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };

  const instagramAuth = async () => {
    setLoader(true);
    await axios
      .get("/instagram/auth")
      .then((res) => {
        setLoader(false);
        // console.log("data", res);
        window.open(res.data.data.url, "_self");
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };

  const twitterAuth = async () => {
    setLoader(true);
    await axios
      .get("/twitter/auth")
      .then((res) => {
        setLoader(false);
        // console.log("data", res);
        window.open(res.data.data.url, "_blank");
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };

  const linkedinAuth = async () => {
    setLoader(true);
    await axios
      .get("/linkedin/auth")
      .then((res) => {
        setLoader(false);
        // console.log("data", res);
        window.open(res.data.data.url, "_blank");
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };

  // const wordpressAuth = async () => {
  //   setLoader(true);
  //   await axios
  //     .get("/wordpress/auth")
  //     .then((res) => {
  //       setLoader(false);
  //       // console.log("data", res);
  //       window.open(res.data.data.url, "_blank");
  //     })
  //     .catch((err) => {
  //       setLoader(false);
  //       console.log("err", err);
  //       message.error(err.response.data.message);
  //     });
  // };


  const wordpressAuth = async () => {
    setLoader(true);

    if (!wpData.app_id) {
      setCredsError({ ...credsError, appIdErr: "AppId is required" })
      return
    }
    if (!wpData.url) {
      setCredsError({ ...credsError, urlErr: "Url is required" })
      return
    }
    if (!wpData.app_password) {
      setCredsError({ ...credsError, appPassErr: "AppId is required" })
      return
    } else {
      await axios
        .post("/wordpress/auth", wpData)
        .then((res) => {
          setLoader(false);
          // console.log("data", res);
          // window.open(res.data.data.url, "_self");
          handleCloseModal()
        })
        .catch((err) => {
          setLoader(false);
          console.log("err", err);
          message.error(err.response.data.message);
        });
    }
  }

  const handleWpChange = (event) => {
    const { name, value } = event.target;
    setWpData({ ...wpData, [name]: value })
  }

  const handleAccChange = async (e, accountId, account_type) => {
    const isChecked = e.target.checked;

    if (account_type === "Twitter") {
      let paramsData = {
        title,
        keywords,
        no_of_words: 45,
      };
      // Submit the form or perform other actions
      setLoader(true);
      await axios
        .post("/content-generate", paramsData)
        .then((res) => {
          setLoader(false);
          setTwitterContent(res.data.data.content);
          handleButtonClick();
        })
        .catch((err) => {
          setLoader(false);
          console.log("err", err);
          message.error(err.response.data.message);
        });
    }

    // Check if the account with the provided id exists in the selectedAcc array
    const existingAccountIndex = selectedAcc.findIndex(
      (account) => account.id === accountId
    );

    if (existingAccountIndex !== -1) {
      // Account exists, so update its selected property
      setSelectedAcc(selectedAcc.filter((acc) => acc.id !== accountId));
    } else {
      // Account does not exist, so add the new account data
      setSelectedAcc((prevSelectedAcc) => [
        ...prevSelectedAcc,
        { id: accountId, account_type: account_type, selected: isChecked },
      ]);
    }
  };

  const getAllSocialAccount = async () => {
    await axios
      .get("/social/accounts")
      .then((res) => {
        const instaAcc = res.data.data
        const filterAc = instaAcc.filter((iAc)=> iAc.account_type==="Twitter")
        console.log("filterAc", filterAc)
        setConnectedAcc(filterAc);
      })
      .catch((err) => {
        // console.error(err, "<<-- Error in get all social accounts")
      });
  };

  const handlePostPublish = async () => {
    publishScroll();
    let paramsData = {
      title: title,
      content: content,
      twitter_content: twitterContent,
      images: !dragImg ? selectedImage : [URL.createObjectURL(dragImg)],
      tags: tags,
      facebook_ids: selectedAcc
        .filter((account) => account.account_type === "Facebook")
        .map((account) => account.id),
      twitter_ids: selectedAcc
        .filter((account) => account.account_type === "Twitter")
        .map((account) => account.id),
      instagram_ids: selectedAcc
        .filter((account) => account.account_type === "Instagram")
        .map((account) => account.id),
      linkedin_ids: selectedAcc
        .filter((account) => account.account_type === "LinkedIn")
        .map((account) => account.id),
      wordpress_ids: selectedAcc
        .filter((account) => account.account_type === "WordPress")
        .map((account) => account.id),
    };
    if (params.postId) {
      paramsData.postId = params.postId;
    }
    setLoader(true)
    await axios
      .post("/post-publish", paramsData)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false)
          getPublishPost();
          message.success("Post published successfully");
          // setTimeout(() => {
          //   history.push("/dashboard");
          // }, 1000);
        }
      })
      .catch((err) => {
        setLoader(false)
        // console.error(err, "<<-- Error in post publish")
        message.error("Internal server error");
      });
  };

  const getPublishPost = async () => {
    setLoader(true);
    await axios
      .get("/archive-post")
      .then((res) => {
        setLoader(false);
        setPublishedPost(res.data.data);
        // console.log(res.data.data);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };
  const getSchedulePost = async () => {
    setLoader(true);
    await axios
      .get("/scheduled-post")
      .then((res) => {
        setLoader(false);
        setSchedulePost(res.data.data);
        // console.log(res.data.data);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };

  const archivePost = () => {
    let post = [];
    publishedPost.map((item, i) => {
      post.push({
        topics: (
          <div className="management_list_name">
            <div className="man_text">{item.title}</div>
          </div>
        ),
        // publisMember: <div className="man_username">publish member</div>,
        created_At: (
          <div className="man_username">
            {moment(item.createdAt).format("DD/MM/YYYY LTS")}
          </div>
        ),
        platforms: (
          <div className="man_username">
            <span>
              {item.wordpress.length <= 0 ? (
                <></>
              ) : (
                <img style={{ width: "30px", height: "30px" }} src={Wp} />
              )}
            </span>
            <span>
              {item.facebook.length <= 0 ? (
                <></>
              ) : (
                <img
                  style={{ width: "30px", height: "30px" }}
                  src={FacebookSocial}
                />
              )}
            </span>
            <span>
              {item.twitter.length <= 0 ? (
                <></>
              ) : (
                <img
                  style={{ width: "30px", height: "30px" }}
                  src={TwitterSocial}
                />
              )}
            </span>
            <span>
              {item.instagram.length <= 0 ? (
                <></>
              ) : (
                <img
                  style={{ width: "30px", height: "30px" }}
                  src={InsSocial}
                />
              )}
            </span>
            <span>
              {item.linkedin.length <= 0 ? (
                <></>
              ) : (
                <img
                  style={{ width: "30px", height: "30px" }}
                  src={LinkedSocial}
                />
              )}
            </span>
          </div>
        ),
        status: <div className="man_username">{item.status}</div>,
        // action: (
        //   <div className="action_partts">
        //     <Dropdown>
        //       <Dropdown.Toggle variant="success" id="dropdown-basic">
        //         <img src={action} />
        //       </Dropdown.Toggle>

        //       <Dropdown.Menu>
        //         <Dropdown.Item href={`/manage-members/${item._id}`}>
        //           Edit
        //         </Dropdown.Item>
        //         <Dropdown.Item onClick={() => deleteUser(item._id)}>
        //           Delete
        //         </Dropdown.Item>
        //       </Dropdown.Menu>
        //     </Dropdown>
        //   </div>
        // ),
      });
    });
    return post;
  };

  const archiveSchedule = () => {
    let post = [];
    schedulePost.map((item, i) => {
      post.push({
        topics: (
          <div className="management_list_name">
            <div className="man_text">{item.title}</div>
          </div>
        ),
        // publisMember: <div className="man_username">publish member</div>,
        created_At: (
          <div className="man_username">
            {moment(item.createdAt).format("DD/MM/YYYY LTS")}
          </div>
        ),
        platforms: (
          <div className="man_username">
            <span>
              {item.wordpress.length <= 0 ? (
                <></>
              ) : (
                <img style={{ width: "30px", height: "30px" }} src={Wp} />
              )}
            </span>
            <span>
              {item.facebook.length <= 0 ? (
                <></>
              ) : (
                <img
                  style={{ width: "30px", height: "30px" }}
                  src={FacebookSocial}
                />
              )}
            </span>
            <span>
              {item.twitter.length <= 0 ? (
                <></>
              ) : (
                <img
                  style={{ width: "30px", height: "30px" }}
                  src={TwitterSocial}
                />
              )}
            </span>
            <span>
              {item.instagram.length <= 0 ? (
                <></>
              ) : (
                <img
                  style={{ width: "30px", height: "30px" }}
                  src={InsSocial}
                />
              )}
            </span>
            <span>
              {item.linkedin.length <= 0 ? (
                <></>
              ) : (
                <img
                  style={{ width: "30px", height: "30px" }}
                  src={LinkedSocial}
                />
              )}
            </span>
          </div>
        ),
        status: <div className="man_username">{item.status}</div>,
        // action: (
        //   <div className="action_partts">
        //     <Dropdown>
        //       <Dropdown.Toggle variant="success" id="dropdown-basic">
        //         <img src={action} />
        //       </Dropdown.Toggle>

        //       <Dropdown.Menu>
        //         <Dropdown.Item href={`/manage-members/${item._id}`}>
        //           Edit
        //         </Dropdown.Item>
        //         <Dropdown.Item onClick={() => deleteUser(item._id)}>
        //           Delete
        //         </Dropdown.Item>
        //       </Dropdown.Menu>
        //     </Dropdown>
        //   </div>
        // ),
      });
    });
    return post;
  };

  useEffect(() => {
    getPublishPost();
    getSchedulePost();
    getAllSocialAccount();
  }, []);

  const columns = [
    {
      name: "Sl No.",
      selector: (row, ind) => ind + 1,
      width: "100px",
      sortable: true,
      style: {
        padding: "10px 7px",
        // height: "50px",
        // display: "block",
      },
    },
    {
      name: " Article Topics",
      selector: (row) => row.topics,
      width: "190px",
      sortable: true,
      style: {
        padding: "10px 7px",
        // height: "50px",
        // display: "block",
      },
    },
    // {
    //   name: "Published Members",
    //   selector: (row) => row.publisMember,
    //   width: "180px",
    //   sortable: true,
    //   style: {
    //     padding: "10px 7px",
    //     // height: "50px",
    //     // display: "block",
    //   },
    // },
    {
      name: "Creation Date",
      selector: (row) => row.created_At,
      width: "200px",
      sortable: true,
      style: {
        padding: "10px 7px",
        // height: "50px",
        // display: "block",
      },
    },
    {
      name: "Shared Platform",
      selector: (row) => row.platforms,
      width: "170px",
      sortable: true,
      style: {
        padding: "10px 7px",
        // height: "50px",
        // display: "block",
      },
    },
    {
      name: "Status",
      selector: (row) => row.status,
      width: "170px",
      sortable: true,
      style: {
        padding: "10px 7px",
        // height: "50px",
        // display: "block",
      },
    },
    // {
    //     name: 'Action',
    //     selector: row => row.action,
    //     sortable: true,
    //     width: '110px',
    //     style: {
    //         padding: "10px 7px",
    //         // height: "50px",
    //         // display: "block",
    //       },
    // },
  ];

  const socialAccountData = {
    Facebook: {
      icon: Social1,
      name: "Facebook",
    },
    Instagram: {
      icon: Social4,
      name: "Instagram",
    },
    Twitter: {
      icon: Social2,
      name: "Twitter",
    },
    LinkedIn: {
      icon: Social3,
      name: "Linkedin",
    },
    WordPress: {
      icon: Social5,
      name: "Wordpress",
      additionalData: {
        image: SocialImggg,
        title: "UX Design online course with certificate and placements",
        date: "Monday, July 3, 2023",
        category: "UI UX Design",
        content:
          "User experience (UX) design is the process design teams use to create products that provide meaningful and relevant experiences to users...",
      },
    },
  };

  const getFolders = async () => {
    await axios
      .get("/my-folders")
      .then((res) => {
        // console.log(res, "<<-- response");
        setFolders(res.data.data);
      })
      .catch((err) => {
        console.error("err",err);
      });
  };

  useEffect(() => {
    getFolders();
  }, []);

  const selectFolder = (folder) => {
    setSelectedFolder(folder._id);
  };

  const savePost = async () => {
    let paramsData = {
      title: title,
      content: content,
      twitter_content: twitterContent,
      images: !dragImg ? selectedImage : [URL.createObjectURL(dragImg)],
      tags: tags,
      folder_id: selectedFolder,
      facebook_ids: selectedAcc
        .filter((account) => account.account_type === "Facebook")
        .map((account) => account.id),
      twitter_ids: selectedAcc
        .filter((account) => account.account_type === "Twitter")
        .map((account) => account.id),
      instagram_ids: selectedAcc
        .filter((account) => account.account_type === "Instagram")
        .map((account) => account.id),
      linkedin_ids: selectedAcc
        .filter((account) => account.account_type === "LinkedIn")
        .map((account) => account.id),
      wordpress_ids: selectedAcc
        .filter((account) => account.account_type === "WordPress")
        .map((account) => account.id),
    };

    await axios.post("/post-draft", paramsData).then(res => {
      if (res.data.status) {
        message.success("Post saved successfully")
        setShowModal(false)
        history.push("/my-folder")
      }
    }).catch(err => {
      console.error(err, "<<-- Error in post save")
      message.error("Post save failed")
    })
  }

  const handleShowModal = () => {
    setShowWpModal(true);
  };
  const handleCloseModal = () => {
    setShowWpModal(false);
  };
  return (
    <section className="dash_partts">
      <div className="dash_inner_partts">
        <div className={`sidebar ${collapsed ? "collapsed" : ""}`}>
          <DashboardLeftPanel />
        </div>

        <div className={`dash_right_panel ${expanded ? "expanded" : ""}`}>
          <button onClick={toggleSidebar} className="toggle__button">
            <img src={Toggle} />
          </button>
          <div className="dash_right_inner_panel">
            <Row>
              <Col lg="9" md="12" sm="12">
                {loader == true && (
                  <div className="loader-wrapper">
                    <Audio
                      height="80"
                      width="80"
                      radius="9"
                      color="green"
                      ariaLabel="loading"
                      wrapperStyle
                      wrapperClass
                    />
                  </div>
                )}
                {showParentDiv && (
                  <div className="generate_article_parttts">
                    <div className="generate_article_top_sec">
                      <div className="generate_article_topper">
                        <div className="art_left active">
                          <div className="art_box">1</div>
                          <p>Generate Article with Image Generation</p>
                        </div>
                        <div className="art_middle"></div>
                        <div className="art_left inactive">
                          <div className="art_box">2</div>
                          <p>Review Article & Images in Channels</p>
                        </div>
                        <div className="art_middle"></div>
                        <div className="art_left inactive">
                          <div className="art_box">3</div>
                          <p>Social Media Connect</p>
                        </div>
                        <div className="art_middle"></div>
                        <div className="art_left inactive">
                          <div className="art_box">4</div>
                          <p>Final preview and Publish</p>
                        </div>
                      </div>
                    </div>

                    <div className="describe_topic">
                      <Form>
                        <Form.Group
                          className="mb-3 describe_form"
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Describe your topic</Form.Label>
                          <Form.Control
                            as="textarea"
                            value={title}
                            rows={3}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                          {titleError && (
                            <p style={{ color: "red" }}>{titleError}</p>
                          )}
                        </Form.Group>
                        {/* <Form.Group
                              className="mb-3 describe_form"
                              controlId="formBasicPassword"
                            >
                              <Form.Label>Keywords</Form.Label>
                              <div className="tagger">
                              <Form.Control
                                type="text"
                                value={keywords}
                                placeholder="Type Keywords"
                                className="des_text_field"
                                onChange={(e) => setKeywords(e.target.value)}
                              />
                              {keywordsError && (
                                <p style={{ color: "red" }}>{keywordsError}</p>
                              )}
                            </div>
                            </Form.Group> */}
                        <Form.Group>
                          <TagsInput tags={tags} onChange={handleTagsChange} />
                          <div>
                            {keywordsError && (
                              <p style={{ color: "red" }}>{keywordsError}</p>
                            )}
                          </div>
                        </Form.Group>
                        <div className="words_limit">
                          <div className="limit_heading d-flex flex-wrap justify-content-between">
                            <label>Words limit (150-280)</label>
                            <span>{value} Words</span>
                          </div>
                          <Slider
                            value={value}
                            onChange={handleChange}
                            min={0}
                            max={280}
                          />
                          {/* <div>Value: </div> */}
                          {valueError && (
                            <p style={{ color: "red" }}>{valueError}</p>
                          )}
                        </div>
                        <div className="text-center">
                          <Button
                            variant="primary"
                            type="button"
                            className="generate_button"
                            onClick={handleSubmit}
                          >
                            Generate Article
                          </Button>
                        </div>
                      </Form>
                      {isOpen && (
                        <div className="edit_article">
                          <div className="edit_art_top">
                            <h3>Edit your article</h3>

                            <div className="ckeditor_partts">
                              <CKEditor
                                editor={ClassicEditor}
                                onChange={handleEditorChange}
                                data={content}
                              />
                            </div>
                          </div>

                          <div className="generate_images">
                            {!img.length > 0 && <h3>Choose Images</h3>}
                            {!img.length > 0 && (
                              <div className="generate_images_inner">
                                <input
                                  type="file"
                                  name="imagefile"
                                  accept="image/*"
                                  onChange={(event) => {
                                    setDragImg(event.target.files[0]);
                                  }}
                                />

                                {/* <div className='text-center'>
                                                    <Button variant="primary" type="button" className='generate_button' onClick={handleButtonClick}>
                                                        Generate Images
                                                    </Button>
                                                </div> */}
                              </div>
                            )}

                            <div className="generate_images_partts">
                              <div className="generate_images_inner_partts">
                                {!dragImg && <h3>Generate Images</h3>}
                                {/* <div className="your_topic"> */}
                                {/* <h4>Your Topic</h4> */}
                                {/* <div className="topic_name d-flex justify-content-between w-70"> */}
                                {/* <Form.Control
                                      type="text"
                                      value={imgTopic}
                                      onChange={(e) =>
                                        setimgTopic(e.target.value)
                                      }
                                      className="topic_text"
                                      placeholder="UX Design online course"
                                    >
                                      {/* {imgTopicError && (
                                        <p style={{ color: "red" }}>
                                          {imgTopicError}
                                        </p>
                                      )} */}
                                {/* </Form.Control> */}
                                {!dragImg && (
                                  <Button
                                    variant="primary"
                                    type="button"
                                    // disabled={!imgTopic}
                                    className="generate_button mt-0"
                                    onClick={handleImgSubmit}
                                  >
                                    Generate
                                  </Button>
                                )}
                                {/* </div> */}
                                {/* </div> */}

                                {/* <div className="image_size">
                                  <h4>Image Size</h4>
                                  <div className="image_size_partts">
                                    <label className="labl">
                                      <input
                                        type="radio"
                                        name="radioname"
                                        value="one_value"
                                      />
                                      <div>
                                        <img src={SquareImage} />
                                      </div>
                                      <p>Square</p>
                                    </label>
                                    <label className="labl">
                                      <input
                                        type="radio"
                                        name="radioname"
                                        value="another"
                                      />
                                      <div>
                                        <img src={ProtraitImage} />
                                      </div>
                                      <p>Potrait</p>
                                    </label>

                                    <label className="labl">
                                      <input
                                        type="radio"
                                        name="radioname"
                                        value="another_one"
                                      />
                                      <div>
                                        <img src={LandscapeImage} />
                                      </div>
                                      <p>Landscape</p>
                                    </label>
                                  </div>
                                </div> */}

                                <div className="generated_images">
                                  <div className="generated_image_inner">
                                    {img.map((item, key) => {
                                      // console.log("url",item.url)
                                      return (
                                        <label
                                          key={item.url}
                                          className="labl2"
                                          for={key}
                                        >
                                          <input
                                            type="checkbox"
                                            name={key}
                                            value={item.url}
                                            id={key}
                                            checked={selectedImage.includes(
                                              item.url
                                            )}
                                            onChange={handleCheckboxChange}
                                          />
                                          <div>
                                            <img
                                              src={item.url || item}
                                              className="img-fluid"
                                            />
                                          </div>
                                        </label>
                                      );
                                    })}
                                  </div>
                                </div>
                                {/* <div className="generated_images">
                                  <div className="generated_image_inner">
                                    {regenerateImg.map((item, key) => {
                                      // console.log("url",item.url)
                                      return (
                                        <label key={item.url} className="labl2" for={key}>
                                          <input
                                            type="checkbox"
                                            name={key}
                                            id={key}
                                            value={item.url}
                                            checked={selectedImage.includes(item.url)}
                                            onChange={handleCheckboxChange}
                                          />
                                          <div>
                                            <img
                                              src={item.url}
                                              className="img-fluid"
                                            />
                                          </div>
                                        </label>
                                      );
                                    })}
                                  </div>
                                </div> */}

                                <div className="generate_buttons">
                                  {!dragImg && (
                                    <Button
                                      type="button"
                                      className="generate_buttts"
                                      // disabled={!imgTopic}
                                      onClick={handleRegenerateImg}
                                    >
                                      Regenerate
                                    </Button>
                                  )}

                                  <Button
                                    type="button"
                                    className="save_buttts"
                                    onClick={handleNextClick}
                                  >
                                    Save
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {showNextDiv && (
                  <div className="generate_article_parttts">
                    <div className="generate_article_top_sec">
                      <div className="generate_article_topper">
                        <div className="art_left active">
                          <div className="art_box bg-blue">1</div>
                          <p>Generate Article with Image Generation</p>
                        </div>
                        <div className="art_middle active"></div>
                        <div className="art_left active">
                          <div className="art_box">2</div>
                          <p>Review Article & Images in Channels</p>
                        </div>

                        <div className="art_middle"></div>
                        <div className="art_left inactive">
                          <div className="art_box">3</div>
                          <p>Social Media Connect</p>
                        </div>
                        <div className="art_middle"></div>
                        <div className="art_left inactive">
                          <div className="art_box">4</div>
                          <p>Final preview and Publish</p>
                        </div>
                      </div>
                    </div>

                    <div className="review_articles">
                      <Tab.Container
                        id="tabs-container"
                        defaultActiveKey="tab1"
                      >
                        {/* <Nav
                          variant="tabs"
                          className="social_connect_inner_sec tab_inner_inner_seccc"
                        >
                          <Nav.Item className="social_inner_inner_sec">
                            <Nav.Link
                              eventKey="tab1"
                              onClick={(e) => handleClickMedia(e, "facebook")}
                            >
                              <div className="social_white_sec">
                                <img src={FacebookSocial} width={35} />
                                <h4>Facebook</h4>
                              </div>
                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item className="social_inner_inner_sec">
                            <Nav.Link
                              eventKey="tab2"
                              onClick={(e) => handleClickMedia(e, "instagram")}
                            >
                              <div className="social_white_sec" id="instagram">
                                <img src={InsSocial} width={35} />
                                <h4>Instagram</h4>
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item
                            className="social_inner_inner_sec"
                            onClick={(e) => handleClickMedia(e, "twitter")}
                          >
                            <Nav.Link eventKey="tab3" id="twitter">
                              <div className="social_white_sec">
                                <img src={TwitterSocial} width={35} />
                                <h4>Twitter</h4>
                              </div>
                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item className="social_inner_inner_sec">
                            <Nav.Link eventKey="tab4" id="linkedin">
                              <div className="social_white_sec">
                                <img
                                  src={LinkedSocial}
                                  width={35}
                                  onClick={(e) =>
                                    handleClickMedia(e, "linkedin")
                                  }
                                />
                                <h4>Linkedin</h4>
                              </div>
                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item className="social_inner_inner_sec">
                            <Nav.Link
                              eventKey="tab5"
                              id="wordpress"
                              onClick={(e) => handleClickMedia(e, "wordpress")}
                            >
                              <div className="social_white_sec">
                                <img src={Wp} width={33} />
                                <h4>Wordpress Blog</h4>
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                        </Nav> */}

                        <Tab.Content className="social_content">
                          <Tab.Pane eventKey="tab1">
                            <div className="preview__partts">
                              <div className="prev_top">
                                <Row>
                                  <Col lg="6" md="6">
                                    <div className="prev_left_text">
                                      {/* <p>{content} </p> */}
                                      <p dangerouslySetInnerHTML={{__html:ckEditorData}}></p>
                                      <div className="prev_art_images_boxx">
                                        {selectedImage || dragImg != "" ? (
                                          selectedImage.map((item) => {
                                            return (
                                              !!item && (
                                                <div className="prev_art_images">
                                                  <div className="prev_art_image_inner mb-4">
                                                    <>
                                                      <img
                                                        src={item}
                                                        className="img-fluid"
                                                      />
                                                      <div className="prev_cross">
                                                        {/* <img src={PrevCross} /> */}
                                                        <div
                                                          className="prev_cross_inner"
                                                          onClick={() =>
                                                            handleRemoveImage(
                                                              item
                                                            )
                                                          }
                                                        >
                                                          X
                                                        </div>
                                                      </div>
                                                    </>
                                                  </div>
                                                </div>
                                              )
                                            );
                                          })
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col lg="6" md="6">
                                    <div className="prev_right ">
                                      <h4 className="mb-3">Preview</h4>
                                      <div className="social_boxxx mt-0">
                                        {/* <img
                                          src={SocialTop}
                                          className="img-fluid top__imgg"
                                        /> */}
                                        <div className="social_prev_head d-flex align-items-center">
                                          <div className="social_img">
                                            <img src={DemoLogo} />
                                          </div>
                                          <div className="social_img_text">
                                            <h5>
                                              {orgName ||
                                                "HMC Sales and Services PVT. LTD."}
                                            </h5>
                                          </div>
                                        </div>
                                        {/* <p>
                                          {content}
                                        </p> */}
                                        <p dangerouslySetInnerHTML={{__html:ckEditorData}}></p>
                                        {showMore && (
                                          <p>More content goes here...</p>
                                        )}
                                        {!showMore && (
                                          <button onClick={handleSeeMore}>
                                            ... See More
                                          </button>
                                        )}
                                        <div className="socialll_images mt-5">
                                          <span>
                                            {selectedImage.map((item) => {
                                              return (
                                                !!item && (
                                                  <img
                                                    src={item}
                                                    className="img-fluid"
                                                  />
                                                )
                                              );
                                            })}
                                            {dragImg && (
                                              <img
                                                className="img-fluid"
                                                src={URL.createObjectURL(
                                                  dragImg
                                                )}
                                              />
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </div>

                              {/* <div className="prev_bottom">
                                <h4>Select your organization page : </h4>
                                <div className="org_inner">
                                  <div className="org_innr_innrr">
                                    <div className="org_innr_partts">
                                      <input
                                        className="styled-checkbox"
                                        id="styled-checkbox-1"
                                        type="checkbox"
                                        value="value1"
                                      />
                                      <label for="styled-checkbox-1">
                                        <img src={FbLogo} />
                                        <span>_deeya Sur_</span>
                                      </label>
                                    </div>
                                  </div>

                                  {/* <div className="org_innr_innrr">
                                    <div className="org_innr_partts">
                                      <input
                                        className="styled-checkbox"
                                        id="styled-checkbox-2"
                                        type="checkbox"
                                        value="value1"
                                      />
                                      <label for="styled-checkbox-2">
                                        <img src={FbLogo} />
                                        <span>_deeya Sur_</span>
                                      </label>
                                    </div>
                                  </div>

                                  <div className="org_innr_innrr">
                                    <div className="org_innr_partts">
                                      <input
                                        className="styled-checkbox"
                                        id="styled-checkbox-3"
                                        type="checkbox"
                                        value="value1"
                                      />
                                      <label for="styled-checkbox-3">
                                        <img src={FbLogo} />
                                        <span>_deeya Sur_</span>
                                      </label>
                                    </div>
                                  </div>

                                  <div className="org_innr_innrr">
                                    <div className="org_innr_partts">
                                      <input
                                        className="styled-checkbox"
                                        id="styled-checkbox-4"
                                        type="checkbox"
                                        value="value1"
                                      />
                                      <label for="styled-checkbox-4">
                                        <img src={FbLogo} />
                                        <span>_deeya Sur_</span>
                                      </label>
                                    </div>
                                  </div> */}
                              {/* </div>
                              </div> */}
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="tab2">
                            <Row>
                              <Col lg="6" md="6">
                                <div className="prev_left_text">
                                  {/* <p>{content} </p> */}
                                  <p dangerouslySetInnerHTML={{__html:ckEditorData}}></p>
                                  <div className="prev_art_images_boxx">
                                    {selectedImage || dragImg != "" ? (
                                      selectedImage.map((item) => {
                                        return (
                                          !!item && (
                                            <div className="prev_art_images">
                                              <div className="prev_art_image_inner mb-4">
                                                <>
                                                  <img
                                                    src={item}
                                                    className="img-fluid"
                                                  />
                                                  <div className="prev_cross">
                                                    {/* <img src={PrevCross} /> */}
                                                    <div
                                                      className="prev_cross_inner"
                                                      onClick={() =>
                                                        handleRemoveImage(item)
                                                      }
                                                    >
                                                      X
                                                    </div>
                                                  </div>
                                                </>
                                              </div>
                                            </div>
                                          )
                                        );
                                      })
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              </Col>
                              <Col lg="6" md="6">
                                <div className="prev_right ">
                                  <h4 className="mb-3">Preview</h4>
                                  <div className="social_boxxx mt-0">
                                    <div className="social_prev_head d-flex align-items-center">
                                      <div className="social_img">
                                        <img src={DemoLogo} />
                                      </div>
                                      <div className="social_img_text">
                                        <h5>
                                          {orgName ||
                                            "HMC Sales and Services PVT. LTD."}
                                        </h5>
                                        {/* <p>Suggested for you . 21 June . </p> */}
                                      </div>
                                    </div>
                                    {/* <p>
                                      {content}
                                      <a href="#">... See More</a>
                                    </p> */}
                                    <p dangerouslySetInnerHTML={{__html:ckEditorData}}></p>

                                    <div className="socialll_images mt-5">
                                      <span>
                                        {selectedImage.map((item) => {
                                          return (
                                            !!item && (
                                              <img
                                                src={item}
                                                className="img-fluid"
                                              />
                                            )
                                          );
                                        })}
                                        {dragImg && (
                                          <img
                                            className="img-fluid"
                                            src={URL.createObjectURL(dragImg)}
                                          />
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Tab.Pane>
                          <Tab.Pane eventKey="tab3">
                            <Row>
                              <Col lg="6" md="6">
                                <div className="prev_left_text">
                                  {/* <p>{content} </p> */}
                                  <p dangerouslySetInnerHTML={{__html:ckEditorData}}></p>
                                  <div className="prev_art_images_boxx">
                                    {selectedImage || dragImg != "" ? (
                                      selectedImage.map((item) => {
                                        return (
                                          !!item && (
                                            <div className="prev_art_images">
                                              <div className="prev_art_image_inner mb-4">
                                                <>
                                                  <img
                                                    src={item}
                                                    className="img-fluid"
                                                  />
                                                  <div className="prev_cross">
                                                    {/* <img src={PrevCross} /> */}
                                                    <div
                                                      className="prev_cross_inner"
                                                      onClick={() =>
                                                        handleRemoveImage(item)
                                                      }
                                                    >
                                                      X
                                                    </div>
                                                  </div>
                                                </>
                                              </div>
                                            </div>
                                          )
                                        );
                                      })
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              </Col>
                              <Col lg="6" md="6">
                                <div className="prev_right ">
                                  <h4 className="mb-3">Preview</h4>
                                  <div className="social_boxxx mt-0">
                                    <div className="social_prev_head d-flex align-items-center">
                                      <div className="social_img">
                                        <img src={DemoLogo} />
                                      </div>
                                      <div className="social_img_text">
                                        <h5>
                                          {orgName ||
                                            "HMC Sales and Services PVT. LTD."}
                                        </h5>
                                        {/* <p>Suggested for you . 21 June . </p> */}
                                      </div>
                                    </div>
                                    {/* <p>
                                      {content}
                                      <a href="#">... See More</a>
                                    </p> */}
                                    <p dangerouslySetInnerHTML={{__html:ckEditorData}}></p>
                                    {showMore && (
                                      <p>More content goes here...</p>
                                    )}
                                    {!showMore && (
                                      <button onClick={handleSeeMore}>
                                        ... See More
                                      </button>
                                    )}
                                    <div className="socialll_images mt-5">
                                      <span>
                                        {selectedImage.map((item) => {
                                          return (
                                            !!item && (
                                              <img
                                                src={item}
                                                className="img-fluid"
                                              />
                                            )
                                          );
                                        })}
                                        {dragImg && (
                                          <img
                                            className="img-fluid"
                                            src={URL.createObjectURL(dragImg)}
                                          />
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Tab.Pane>

                          <Tab.Pane eventKey="tab4">
                            <Row>
                              <Col lg="6" md="6">
                                <div className="prev_left_text">
                                  {/* <p>{content} </p> */}
                                  <p dangerouslySetInnerHTML={{__html:ckEditorData}}></p>
                                  <div className="prev_art_images_boxx">
                                    {selectedImage || dragImg != "" ? (
                                      selectedImage.map((item) => {
                                        return (
                                          !!item && (
                                            <div className="prev_art_images">
                                              <div className="prev_art_image_inner mb-4">
                                                <>
                                                  <img
                                                    src={item}
                                                    className="img-fluid"
                                                  />
                                                  <div className="prev_cross">
                                                    {/* <img src={PrevCross} /> */}
                                                    <div
                                                      className="prev_cross_inner"
                                                      onClick={() =>
                                                        handleRemoveImage(item)
                                                      }
                                                    >
                                                      X
                                                    </div>
                                                  </div>
                                                </>
                                              </div>
                                            </div>
                                          )
                                        );
                                      })
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              </Col>
                              <Col lg="6" md="6">
                                <div className="prev_right ">
                                  <h4 className="mb-3">Preview</h4>
                                  <div className="social_boxxx mt-0">
                                    <div className="social_prev_head d-flex align-items-center">
                                      <div className="social_img">
                                        <img src={DemoLogo} />
                                      </div>
                                      <div className="social_img_text">
                                        <h5>
                                          {orgName ||
                                            "HMC Sales and Services PVT. LTD."}
                                        </h5>
                                      </div>
                                    </div>
                                    {/* <p>
                                      {content}
                                      <a href="#">... See More</a>
                                    </p> */}
                                    <p dangerouslySetInnerHTML={{__html:ckEditorData}}></p>
                                    {showMore && (
                                      <p>More content goes here...</p>
                                    )}
                                    {!showMore && (
                                      <button onClick={handleSeeMore}>
                                        ... See More
                                      </button>
                                    )}

                                    <div className="socialll_images mt-5">
                                      <span>
                                        {selectedImage.map((item) => {
                                          return (
                                            !!item && (
                                              <img
                                                src={item}
                                                className="img-fluid"
                                              />
                                            )
                                          );
                                        })}
                                        {dragImg && (
                                          <img
                                            className="img-fluid"
                                            src={URL.createObjectURL(dragImg)}
                                          />
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Tab.Pane>
                          <Tab.Pane eventKey="tab5">
                            <Row>
                              <Col lg="6" md="6">
                                <div className="prev_left_text">
                                  {/* <p>{content} </p> */}
                                  <p dangerouslySetInnerHTML={{__html:ckEditorData}}></p>
                                  <div className="prev_art_images_boxx">
                                    {selectedImage || dragImg != "" ? (
                                      selectedImage.map((item) => {
                                        return (
                                          !!item && (
                                            <div className="prev_art_images">
                                              <div className="prev_art_image_inner mb-4">
                                                <>
                                                  <img
                                                    src={item}
                                                    className="img-fluid"
                                                  />
                                                  <div className="prev_cross">
                                                    {/* <img src={PrevCross} /> */}
                                                    <div
                                                      className="prev_cross_inner"
                                                      onClick={() =>
                                                        handleRemoveImage(item)
                                                      }
                                                    >
                                                      X
                                                    </div>
                                                  </div>
                                                </>
                                              </div>
                                            </div>
                                          )
                                        );
                                      })
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              </Col>
                              <Col lg="6" md="6">
                                <div className="prev_right ">
                                  <h4 className="mb-3">Preview</h4>
                                  <div className="social_boxxx mt-0">
                                    <div className="social_prev_head d-flex align-items-center">
                                      <div className="social_img">
                                        <img src={DemoLogo} />
                                      </div>
                                      <div className="social_img_text">
                                        <h5>
                                          {orgName ||
                                            "HMC Sales and Services PVT. LTD."}
                                        </h5>
                                      </div>
                                    </div>
                                    {/* <p>
                                      {content}
                                    </p> */}
                                    <p dangerouslySetInnerHTML={{__html:ckEditorData}}></p>
                                    {showMore && (
                                      <p>More content goes here...</p>
                                    )}
                                    {!showMore && (
                                      <button onClick={handleSeeMore}>
                                        ... See More
                                      </button>
                                    )}

                                    <div className="socialll_images mt-5">
                                      <span>
                                        {selectedImage.map((item) => {
                                          return (
                                            <img
                                              src={item}
                                              className="img-fluid"
                                            />
                                          );
                                        })}
                                        {dragImg && (
                                          <img
                                            className="img-fluid"
                                            src={URL.createObjectURL(dragImg)}
                                          />
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                      <div className="generate_buttons">
                        <Button
                          type="button"
                          className="generate_buttts"
                          onClick={() => handleGoBack(2)}
                        >
                          Back
                        </Button>
                        <Button
                          type="button"
                          className="generate_buttts ml-3"
                          onClick={() => setShowModal(true)}
                        >
                          Save as draft
                        </Button>
                        <Button
                          type="button"
                          className="save_buttts"
                          onClick={handleNextClick1}
                        >
                          Continue
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
                {showNextDiv1 && (
                  <div className="generate_article_parttts">
                    <div className="generate_article_top_sec">
                      <div className="generate_article_topper">
                        <div className="art_left active">
                          <div className="art_box bg-blue">1</div>
                          <p>Generate Article with Image Generation</p>
                        </div>
                        <div className="art_middle active"></div>
                        <div className="art_left active">
                          <div className="art_box bg-blue">2</div>
                          <p>Review Article & Images in Channels</p>
                        </div>
                        <div className="art_middle active"></div>
                        <div className="art_left active">
                          <div className="art_box">3</div>
                          <p>Social Media Connect</p>
                        </div>
                        <div className="art_middle"></div>
                        <div className="art_left inactive">
                          <div className="art_box">4</div>
                          <p>Final preview and Publish</p>
                        </div>
                      </div>
                    </div>

                    <div className="review_articles">
                      <div className="prf_textts">
                        <h4>Select your organization page :</h4><span>
                          
                        </span>
                      </div>
                      <div className="review_article_box mb-2">
                      
                      {connectedAcc && connectedAcc.length > 0 && (
                        connectedAcc.map((acc) =>
                        acc.accounts.map((account) => {
                          return (
                            <div className="org_innr_innrr">
                             {account !=="" && <div className="prf_textts">
                                
                                </div>}
                                <div className="org_innr_partts">
                                  <input
                                    className="styled-checkbox"
                                    id={account.id}
                                    type="checkbox"
                                    onChange={(e) =>
                                      handleAccChange(
                                        e,
                                        account.id,
                                        acc.account_type
                                      )
                                    }
                                  />
                                  <label for={account.id}>
                                    <img
                                      src={
                                        acc.account_type === "Facebook"
                                          ? FbLogo
                                          : acc.account_type === "LinkedIn"
                                          ? LinkedSocial
                                          : acc.account_type === "Twitter"
                                          ? TwitterSocial
                                          : acc.account_type === "Instagram"
                                          ? InsSocial
                                          : Wp
                                      }
                                    />
                                    <span>{account.name}</span>
                                  </label>
                                </div>
                              </div>
                            );
                          })
                        )
                      ) }
                      </div>


                      <div className="d-flex justify-content-end mb-4"><Button onClick={getAllSocialAccount}>Refresh</Button></div>
                      <div className="prf_parttts">
                          <div className="prf_textts">
                            <h4>Connect your social media account :</h4>
                          </div>                          
                        <div className="social_connect_inner_sec social__connect_inner_parttts">
                        
                          {/* <div className="social_inner_inner_sec">
                            <div className="social_white_sec">
                              <img src={FacebookSocial} width={35} />
                              <h4>Facebook</h4>
                              <span>Profile</span>
                              <button
                                type="button"
                                className="connect_button"
                                onClick={facebookAuth}
                              >
                                Connect
                              </button>
                            </div>
                          </div> */}
                          {/* <div className="social_inner_inner_sec">
                            <div className="social_white_sec">
                              <img src={InsSocial} width={35} />
                              <h4>Instagram</h4>
                              <span>Profile</span>
                              <button
                                type="button"
                                className="connect_button"
                                onClick={instagramAuth}
                              >
                                Connect
                              </button>
                            </div> 
                          </div> */}

                          <div className="social_inner_inner_sec">
                            <div className="social_white_sec">
                              <img src={TwitterSocial} width={35} />
                              <h4>Twitter</h4>
                              <span>Profile</span>
                              <button
                                type="button"
                                className="connect_button"
                                onClick={twitterAuth}
                              >
                                Connect
                              </button>
                            </div>
                          </div>
                          {/* <div className="social_inner_inner_sec">
                            <div className="social_white_sec">
                              <img src={LinkedSocial} width={35} />
                              <h4>Linkedin</h4>
                              <span>Profile</span>
                              <button
                                type="button"
                                className="connect_button"
                                onClick={linkedinAuth}
                              >
                                Connect
                              </button>
                            </div>
                          </div> */}

                          {/* <div className="social_inner_inner_sec">
                            <div className="social_white_sec">
                              <img src={Wp} width={35} />
                              <h4>Wordpress Blog</h4>
                              <span>Profile</span>
                              <button
                                type="button"
                                className="connect_button"
                                onClick={handleShowModal}
                              >
                                Connect
                              </button>
                              <Modal show={showWpModal} onHide={handleCloseModal} size="lg" centered>
                            <Modal.Header className='d-block'>
                              <Modal.Title className='text-center d-block'>Wordpress Login</Modal.Title>

                              <Button variant="secondary" className='wp_close_modal' onClick={handleCloseModal}>
                                X
                              </Button>
                            </Modal.Header>
                            <Modal.Body>
                              <div className='login_wp_partts'>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                  <Form.Label>Url</Form.Label>
                                  <Form.Control name="url" type="text" className='emailfield' placeholder="Enter url" onChange={handleWpChange} />
                                  <p style={{ color: "red" }}>{credsError.urlErr}</p>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                  <Form.Label>App Id</Form.Label>
                                  <Form.Control name="app_id" type="text" className='emailfield' placeholder="Enter app id" onChange={handleWpChange} />
                                  <p style={{ color: "red" }}>{credsError.appIdErr}</p>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                  <Form.Label>Password</Form.Label>
                                  <Form.Control name="app_password" type="password" className='emailfield' placeholder="Enter password" onChange={handleWpChange} />
                                  <p style={{ color: "red" }}>{credsError.appPassErr}</p>
                                </Form.Group>
                                <Button variant="primary" type="submit" className='wp_submit' onClick={wordpressAuth}>
                                  Submit
                                </Button>
                              </div>


                            </Modal.Body>
                            {/* <Modal.Footer>
                                                              
                            </Modal.Footer> */}
                          {/* </Modal>
                            </div>
                          </div> */}
                        </div>
                        </div>
                      

                      <div className="generate_buttons">
                        <Button
                          type="button"
                          className="generate_buttts"
                          onClick={() => handleGoBack(3)}
                        >
                          Back
                        </Button>

                        <Button
                          type="button"
                          className="save_buttts"
                          onClick={handleNextClick2}
                        >
                          Continue
                        </Button>
                      </div>
                    </div>
                  </div>
                )}

                {showNextDiv2 && (
                  <div className="generate_article_parttts">
                    <div className="generate_article_top_sec">
                      <div className="generate_article_topper">
                        <div className="art_left active">
                          <div className="art_box bg-blue">1</div>
                          <p>Generate Article with Image Generation</p>
                        </div>
                        <div className="art_middle active"></div>
                        <div className="art_left active">
                          <div className="art_box bg-blue">2</div>
                          <p>Review Article & Images in Channels</p>
                        </div>
                        <div className="art_middle active"></div>
                        <div className="art_left active">
                          <div className="art_box bg-blue">3</div>
                          <p>Social Media Connect</p>
                        </div>
                        <div className="art_middle active"></div>
                        <div className="art_left active">
                          <div className="art_box">4</div>
                          <p>Final preview and Publish</p>
                        </div>
                      </div>
                    </div>

                    <div className="review_articles">
                      <div className="final_heading">
                        <h4>Final Preview</h4>
                      </div>

                      <div className="final_prev_inner">
                        <div className="final_prev_inner_inner">
                          {selectedAcc && selectedAcc.length > 0 ? (
                            selectedAcc.map((acc) => {
                              const selectedAccount =
                                socialAccountData[acc.account_type];

                              return selectedAccount ? (
                                <div className="final_prev_inner_box">
                                  <div className="inner_box_parttts">
                                    <div className="social_heading d-flex align-items-center">
                                      <img
                                        src={selectedAccount.icon}
                                        alt={selectedAccount.name}
                                      />
                                      <span className="ml-3">
                                        {selectedAccount.name}
                                      </span>
                                    </div>
                                    <div className="social_boxxx">
                                      {selectedAccount.additionalData && (
                                        <>
                                          <span>
                                            {selectedImage.map((item) => {
                                              return (
                                                <img
                                                  src={item}
                                                  className="img-fluid"
                                                />
                                              );
                                            })}
                                            {/* {dragImg && <img className="img-fluid" src={URL.createObjectURL(dragImg)} />} */}
                                          </span>
                                          <h4 className="my-4">
                                            {
                                              selectedAccount.additionalData
                                                .title
                                            }
                                          </h4>
                                          <div className="d-flex mt-2 align-items-center wp_icon">
                                            <img
                                              src={Calendar11}
                                              alt="Calendar"
                                            />
                                            <span className="ml-2 date_span">
                                              {
                                                selectedAccount.additionalData
                                                  .date
                                              }
                                            </span>
                                          </div>
                                          <div className="d-flex mt-2 align-items-center wp_icon">
                                            <img src={Book22} alt="Book" />
                                            <span className="ml-2 date_span">
                                              {
                                                selectedAccount.additionalData
                                                  .category
                                              }
                                            </span>
                                          </div>
                                          {/* <p>{content}</p> */}
                                          <p dangerouslySetInnerHTML={{__html:ckEditorData}}></p>
                                          {showMore && (
                                            <p>More content goes here...</p>
                                          )}
                                          {!showMore && (
                                            <button onClick={handleSeeMore}>
                                              ... See More
                                            </button>
                                          )}
                                        </>
                                      )}
                                      {!selectedAccount.additionalData && (
                                        <>
                                          {/* <img src={SocialTop} className="img-fluid top__imgg" /> */}
                                          <div className="social_prev_head d-flex align-items-center">
                                            <div className="social_img">
                                              <img src={DemoLogo} />
                                            </div>
                                            <div className="social_img_text">
                                              <h5>
                                                {orgName ||
                                                  "HMC Sales and Services PVT. LTD."}
                                              </h5>
                                            </div>
                                          </div>
                                          <p>
                                            {acc.account_type === "Twitter"
                                              ? twitterContent
                                              : <p dangerouslySetInnerHTML={{__html:ckEditorData}}></p>}
                                          </p>
                                          {showMore && (
                                            <p>More content goes here...</p>
                                          )}
                                          {!showMore && (
                                            <button onClick={handleSeeMore}>
                                              ... See More
                                            </button>
                                          )}
                                          <div className="socialll_images mt-5">
                                            <span>
                                              {selectedImage.map((item) => {
                                                return (
                                                  <img
                                                    src={item}
                                                    className="img-fluid"
                                                  />
                                                );
                                              })}
                                              {/* {dragImg && <img className="img-fluid" src={URL.createObjectURL(dragImg)} />} */}
                                            </span>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ) : null;
                            })
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>

                      <div className="generate_buttons">
                        <Button
                          type="button"
                          className="generate_buttts"
                          onClick={() => handleGoBack(4)}
                        >
                          Back
                        </Button>
                        <Button
                          type="button"
                          className="generate_buttts ml-3"
                          onClick={handleButtonClick1}
                        >
                          {params.postId ? "Reschedule" : "Schedule"}
                        </Button>
                        <Button
                          type="button"
                          className="save_buttts"
                          onClick={handlePostPublish}
                        >
                          Publish
                        </Button>
                      </div>
                      {showDiv1 && (
                        <div className="schedule_date_time">
                          <h3>Scheduled Date and Time</h3>
                          <div className="schedulle_inner">
                            <div className="schedule_date">
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label>Scheduled Date</Form.Label>
                                {/* <DatePicker
                                  selected={startDate}
                                  onChange={handleDateChange}
                                /> */}

                                <Form.Control
                                  value={startDate}
                                  type="date"
                                  onChange={(e) => setStartDate(e.target.value)}
                                />
                                <div>
                                  {dateError && (
                                    <p style={{ color: "red" }}>{dateError}</p>
                                  )}
                                </div>
                              </Form.Group>
                            </div>
                            <div className="schedule_time">
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlTextarea1"
                              >
                                <Form.Label>Scheduled Time</Form.Label>
                                {/* <TimePicker
                                  onChange={(e) => setTime(e.target.value)}
                                  value={time}
                                /> */}
                                {/* <Form.Control
                                  value={time}
                                  type="time"
                                  onChange={(e) => setTime(e.target.value)}
                                />
                                <div>
                                  {timeError && (
                                    <p style={{ color: "red" }}>{timeError}</p>
                                  )}
                                </div> */}
                                <select
                                  value={time}
                                  onChange={handleTimeChange}
                                >
                                  <option value="">Select a round time</option>
                                  {roundTimeOptions.map((timeOption, index) => (
                                    <option key={index} value={timeOption}>
                                      {timeOption}
                                    </option>
                                  ))}
                                </select>
                                <div>
                                  {timeError && (
                                    <p style={{ color: "red" }}>{timeError}</p>
                                  )}
                                </div>
                              </Form.Group>
                            </div>
                          </div>
                          <Button
                            type="button"
                            className="d-block w-100"
                            onClick={handleSchedule}
                          >
                            Scheduled
                          </Button>
                        </div>
                      )}
                    </div>
                    <div>
                      <div className="dash_right_inner_panel">
                        <Row>
                          <Col lg="12" md="12" sm="12">
                            <div className="archive_article_boxx mt-4">
                              <div className="archive_heading">
                                <h3 ref={scrollTargetRef}>Published Post</h3>
                                <div className="search_boxxx">
                                  <Form.Control
                                    type="text"
                                    placeholder="Search"
                                    className="search__archive"
                                  ></Form.Control>
                                  <img src={Search1} className="search_img" />
                                </div>
                              </div>
                              <div className="archive_data_table">
                                <DataTable
                                  columns={columns}
                                  data={archivePost()}
                                  pagination
                                />
                              </div>
                            </div>
                            <div className="archive_article_boxx mt-4">
                              <div className="archive_heading">
                                <h3 ref={scrollTargetRefSchedule}>
                                  Scheduled Post
                                </h3>
                                <div className="search_boxxx">
                                  <Form.Control
                                    type="text"
                                    placeholder="Search"
                                    className="search__archive"
                                  ></Form.Control>
                                  <img src={Search1} className="search_img" />
                                </div>
                              </div>
                              <div className="archive_data_table">
                                <DataTable
                                  columns={columns}
                                  data={archiveSchedule()}
                                  pagination
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                )}
              </Col>
              <Col lg="3" md="12" sm="12" className="pr-0">
                <div className="dash_right_paertts">
                  <DashRightPanel />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Modal show={showModal} centered size="lg">
        <Modal.Header className="d-block">
          <Modal.Title className="text-center">Select Folder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="article_group generate-article">
            {folders &&
              folders.length > 0 &&
              folders.map((folder) => {
                return (
                  <div
                    className="article_inner"
                    key={folder._id}
                    onClick={() => selectFolder(folder)}
                  >
                    <div
                      className="art_inner_inner"
                      style={{
                        backgroundColor:
                          selectedFolder === folder._id && "#050561",
                      }}
                    >
                      <img src={Folder} height={40} />
                      <div className="article_text">
                        <h4>{folder?.name}</h4>
                        <p>
                          {folder?.posts?.length}{" "}
                          {`post${folder.posts.length > 1 ? "s" : ""}`}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className='search_button' onClick={() => { setShowCreateModal(true) }}>
            <Button type='button' className='search_button_partts d-block w-100'>Add Folder <span>+</span></Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={savePost}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={genCreditModal} centered size="lg">
      <Modal.Header className="d-block">
          <Modal.Title className="text-center">Generate Article Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {resError}
        </Modal.Body>
      <Modal.Footer>
      <Button variant="secondary" onClick={() => {}}>
            <Link to="/plan">Upgrade Plan</Link>
          </Button>
        </Modal.Footer>
      </Modal>

      <CreateFolderModal handleCreate={handleCreate} showModal={showCreateModal} folderName={folderName} handleChange={(e) => { setFolderName(e.target.value) }} handleClose={handleClose} folderNameError={errorMsg} setFolderName={setFolderName} />
    </section>
  );
};

export default TwitterGenerate;
