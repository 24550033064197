import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Modal, Button, Form, Dropdown, Table, Accordion, Card, Tab, Nav } from 'react-bootstrap';
import DashboardLeftPanel from "../../../components/DashboardLeftPanel/dashboardLeftPanel";
import DashRightPanel from "../../../components/DashboardRightPanel/dashbaordrightpanel";
import {
	Toggle,
} from "../../../assets/images";

function ChatPage() {
	const [collapsed, setCollapsed] = useState(false);
	const [expanded, setExpanded] = useState(false);


	// Effect to load the state from localStorage on initial render
	useEffect(() => {
		const storedCollapsed = localStorage.getItem('collapsed');
		if (storedCollapsed) {
			setCollapsed(JSON.parse(storedCollapsed));
		}
	}, []);

	// Effect to save the state to localStorage whenever it changes
	useEffect(() => {
		localStorage.setItem('collapsed', JSON.stringify(collapsed));
	}, [collapsed]);



	// Effect to load the state from localStorage on initial render
	useEffect(() => {
		const storedExpanded = localStorage.getItem('expanded');
		if (storedExpanded) {
			setExpanded(JSON.parse(storedExpanded));
		}
	}, []);

	// Effect to save the state to localStorage whenever it changes
	useEffect(() => {
		localStorage.setItem('expanded', JSON.stringify(collapsed));
	}, [collapsed]);
	const steps = [
		{
			id: 'intro',
			message: 'Hello, how can I assist you today?',
			trigger: 'options',
		},
		{
			id: 'options',
			options: [
				{
					value: '1',
					label: 'Option 1',
					trigger: 'response',
				},
				{
					value: '2',
					label: 'Option 2',
					trigger: 'response',
				},
				{
					value: '3',
					label: 'Option 3',
					trigger: 'response',
				},
			],
		},
		{
			id: 'response',
			message: 'You selected option.',
			end: true,
		},
	];


	const theme = {
		background: '#fff',
		headerBgColor: 'rgba(63, 62, 237, 1)',
		headerFontSize: '20px',
		botBubbleColor: 'blue',
		headerFontColor: '#ffffff',
		botFontColor: 'white',
		userBubbleColor: '#FF5733',
		userFontColor: 'white',
	};

	// Set some properties of the bot
	const config = {
		botAvatar: "img.png",
		floating: true,
	};
	const toggleSidebar = () => {
		setCollapsed(!collapsed);
		setExpanded(!expanded);
	};
	return (
		<section className="dash_partts">
			<div className='dash_inner_partts'>
				<div className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
					<DashboardLeftPanel />
				</div>

				<div className={`dash_right_panel ${expanded ? 'expanded' : ''}`}>
					<button onClick={toggleSidebar} className='toggle__button'><img src={Toggle} /></button>
					<div className='dash_right_inner_panel'>
						<Row>
							<Col lg="9" md="12" sm="12">
								<div className='dash_middle_panel'>
									{/* <div className='support_page'> */}
										<iframe src="https://tawk.to/chat/64d369b994cf5d49dc6959df/1h7ctt3pl" height={750} width={"100%"} />
									{/* </div> */}
								</div>
							</Col>
							<Col lg="3" md="12" sm="12" className='pr-0'>
								<div className='dash_right_paertts'>
									<DashRightPanel />
								</div>
							</Col>
						</Row>
					</div>

				</div>
			</div>


		</section>
	)
}

export default ChatPage