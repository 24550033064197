import React, { useState, useContext, useEffect } from 'react';
import { Container, Row, Col, Modal, Button, Form, Dropdown, Table, Accordion, Card } from 'react-bootstrap';
import { Link, useHistory } from "react-router-dom";
import './successfull.css';
import { PaymentSuccess, } from "../../../assets/images";
import axios from '../../../axios/axios';

const PAymentSuccessfull = () => {
    const history = useHistory()
    const [payment, setPayment] = useState({})
    const getPaymentConfirmation = async () => {
        const paymentConfirm = await axios.get("/payment-confirmation").catch(err => console.error(err, "<<-- Error in get payment confirmation"))

        if (paymentConfirm?.data?.status) {
            setPayment(paymentConfirm.data.data)
        } else {
            history.replace("/dashboard")
        }
    }

    useEffect(() => {
        getPaymentConfirmation()
    }, [])

    return (
        <section className='success_payment'>
            <div className='inner_success'>
                <div className='success_heading'>
                    <h2>Payment Successfull!</h2>
                    <div className='paymnt_success text-center'>
                        <img src={PaymentSuccess} width={100} />
                    </div>
                </div>

                <div className='paymnt_partts_bottom'>
                    <div className='paymnt_inner_partts_bottom'>
                        <label>Plan name</label>
                        <span>{payment?.plan_name}</span>
                    </div>

                    <div className='paymnt_inner_partts_bottom'>
                        <label>Payment Type</label>
                        <span>{payment?.payment_method?.length > 0 && payment?.payment_method[0]}</span>
                    </div>

                    <div className='paymnt_inner_partts_bottom'>
                        <label>Mobile</label>
                        <span>{payment?.phone}</span>
                    </div>

                    <div className='paymnt_inner_partts_bottom'>
                        <label>Email</label>
                        <span>{payment?.email}</span>
                    </div>

                    <div className='paymnt_inner_partts_bottom amnt my-4'>
                        <label>Amount Paid</label>
                        <span>${payment?.amount?.toFixed(2)}</span>
                    </div>

                    <div className='paymnt_inner_partts_bottom'>
                        <label>Transaction ID</label>
                        <span>{payment?.txn_id}</span>
                    </div>

                    <div className='paymnt_inner_partts_bottom'>
                        <label>Instead of expires:</label>
                        <span>{payment?.expire_on}</span>
                    </div>
                </div>
                <div className='paymnt_button d-flex justify-content-center align-items-center'>
                    <button type='button' className='print_but' onClick={() => {
                        window.print()
                    }}>Print</button>
                    <button type='button' className='close_but' onClick={() => {
                        history.push("/dashboard")
                    }}>Close</button>
                </div>
            </div>
        </section>
    );
};

export default PAymentSuccessfull;