import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Modal, Button, Form, Dropdown, Table, Accordion, Card, Tab, Nav } from 'react-bootstrap';
import DashboardLeftPanel from "../../../components/DashboardLeftPanel/dashboardLeftPanel";
import DashRightPanel from "../../../components/DashboardRightPanel/dashbaordrightpanel";
import { Link } from "react-router-dom";
import './content-calendar.css';
// import { Calendar, momentLocalizer } from 'react-event-calendar';
import {
    Toggle,
    FacebookSocial,
    InsSocial,
    Wp,
    LinkedSocial,
    TwitterSocial,
} from "../../../assets/images";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import message from "../../../messages";
import axios from '../../../axios/axios';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const localizer = momentLocalizer(moment);

const ContentCalendarPage = (props) => {
    const history = useHistory()
    const [collapsed, setCollapsed] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [calData, setCalData] = useState({})

    const toggleSidebar = () => {
        setCollapsed(!collapsed);
        setExpanded(!expanded);
    };

// Effect to load the state from localStorage on initial render
useEffect(() => {
    const storedCollapsed = localStorage.getItem('collapsed');
    if (storedCollapsed) {
        setCollapsed(JSON.parse(storedCollapsed));
    }
  }, []);

  // Effect to save the state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('collapsed', JSON.stringify(collapsed));
  }, [collapsed]);



// Effect to load the state from localStorage on initial render
useEffect(() => {
    const storedExpanded = localStorage.getItem('expanded');
    if (storedExpanded) {
        setExpanded(JSON.parse(storedExpanded));
    }
  }, []);

  // Effect to save the state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('expanded', JSON.stringify(collapsed));
  }, [collapsed]);

    const [events, setEvents] = useState([
        {
            _id: "",
            start: new Date('2023-07-06 09:00:00'),
            end: new Date('2023-07-06 10:30:00'),
            title: 'Meeting',
            description: 'Project meeting with the team',
            images: []
        },
        // Add more events as needed
    ]);
    const handleAddEvent = (event) => {
        setEvents((prevEvents) => [...prevEvents, event]);
    };

    const scheduledPost = async () => {
        try {
            const posts = await axios.get("/scheduled-post")
            const { data } = posts.data
            await Promise.all(data.map(post => {
                setEvents(prevState => [...prevState, { _id: post._id, start: new Date(post.schedule_at), end: new Date(post.schedule_at), title: post.title, description: post.content, images: post.images, ...post }])
            }))
        } catch (error) {
            // console.error(error, "<<-- Error in getting scheduled post")
            message.error("Error occured")
        }
    }

    const handleCloseModal = () => {
        setShowModal(false);
    };

    useEffect(() => {
        scheduledPost()
    }, [])

    const handleEditPost = () => {
        console.log(calData, "<<-- caldataId")
        history.push(`/generate-article/${calData._id}`)
    }

    return (
        <section className="dash_partts">
            <div className='dash_inner_partts'>
                <div className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
                    <DashboardLeftPanel />
                </div>

                <div className={`dash_right_panel ${expanded ? 'expanded' : ''}`}>
                    <button onClick={toggleSidebar} className='toggle__button'><img src={Toggle} /></button>
                    <div className='dash_right_inner_panel'>
                        <Row>
                            <Col lg="9" md="12" sm="12">
                                <div className='event_calendar'>
                                    <Calendar
                                        localizer={localizer}
                                        events={events}
                                        // startAccessor="start"
                                        // endAccessor="end"
                                        onSelectEvent={(event) => {
                                            console.log(event, "<<-- event")
                                            setShowModal(true)
                                            setCalData(event)
                                        }}
                                        // selectable
                                        //onSelectSlot={(slotInfo) => console.log(slotInfo)}
                                        //defaultView="month"
                                        startAccessor="start"
                                        endAccessor="end"
                                        style={{ height: 500 }}
                                    />
                                </div>
                            </Col>
                            <Col lg="3" md="12" sm="12" className='pr-0'>
                                <div className='dash_right_paertts'>
                                    <DashRightPanel />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
                <Modal.Header className='d-block'>
                    <Modal.Title className='text-center d-block'>{calData.title}</Modal.Title>

                    <Button variant="secondary" className='wp_close_modal' onClick={handleCloseModal}>
                        X
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <div className='modal_body'>
                        <div className="modal-top">
                            <div>
                                <h5>Scheduled Date</h5>
                                <p>{moment(calData.start).format("MM/DD/YYYY")}</p>
                            </div>
                            <div>
                                <h5>Scheduled Time</h5>
                                <p>{moment(calData.end).format("LTS")}</p>
                            </div>
                        </div>
                        <div>
                            <h5>Description</h5>
                            <p className='content-description' style={{ textAlign: "justify" }}>{calData.description}</p>
                        </div>
                        <div>
                            <h5>Scheduled platform</h5>
                            <div className="man_username">
                                <span>
                                    {calData.wordpress?.length <= 0 ? <></> : <img style={{ width: "30px", height: "30px" }} src={Wp} />}
                                </span>
                                <span>
                                    {calData.facebook?.length <= 0 ? <></> : <img style={{ width: "30px", height: "30px" }} src={FacebookSocial} />}
                                </span>
                                <span>
                                    {calData.twitter?.length <= 0 ? <></> : <img style={{ width: "30px", height: "30px" }} src={TwitterSocial} />}
                                </span>
                                <span>
                                    {calData.instagram?.length <= 0 ? <></> : <img style={{ width: "30px", height: "30px" }} src={InsSocial} />}
                                </span>
                                <span>
                                    {calData.linkedin?.length <= 0 ? <></> : <img style={{ width: "30px", height: "30px" }} src={LinkedSocial} />}
                                </span>
                            </div>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            <h5>Post images</h5>
                            <div className="socialll_images mt-5">
                                <span>
                                    {calData.images?.map((item) => {
                                        return (
                                            <img src={item} className="img-fluid" />
                                        )
                                    })}
                                    {/* {dragImg && <img className="img-fluid" src={URL.createObjectURL(dragImg)} />} */}
                                </span>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
                    <Button variant="primary" onClick={handleEditPost}>Edit</Button>
                </Modal.Footer>
            </Modal>
        </section>
    );
};

export default ContentCalendarPage;
