// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.success_payment{
    max-width: 600px;
    width: 100%;
    padding-top: 140px;
    margin: auto;
}


.inner_success{
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0,0,0,0.5);
    padding: 20px;
}
.success_heading{
    margin-bottom: 25px;
}
.success_heading h2{
    text-align: center;
    margin: 20px 0 40px;
    font-size: 22px;
    color: rgb(18, 183, 6);
}


.paymnt_inner_partts_bottom{
    display: flex;
    justify-content: space-between;
    max-width: 450px;
    width: 100%;
    margin: 12px auto;
}

.paymnt_inner_partts_bottom label, .paymnt_inner_partts_bottom span{
    color: #1c1c1c;
    font-size: 15px;
    margin-bottom: 0px;
}

.paymnt_inner_partts_bottom span{
    font-weight: 600;
}


.paymnt_inner_partts_bottom.amnt label, .paymnt_inner_partts_bottom.amnt span{
    font-weight: 700;
}
.paymnt_button{
    margin-top: 30px;
}
.paymnt_button .close_but{
    padding: 5px 30px;
    background: #f00;
    color: #fff;
    border-radius: 6px;
    margin: 0 5px;
    border: 0px;
}
.paymnt_button .print_but{
    padding: 5px 30px;
    background: #0514de;
    color: #fff;
    border-radius: 6px;
    margin: 0 5px;
    border: 0px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/PaymentSuccessfull/successfull.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,WAAW;IACX,kBAAkB;IAClB,YAAY;AAChB;;;AAGA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,oCAAoC;IACpC,aAAa;AACjB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,sBAAsB;AAC1B;;;AAGA;IACI,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;IAChB,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB;;;AAGA;IACI,gBAAgB;AACpB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,WAAW;IACX,kBAAkB;IAClB,aAAa;IACb,WAAW;AACf;AACA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,WAAW;IACX,kBAAkB;IAClB,aAAa;IACb,WAAW;AACf","sourcesContent":[".success_payment{\n    max-width: 600px;\n    width: 100%;\n    padding-top: 140px;\n    margin: auto;\n}\n\n\n.inner_success{\n    background: #fff;\n    border-radius: 10px;\n    box-shadow: 0 0 20px rgba(0,0,0,0.5);\n    padding: 20px;\n}\n.success_heading{\n    margin-bottom: 25px;\n}\n.success_heading h2{\n    text-align: center;\n    margin: 20px 0 40px;\n    font-size: 22px;\n    color: rgb(18, 183, 6);\n}\n\n\n.paymnt_inner_partts_bottom{\n    display: flex;\n    justify-content: space-between;\n    max-width: 450px;\n    width: 100%;\n    margin: 12px auto;\n}\n\n.paymnt_inner_partts_bottom label, .paymnt_inner_partts_bottom span{\n    color: #1c1c1c;\n    font-size: 15px;\n    margin-bottom: 0px;\n}\n\n.paymnt_inner_partts_bottom span{\n    font-weight: 600;\n}\n\n\n.paymnt_inner_partts_bottom.amnt label, .paymnt_inner_partts_bottom.amnt span{\n    font-weight: 700;\n}\n.paymnt_button{\n    margin-top: 30px;\n}\n.paymnt_button .close_but{\n    padding: 5px 30px;\n    background: #f00;\n    color: #fff;\n    border-radius: 6px;\n    margin: 0 5px;\n    border: 0px;\n}\n.paymnt_button .print_but{\n    padding: 5px 30px;\n    background: #0514de;\n    color: #fff;\n    border-radius: 6px;\n    margin: 0 5px;\n    border: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
