import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import message from "../../../messages";
import Select from "react-select";
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Form,
  Dropdown,
  Table,
  Accordion,
  Card,
  Tabs,
  Tab,
  Image,
  Nav,
} from "react-bootstrap";
import DashboardLeftPanel from "../../../components/DashboardLeftPanel/dashboardLeftPanel";
import DashRightPanel from "../../../components/DashboardRightPanel/dashbaordrightpanel";
import { Link, useHistory, useParams } from "react-router-dom";
import { Audio } from "react-loader-spinner";
import "./generate-article.css";
import {
  EditBut,
  Toggle,
  Create1,
  Create2,
  Create3,
  Create4,
  Create5,
  Create6,
  Like,
  DisLike,
  // TextIcon,
  Creatives,
  Captions,
  Hashtag,
  Suggetions,
  CreativeImg,
  HeartRate,
  RoundedImg,
  TextIcon,
  SingleImage,
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
  // Create1,
  FbLogo,
  Assets,
  AiIcon,
  SecretRightImg,
  TopperImg,
  FacebookSocial,
  InsSocial,
  TwitterSocial,
  LinkedSocial,
  PrevImg,
  Wp,
  Frame_1,
} from "../../../assets/images";
import axios from "../../../axios/axios";
import Moment from "moment";

const roundTimeOptions = [
  "12:00 AM",
  "12:30 AM",
  "1:00 AM",
  "1:30 AM",
  "2:00 AM",
  "2:30 AM",
  "3:00 AM",
  "3:30 AM",
  "4:00 AM",
  "4:30 AM",
  "5:00 AM",
  "5:30 AM",
  "6:00 AM",
  "6:30 AM",
  "7:00 AM",
  "7:30 AM",
  "8:00 AM",
  "8:30 AM",
  "9:00 AM",
  "9:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",

  "12:00 PM",
  "12:30 PM",
  "1:00 PM",
  "1:30 PM",
  "2:00 PM",
  "2:30 PM",
  "3:00 PM",
  "3:30 PM",
  "4:00 PM",
  "4:30 PM",
  "5:00 PM",
  "5:30 PM",
  "6:00 PM",
  "6:30 PM",
  "7:00 PM",
  "7:30 PM",
  "8:00 PM",
  "8:30 PM",
  "9:00 PM",
  "9:30 PM",
  "10:00 PM",
  "10:30 PM",
  "11:00 PM",
  "11:30 PM",
];

const TabTitle = ({ label, imageSrc }) => (
  <div className="dropdown_menu_partts">
    <Image src={imageSrc} alt={`Image for ${label}`} className="mr-2" />
    <span>{label}</span>
  </div>
);

const TabTitleOne = ({ label, imageSrc }) => (
  <div className="dropdown_menu_partts1">
    <Image src={imageSrc} alt={`Image for ${label}`} className="mr-2" />
    <span>{label}</span>
  </div>
);
const GenerateArticlesNew = (props) => {
  const params = useParams();
  const [collapsed, setCollapsed] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [alert, setAlert] = useState("");
  const [displayModal, setDisplayModal] = useState(false);
  const [displayModal1, setDisplayModal1] = useState(false);
  const [displayModal2, setDisplayModal2] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 3;
  let today = Moment(new Date()).format("YYYY-MM-DD");
  const [template, setTemplate] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionError, setSelectedOptionError] = useState("");
  const [topic, setTopic] = useState("");
  const [topicError, setTopicError] = useState("");
  const [options, setOptions] = useState([]);
  const [loader, setLoader] = useState(false);
  const [getArticles, setGetArticles] = useState([]);
  const [selectEdit, setSelectEdit] = useState([]);
  const [connectedAcc, setConnectedAcc] = useState([]);
  const [selectedAcc, setSelectedAcc] = useState([]);
  const [showText, setShowText] = useState(null);
  const [showWpModal, setShowWpModal] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [dateError, setDateError] = useState("");
  const [time, setTime] = useState("");
  const [timeError, setTimeError] = useState("");
  const [wpData, setWpData] = useState({
    url: "",
    app_id: "",
    app_password: "",
  });
  const [credsError, setCredsError] = useState({
    urlErr: "",
    appIdErr: "",
    appPassErr: "",
  });
  const [selectedCatValue, setSelectedCatValue] = useState("");
  const [mergedCanvas, setMergedCanvas] = React.useState(null);

  const handleButtonClick = (buttonId) => {
    setShowText(buttonId === showText ? null : buttonId);
  };

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
    setExpanded(!expanded);
  };
  useEffect(() => {
    const clearMessage = setTimeout(() => {
      setAlert("");
    }, 5000);
    return () => clearTimeout(clearMessage);
  }, [alert]);

  useEffect(() => {
    const clearMessage = setTimeout(() => {
      setAlert("");
    }, 5000);
    return () => clearTimeout(clearMessage);
  }, [alert]);

  const changeHandler = (e) => {
    setTopic(e.target.value);
    setTopicError("");
  };

  const handleNext = (e) => {
    e.preventDefault();

    if (selectedOption == null) {
      setSelectedOptionError("Please select a option");
      return;
    }
    if (!topic.trim()) {
      setTopicError("Topic is required");
      return;
    }

    // Check if there are more steps to go to
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleImageChange = (event) => {
    const selectedValue = event.target.value;
    console.log("selectedValue", event.target.value);
    const selectedItem = template.find(
      (item) => item.background_img === selectedValue
    );
    setSelectedImage(selectedItem);
  };
  console.log("selectedImage", selectedImage);
  const getTemplate = async () => {
    await axios
      .get("/templates")
      .then((res) => {
        setTemplate(res.data.data);
      })
      .catch((err) => {
        console.error("err", err);
      });
  };

  const getPrompts = async () => {
    try {
      const res = await axios.get("/prompts");
      setOptions(
        res.data.data.map((prompt) => ({
          value: prompt._id,
          label: prompt.type,
        }))
      );
    } catch (err) {
      console.error("err", err);
    }
  };

  const getGenerateArticles = async () => {
    await axios
      .get("/saved-post")
      .then((res) => {
        setGetArticles(res.data.data);
      })
      .catch((err) => {
        console.error("err", err);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisplayModal(false);
    let paramsData = {
      topic: topic,
      prompt_id: selectedOption?.value,
      template_id: selectedImage?._id,
    };

    // Submit the form or perform other actions
    setLoader(true);
    await axios
      .post("/content-generate", paramsData)
      .then((res) => {
        setLoader(false);
        getGenerateArticles();
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
      });
  };

  const handleImageClick = (editData) => {
    setSelectEdit(editData);
    setDisplayModal1(!displayModal1);
  };

  useEffect(() => {
    getTemplate();
    getPrompts();
    getGenerateArticles();
    getAllSocialAccount();
  }, []);

  const handleShere = () => {
    setDisplayModal1(false);
    setDisplayModal2(true);
  };

  const handlePostPublish = async () => {
    let paramsData = {
      title: selectEdit.title,
      content: selectEdit.content,
      images: selectEdit.images,
      facebook_ids: selectedAcc
        .filter((account) => account.account_type === "Facebook")
        .map((account) => account.id),
      twitter_ids: selectedAcc
        .filter((account) => account.account_type === "Twitter")
        .map((account) => account.id),
      instagram_ids: selectedAcc
        .filter((account) => account.account_type === "Instagram")
        .map((account) => account.id),
      linkedin_ids: selectedAcc
        .filter((account) => account.account_type === "LinkedIn")
        .map((account) => account.id),
      wordpress_ids: selectedAcc
        .filter((account) => account.account_type === "WordPress")
        .map((account) => account.id),
      wp_category: selectedCatValue,
    };
    if (params.postId) {
      paramsData.postId = params.postId;
    }
    setLoader(true);
    await axios
      .post("/post-publish", paramsData)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          message.success("Post published successfully");
        }
      })
      .catch((err) => {
        setLoader(false);
        message.error("Internal server error");
      });
  };

  const handleWpChange = (event) => {
    const { name, value } = event.target;
    setWpData({ ...wpData, [name]: value });
  };

  const handleTimeChange = (event) => {
    const newTime = event.target.value;
    setTime(newTime);
  };

  const DateAndTimeValidate = () => {
    setDateError("");
    setTimeError("");

    if (!startDate) {
      setDateError("Date field is required");
      return false;
    } else if (startDate < today) {
      setDateError("Please select a date that is today or in the future");
      return false;
    }
    if (!time) {
      setTimeError("Time field is required");
      return false;
    }

    return true;
  };

  const handleSchedule = async (e) => {
    e.preventDefault();
    if (DateAndTimeValidate()) {
      let paramsData = {
        title: selectEdit.title,
        content: selectEdit.content,
        images: selectEdit.images,
        schedule_at: `${startDate} ${time}`,
        facebook_ids: selectedAcc
          .filter((account) => account.account_type === "Facebook")
          .map((account) => account.id),
        twitter_ids: selectedAcc
          .filter((account) => account.account_type === "Twitter")
          .map((account) => account.id),
        instagram_ids: selectedAcc
          .filter((account) => account.account_type === "Instagram")
          .map((account) => account.id),
        linkedin_ids: selectedAcc
          .filter((account) => account.account_type === "LinkedIn")
          .map((account) => account.id),
        wordpress_ids: selectedAcc
          .filter((account) => account.account_type === "WordPress")
          .map((account) => account.id),
        wp_category: selectedCatValue,
      };

      if (params.postId) {
        paramsData.postId = params.postId;
      }
      // Submit the form or perform other actions
      setLoader(true);
      await axios
        .post("/post-schedule", paramsData)
        .then((res) => {
          setLoader(false);
          message.success(res.data.message);
        })
        .catch((err) => {
          setLoader(false);
          console.log("err", err);
          message.error(err.response.data.message);
        });
    }
  };

  const getAllSocialAccount = async () => {
    await axios
      .get("/social/accounts")
      .then((res) => {
        const instaAcc = res.data.data;
        const filterAc = instaAcc.filter(
          (iAc) =>
            iAc.account_type === "LinkedIn" ||
            iAc.account_type === "Facebook" ||
            iAc.account_type === "WordPress" ||
            iAc.account_type === "Instagram" ||
            iAc.account_type === "Twitter"
        );
        // console.log("filterAc", filterAc)
        setConnectedAcc(filterAc);
      })
      .catch((err) => {
        // console.error(err, "<<-- Error in get all social accounts")
      });
  };

  const handleAccChange = async (e, accountId, account_type) => {
    const isChecked = e.target.checked;
    // Check if the account with the provided id exists in the selectedAcc array
    const existingAccountIndex = selectedAcc.findIndex(
      (account) => account.id === accountId
    );

    if (existingAccountIndex !== -1) {
      // Account exists, so update its selected property
      setSelectedAcc(selectedAcc.filter((acc) => acc.id !== accountId));
    } else {
      // Account does not exist, so add the new account data
      setSelectedAcc((prevSelectedAcc) => [
        ...prevSelectedAcc,
        { id: accountId, account_type: account_type, selected: isChecked },
      ]);
    }
  };

  const facebookAuth = async () => {
    setLoader(true);
    await axios
      .get("/facebook/auth")
      .then((res) => {
        setLoader(false);
        // console.log("data", res);
        window.open(res.data.data.url, "_blank");
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };

  const instagramAuth = async () => {
    setLoader(true);
    await axios
      .get("/instagram/auth")
      .then((res) => {
        setLoader(false);
        // console.log("data", res);
        window.open(res.data.data.url, "_self");
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };

  const twitterAuth = async () => {
    setLoader(true);
    await axios
      .get("/twitter/auth")
      .then((res) => {
        setLoader(false);
        // console.log("data", res);
        window.open(res.data.data.url, "_blank");
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };

  const linkedinAuth = async () => {
    setLoader(true);
    await axios
      .get("/linkedin/auth")
      .then((res) => {
        setLoader(false);
        // console.log("data", res);
        window.open(res.data.data.url, "_blank");
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };
  const wordpressAuth = async () => {
    setLoader(true);

    if (!wpData.app_id) {
      setCredsError({ ...credsError, appIdErr: "AppId is required" });
      return;
    }
    if (!wpData.url) {
      setCredsError({ ...credsError, urlErr: "Url is required" });
      return;
    }
    if (!wpData.app_password) {
      setCredsError({ ...credsError, appPassErr: "AppId is required" });
      return;
    } else {
      await axios
        .post("/wordpress/auth", wpData)
        .then((res) => {
          setLoader(false);
          handleCloseModal();
        })
        .catch((err) => {
          setLoader(false);
          console.log("err", err);
          message.error(err.response.data.message);
        });
    }
  };

    // image1.src = template?.[0]?.background_img;
    // image2.src = selectEdit.images;
    // mergeImages(image1, image2, selectEdit.title, selectEdit.hookline);

    // useEffect(() => {
    //   const image1 = new Image();
    //   const image2 = new Image();
  
    //   image1.onload = image2.onload = () => {
    //     mergeImages(image1, image2, selectEdit?.title, selectEdit?.hookline);;
    //   };
  
    //   image1.src = template?.[0]?.background_img;
    // image2.src = selectEdit?.images;
  
    //   // Cleanup function
    //   return () => {
    //     image1.onload = null;
    //     image2.onload = null;
    //   };
    // }, []);
  
    // const mergeImages = (image1, image2, text1, text2) => {
    //   const canvas = document.createElement('canvas');
    //   canvas.width = 800; // Set canvas width
    //   canvas.height = 600; // Set canvas height
    //   const context = canvas.getContext('2d');
  
    //   // Draw the first image on the canvas
    //   context.drawImage(image1, 0, 0, canvas.width / 2, canvas.height);
  
    //   // Draw the second image on the canvas
    //   context.drawImage(image2, canvas.width / 2, 0, canvas.width / 2, canvas.height);
  
    //   // Add text1
    //   context.fillStyle = 'white';
    //   context.font = '20px Arial';
    //   context.fillText(text1, 20, 30);
  
    //   // Add text2
    //   context.fillText(text2, canvas.width / 2 + 20, 30);
  
    //   // Set the merged image in the state
    //   setMergedCanvas(canvas.toDataURL());
    // };

  const handleShowModal = () => {
    setShowWpModal(true);
  };
  const handleCloseModal = () => {
    setShowWpModal(false);
  };

  return (
    <section className="dash_partts">
      {loader == true && (
        <div className="loader-wrapper">
          <Audio
            height="80"
            width="80"
            radius="9"
            color="green"
            ariaLabel="loading"
            wrapperStyle
            wrapperClass
          />
        </div>
      )}
      <div className="dash_inner_partts">
        <div className={`sidebar ${collapsed ? "collapsed" : ""}`}>
          <DashboardLeftPanel />
        </div>
        <div className={`dash_right_panel ${expanded ? "expanded" : ""}`}>
          <button onClick={toggleSidebar} className="toggle__button">
            <img src={Toggle} />
          </button>
          <div className="dash_right_inner_panel">
            <Row>
              <Col lg="9" md="12" sm="12">
                <div className="gpt_create_partts">
                  <div>
                    <div className="create_new_button">
                      <Link
                        to="#"
                        className="create_new_but"
                        onClick={() => setDisplayModal(!displayModal)}
                      >
                        <span>
                          <img src={EditBut} />
                        </span>
                        Create New
                      </Link>
                    </div>
                    <div className={`Modal_1 ${displayModal ? "Show" : ""}`}>
                      <div className="modal_content">
                        <button
                          className="Close"
                          onClick={() => setDisplayModal(!displayModal)}
                        >
                          X
                        </button>
                        <div className="inner_partts_modal">
                          {currentStep === 1 && (
                            <div className="d-flex justify-content-between flex-wrap">
                              <div className="left_partttts">
                                <Tabs defaultActiveKey="tab1" id="my-tabs">
                                  <Tab
                                    eventKey="tab1"
                                    className="nav-item"
                                    title={
                                      <TabTitle
                                        label="Text"
                                        imageSrc={TextIcon}
                                      />
                                    }
                                  >
                                    <div className="nav_text_area">
                                      <div className="post_abt">
                                        <Select
                                          defaultValue={selectedOption}
                                          onChange={setSelectedOption}
                                          options={options}
                                        />
                                        {selectedOptionError && (
                                          <span style={{ color: "red" }}>
                                            {selectedOptionError}
                                          </span>
                                        )}
                                      </div>
                                      <Form.Group
                                        className="left_partts_textarea"
                                        controlId="exampleForm.ControlTextarea1"
                                      >
                                        <Form.Control
                                          as="textarea"
                                          rows={6}
                                          placeholder="3 steps to follow in digital marketing..."
                                          value={topic}
                                          onChange={changeHandler}
                                        />
                                        {topicError && (
                                          <span style={{ color: "red" }}>
                                            {topicError}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </div>
                                  </Tab>
                                </Tabs>
                              </div>
                              <div className="right_parttts">
                                <div className="input_partts">
                                  <ul>
                                    <li className="active">
                                      <div className="input_number">1</div>
                                      <label>Your Inputs</label>
                                    </li>

                                    <li>
                                      <div className="input_number">2</div>
                                      <label>Choose Templates</label>
                                    </li>

                                    <li>
                                      <div className="input_number">3</div>
                                      <label>Finishing Touches</label>
                                    </li>
                                  </ul>
                                </div>
                                {/* <div className="next_button">
              <Link to="#" className="next_but">Next</Link>
          </div> */}
                              </div>
                            </div>
                          )}
                          {currentStep === 2 && (
                            <div className="d-flex justify-content-between flex-wrap">
                              <div className="left_partttts">
                                <Tabs defaultActiveKey="tab1" id="my-tabs">
                                  <Tab
                                    eventKey="tab1"
                                    className="nav-item"
                                    title={
                                      <TabTitle
                                        label="Single Image"
                                        imageSrc={SingleImage}
                                      />
                                    }
                                  >
                                    <div className="nav_text_area">
                                      <div className="image_part">
                                        {template.map((item, index) => (
                                          <div
                                            className="image_partts_nav"
                                            key={index}
                                          >
                                            <div className="image_inner_partts">
                                              {/* <h1 className="temp_title">{item.name}</h1>
      <p className="hook_text">{item.description}</p> */}
                                              <label className="d-block">
                                                <div className="frame_bg_image">
                                                  <input
                                                    type="radio"
                                                    name="test"
                                                    value={item?.background_img}
                                                    checked={
                                                      selectedImage &&
                                                      selectedImage?.background_img ===
                                                        item?.background_img
                                                    }
                                                    onChange={handleImageChange}
                                                  />
                                                  {typeof item?.background_img ===
                                                  "string" ? (
                                                    <img
                                                      src={item?.background_img}
                                                      alt={`Image ${index}`}
                                                    />
                                                  ) : (
                                                    <img
                                                      src={URL.createObjectURL(
                                                        item?.background_img
                                                      )}
                                                      alt={`Image ${index}`}
                                                    />
                                                  )}
                                                  <div className="frame_overlap_image">
                                                    <img
                                                      src={item?.primary_img}
                                                      alt={`Image ${index}`}
                                                    />
                                                  </div>
                                                </div>
                                              </label>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </Tab>
                                </Tabs>
                              </div>

                              <div className="right_parttts">
                                <div className="input_partts">
                                  <ul>
                                    <li className="active">
                                      <div className="input_number">1</div>
                                      <label>Your Inputs</label>
                                    </li>

                                    <li className="active">
                                      <div className="input_number">2</div>
                                      <label>Choose Templates</label>
                                    </li>

                                    <li>
                                      <div className="input_number">3</div>
                                      <label>Finishing Touches</label>
                                    </li>
                                  </ul>
                                </div>
                                {/* <div className="next_button">
              <Link to="#" className="next_but">Next</Link>
          </div> */}
                              </div>
                            </div>
                          )}
                          {currentStep === 3 && (
                            <div className="d-flex justify-content-between flex-wrap third_process">
                              <div className="left_partttts">
                                <Tabs defaultActiveKey="tab1" id="my-tabs">
                                  <Tab
                                    eventKey="tab1"
                                    className="nav-item"
                                    title={
                                      <TabTitle
                                        label="Assets"
                                        imageSrc={Assets}
                                      />
                                    }
                                  >
                                    <div className="nav_text_area">
                                      <label className="ai_boxx">
                                        <input
                                          type="radio"
                                          name="test"
                                          value="big17"
                                        />
                                        <div className="aiboxradio">
                                          <div className="ai_img">
                                            <img src={AiIcon} />
                                          </div>
                                          <p>
                                            Let AI select the best assets for
                                            your post
                                          </p>
                                        </div>
                                      </label>
                                    </div>
                                  </Tab>
                                </Tabs>
                              </div>

                              <div className="right_parttts">
                                <div className="input_partts">
                                  <ul>
                                    <li className="active">
                                      <div className="input_number">1</div>
                                      <label>Your Inputs</label>
                                    </li>

                                    <li className="active">
                                      <div className="input_number">2</div>
                                      <label>Choose Templates</label>
                                      <span className="create_121">
                                        <img
                                          src={selectedImage?.background_img}
                                        />
                                        <div className="frame_overlap_image">
                                          <img
                                            src={selectedImage?.primary_img}
                                          />
                                        </div>
                                      </span>
                                    </li>
                                    <li className="active">
                                      <div className="input_number">3</div>
                                      <label>Finishing Touches</label>
                                    </li>
                                  </ul>
                                </div>
                                {/* <div className="next_button">
              <Link to="#" className="next_but">Next</Link>
          </div> */}
                              </div>
                            </div>
                          )}
                          <div className="next_button">
                            <button
                              className="next_but"
                              onClick={
                                currentStep == 3 ? handleSubmit : handleNext
                              }
                              // disabled={currentStep === totalSteps}
                            >
                              {currentStep != 3 ? "Next" : "Generate"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`Overlay ${displayModal ? "Show" : ""}`}
                      onClick={() => setDisplayModal(!displayModal)}
                    ></div>
                  </div>

                  <div className="articles_grid mt-5">
                    <div className="articles_row row">
                      <div
                        className={`Modal_1 modal_1212 ${
                          displayModal2 ? "Show" : ""
                        }`}
                      >
                        <div className="modal_content">
                          <button
                            className="Close"
                            onClick={() => setDisplayModal2(!displayModal2)}
                          >
                            X
                          </button>
                          <div className="row">
                            <div className="col-4">
                              <select
                                className="form-control"
                                value={time}
                                onChange={handleTimeChange}
                              >
                                <option value="">Select a round time</option>
                                {roundTimeOptions.map((timeOption, index) => (
                                  <option key={index} value={timeOption}>
                                    {timeOption}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col">
                              <div className="text-right mb-4">
                                <Button
                                  className="mr-2"
                                  onClick={handlePostPublish}
                                >
                                  Publish
                                </Button>
                                <Button
                                  className="mr-2"
                                  onClick={handleSchedule}
                                >
                                  Schedule
                                </Button>
                                <Button onClick={getAllSocialAccount}>
                                  Refresh
                                </Button>
                              </div>
                              <div>
                                {timeError && (
                                  <p style={{ color: "red" }}>{timeError}</p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="review_article_box mb-2">
                              {connectedAcc &&
                                connectedAcc.length > 0 &&
                                connectedAcc.map((acc) =>
                                  acc.accounts.map((account) => {
                                    return (
                                      <div className="org_innr_innrr text-dark">
                                        {account !== "" && (
                                          <div className="prf_textts"></div>
                                        )}
                                        <div className="org_innr_partts">
                                          <input
                                            className="styled-checkbox"
                                            id={account.id}
                                            key={`${account.id}-${acc.account_type}`}
                                            type="checkbox"
                                            onChange={(e) => {
                                              handleAccChange(
                                                e,
                                                account.id,
                                                acc.account_type
                                              );
                                            }}
                                          />
                                          <label for={account.id}>
                                            <img
                                              src={
                                                acc.account_type === "Facebook"
                                                  ? FbLogo
                                                  : acc.account_type ===
                                                    "LinkedIn"
                                                  ? LinkedSocial
                                                  : acc.account_type ===
                                                    "Twitter"
                                                  ? TwitterSocial
                                                  : acc.account_type ===
                                                    "Instagram"
                                                  ? InsSocial
                                                  : Wp
                                              }
                                            />
                                            <span>{account.name}</span>
                                          </label>
                                        </div>
                                      </div>
                                    );
                                  })
                                )}
                            </div>
                            <div className="prf_parttts">
                              <div className="prf_textts">
                                <h4 className="text-dark">
                                  Connect your social media account :
                                </h4>
                              </div>
                              <div className="social_connect_inner_sec social__connect_inner_parttts">
                                <div className="social_inner_inner_sec">
                                  <div className="social_white_sec">
                                    <img src={FacebookSocial} width={35} />
                                    <h4>Facebook</h4>
                                    <span>Profile</span>
                                    <button
                                      type="button"
                                      className="connect_button"
                                      onClick={facebookAuth}
                                    >
                                      Connect
                                    </button>
                                  </div>
                                </div>
                                <div className="social_inner_inner_sec">
                                  <div className="social_white_sec">
                                    <img src={InsSocial} width={35} />
                                    <h4>Instagram</h4>
                                    <span>Profile</span>
                                    <button
                                      type="button"
                                      className="connect_button"
                                      onClick={instagramAuth}
                                    >
                                      Connect
                                    </button>
                                  </div>
                                </div>

                                <div className="social_inner_inner_sec">
                                  <div className="social_white_sec">
                                    <img src={TwitterSocial} width={35} />
                                    <h4>Twitter</h4>
                                    <span>Profile</span>
                                    <button
                                      type="button"
                                      className="connect_button"
                                      onClick={twitterAuth}
                                    >
                                      Connect
                                    </button>
                                  </div>
                                </div>
                                <div className="social_inner_inner_sec">
                                  <div className="social_white_sec">
                                    <img src={LinkedSocial} width={35} />
                                    <h4>Linkedin</h4>
                                    <span>Profile</span>
                                    <button
                                      type="button"
                                      className="connect_button"
                                      onClick={linkedinAuth}
                                    >
                                      Connect
                                    </button>
                                  </div>
                                </div>

                                <div className="social_inner_inner_sec">
                                  <div className="social_white_sec">
                                    <img src={Wp} width={35} />
                                    <h4>Wordpress Blog</h4>
                                    <span>Profile</span>
                                    <button
                                      type="button"
                                      className="connect_button"
                                      onClick={handleShowModal}
                                    >
                                      Connect
                                    </button>
                                    <Modal
                                      show={showWpModal}
                                      onHide={handleCloseModal}
                                      size="lg"
                                      centered
                                    >
                                      <Modal.Header className="d-block">
                                        <Modal.Title className="text-center d-block">
                                          Wordpress Login
                                        </Modal.Title>

                                        <Button
                                          variant="secondary"
                                          className="wp_close_modal"
                                          onClick={handleCloseModal}
                                        >
                                          X
                                        </Button>
                                      </Modal.Header>
                                      <Modal.Body>
                                        <div className="login_wp_partts">
                                          <Form.Group
                                            className="mb-3"
                                            controlId="formBasicEmail"
                                          >
                                            <Form.Label>Url</Form.Label>
                                            <Form.Control
                                              name="url"
                                              type="text"
                                              className="emailfield"
                                              placeholder="Enter url"
                                              onChange={handleWpChange}
                                            />
                                            <p style={{ color: "red" }}>
                                              {credsError.urlErr}
                                            </p>
                                          </Form.Group>

                                          <Form.Group
                                            className="mb-3"
                                            controlId="formBasicEmail"
                                          >
                                            <Form.Label>App Id</Form.Label>
                                            <Form.Control
                                              name="app_id"
                                              type="text"
                                              className="emailfield"
                                              placeholder="Enter app id"
                                              onChange={handleWpChange}
                                            />
                                            <p style={{ color: "red" }}>
                                              {credsError.appIdErr}
                                            </p>
                                          </Form.Group>

                                          <Form.Group
                                            className="mb-3"
                                            controlId="formBasicPassword"
                                          >
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control
                                              name="app_password"
                                              type="password"
                                              className="emailfield"
                                              placeholder="Enter password"
                                              onChange={handleWpChange}
                                            />
                                            <p style={{ color: "red" }}>
                                              {credsError.appPassErr}
                                            </p>
                                          </Form.Group>
                                          <Button
                                            variant="primary"
                                            type="submit"
                                            className="wp_submit"
                                            onClick={wordpressAuth}
                                          >
                                            Submit
                                          </Button>
                                        </div>
                                      </Modal.Body>
                                    </Modal>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className={`Modal_1 modal_1212 ${
                          displayModal1 ? "Show" : ""
                        }`}
                      >
                        <div className="modal_content">
                          <button
                            className="Close"
                            onClick={() => setDisplayModal1(!displayModal1)}
                          >
                            X
                          </button>
                          <div className="mb-4 text-right">
                            <Button onClick={handleShere}>Share</Button>
                          </div>
                          <Tabs
                            defaultActiveKey="home"
                            id="justify-tab-example"
                            className="mb-3"
                            fill
                          >
                            <Tab
                              eventKey="home"
                              title={
                                <TabTitleOne
                                  label="Creatives"
                                  imageSrc={Creatives}
                                />
                              }
                            >
                              <div className="creative_img row justify-content-center mt-5">
                                <div className="col-md-4">
                                  <div
                                    style={{
                                      backgroundImage:
                                        "url(" +
                                        template?.[0]?.background_img +
                                        ")",
                                    }}
                                    className="frame_bg_image"
                                  >
                                    <div className="row frame_row align-items-center">
                                      <div className="col">
                                        {selectEdit.title && (
                                          <h2 className="image-title-lg">
                                            {selectEdit.title}
                                          </h2>
                                        )}
                                        {selectEdit.hookline && (
                                          <p className="image-title-sm mb-0">
                                            {selectEdit.hookline}
                                          </p>
                                        )}
                                      </div>
                                      <div className="col">
                                        <img src={selectEdit.images} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tab>
                            <Tab
                              eventKey="profile"
                              title={
                                <TabTitleOne
                                  label="Captions"
                                  style={{ height: "5px", width: "5px" }}
                                  imageSrc={Captions}
                                />
                              }
                            >
                              <div className="generate_more_captions">
                                <div className="generate_more">
                                  <p>Generate more caption options</p>
                                  <div className="tone">
                                    <label>
                                      Tone
                                      <select
                                        name="selectedFruit"
                                        defaultValue="orange"
                                      >
                                        <option value="casual">Casual</option>
                                        <option value="funny">Funny</option>
                                        <option value="formal">Formal</option>
                                        <option value="witty">Witty</option>
                                        <option value="persuasive">
                                          Persuasive
                                        </option>
                                        <option value="empathetic">
                                          Empathetic
                                        </option>
                                      </select>
                                    </label>
                                  </div>

                                  <div className="generate_more_but">
                                    <button className="generate_but">
                                      GENERATE MORE
                                    </button>
                                  </div>
                                </div>

                                <div className="generate__text">
                                  <div className="generate__text__inner">
                                    <p>{selectEdit.content}</p>
                                  </div>
                                </div>
                              </div>
                            </Tab>

                            <Tab
                              eventKey="longer-tab"
                              title={
                                <TabTitleOne
                                  label="Hashtag"
                                  imageSrc={Hashtag}
                                />
                              }
                            >
                              <div className="hashtags_by">
                                <div className="hash">
                                  <label>Hashtags by</label>
                                  <div className="radio_label">
                                    <div className="radio">
                                      <input
                                        id="radio-1"
                                        name="radio"
                                        type="radio"
                                        checked
                                      />
                                      <label
                                        for="radio-1"
                                        className="radio-label"
                                      >
                                        Caption
                                      </label>
                                    </div>
                                    <div className="radio">
                                      <input
                                        id="radio-2"
                                        name="radio"
                                        type="radio"
                                      />
                                      <label
                                        for="radio-2"
                                        className="radio-label"
                                      >
                                        Creative
                                      </label>
                                    </div>
                                    <div className="radio">
                                      <input
                                        id="radio-3"
                                        name="radio"
                                        type="radio"
                                      />
                                      <label
                                        for="radio-3"
                                        className="radio-label"
                                      >
                                        Keyword
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="sort">
                                  <label>Sort By</label>
                                  <div className="radio_label">
                                    <div className="radio">
                                      <input
                                        id="radio-5"
                                        name="radio"
                                        type="radio"
                                        checked
                                      />
                                      <label
                                        for="radio-5"
                                        className="radio-label"
                                      >
                                        Relevancy
                                      </label>
                                    </div>
                                    <div className="radio">
                                      <input
                                        id="radio-8"
                                        name="radio"
                                        type="radio"
                                      />
                                      <label
                                        for="radio-8"
                                        className="radio-label"
                                      >
                                        Reach
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="hash_content">
                                <p>Choose 5 Hashtags Related To your Content</p>
                                <div className="business_tagger">
                                  <div className="business_tag">
                                    <span>
                                      <input id="foo" type="checkbox" />
                                      <label for="foo">
                                        #Businessstrategy <span>1.1M</span>
                                      </label>
                                    </span>
                                  </div>
                                  <div className="business_tag">
                                    <span>
                                      <input id="bah" type="checkbox" />
                                      <label for="bah">
                                        #Businesssuccess <span>1.1M</span>
                                      </label>
                                    </span>
                                  </div>
                                  <div className="business_tag">
                                    <span>
                                      <input id="bah1" type="checkbox" />
                                      <label for="bah1">
                                        #Businesssuccess <span>1.1M</span>
                                      </label>
                                    </span>
                                  </div>
                                  <div className="business_tag">
                                    <span>
                                      <input id="bah2" type="checkbox" />
                                      <label for="bah2">
                                        #Businesssuccess <span>1.1M</span>
                                      </label>
                                    </span>
                                  </div>
                                  <div className="business_tag">
                                    <span>
                                      <input id="bah3" type="checkbox" />
                                      <label for="bah3">
                                        #Businesssuccess <span>1.1M</span>
                                      </label>
                                    </span>
                                  </div>
                                  <div className="business_tag">
                                    <span>
                                      <input id="bah4" type="checkbox" />
                                      <label for="bah4">
                                        #Businesssuccess <span>1.1M</span>
                                      </label>
                                    </span>
                                  </div>
                                  <div className="business_tag">
                                    <span>
                                      <input id="bah5" type="checkbox" />
                                      <label for="bah5">
                                        #Businesssuccess <span>1.1M</span>
                                      </label>
                                    </span>
                                  </div>
                                  <div className="business_tag">
                                    <span>
                                      <input id="bah6" type="checkbox" />
                                      <label for="bah6">
                                        #Businesssuccess <span>1.1M</span>
                                      </label>
                                    </span>
                                  </div>
                                  <div className="business_tag">
                                    <span>
                                      <input id="bah7" type="checkbox" />
                                      <label for="bah7">
                                        #Businesssuccess <span>1.1M</span>
                                      </label>
                                    </span>
                                  </div>
                                  <div className="business_tag">
                                    <span>
                                      <input id="bah8" type="checkbox" />
                                      <label for="bah8">
                                        #Businesssuccess <span>1.1M</span>
                                      </label>
                                    </span>
                                  </div>
                                  <div className="business_tag">
                                    <span>
                                      <input id="bah9" type="checkbox" />
                                      <label for="bah9">
                                        #Businesssuccess <span>1.1M</span>
                                      </label>
                                    </span>
                                  </div>
                                  <div className="business_tag">
                                    <span>
                                      <input id="bah10" type="checkbox" />
                                      <label for="bah10">
                                        #Businesssuccess <span>1.1M</span>
                                      </label>
                                    </span>
                                  </div>
                                  <div className="business_tag">
                                    <span>
                                      <input id="bah15" type="checkbox" />
                                      <label for="bah15">
                                        #Businesssuccess <span>1.1M</span>
                                      </label>
                                    </span>
                                  </div>
                                  <div className="business_tag">
                                    <span>
                                      <input id="bah26" type="checkbox" />
                                      <label for="bah26">
                                        #Businesssuccess <span>1.1M</span>
                                      </label>
                                    </span>
                                  </div>
                                  <div className="business_tag">
                                    <span>
                                      <input id="bah37" type="checkbox" />
                                      <label for="bah37">
                                        #Businesssuccess <span>1.1M</span>
                                      </label>
                                    </span>
                                  </div>
                                  <div className="business_tag">
                                    <span>
                                      <input id="bah48" type="checkbox" />
                                      <label for="bah48">
                                        #Businesssuccess <span>1.1M</span>
                                      </label>
                                    </span>
                                  </div>
                                  <div className="business_tag">
                                    <span>
                                      <input id="bah59" type="checkbox" />
                                      <label for="bah59">
                                        #Businesssuccess <span>1.1M</span>
                                      </label>
                                    </span>
                                  </div>
                                  <div className="business_tag">
                                    <span>
                                      <input id="bah106" type="checkbox" />
                                      <label for="bah106">
                                        #Businesssuccess <span>1.1M</span>
                                      </label>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </Tab>

                            <Tab
                              eventKey="contact"
                              title={
                                <TabTitleOne
                                  label="Suggetions"
                                  imageSrc={Suggetions}
                                />
                              }
                            >
                              <div className="hashtags_by">
                                <div className="hash">
                                  <label>Hashtags by</label>
                                  <div className="radio_label">
                                    <div className="radio">
                                      <input
                                        id="radio-1"
                                        name="radio"
                                        type="radio"
                                        checked
                                      />
                                      <label
                                        for="radio-1"
                                        className="radio-label"
                                      >
                                        Caption
                                      </label>
                                    </div>
                                    <div className="radio">
                                      <input
                                        id="radio-2"
                                        name="radio"
                                        type="radio"
                                      />
                                      <label
                                        for="radio-2"
                                        className="radio-label"
                                      >
                                        Creative
                                      </label>
                                    </div>
                                    <div className="radio">
                                      <input
                                        id="radio-3"
                                        name="radio"
                                        type="radio"
                                      />
                                      <label
                                        for="radio-3"
                                        className="radio-label"
                                      >
                                        Keyword
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="sort">
                                  <label>Sort By</label>
                                  <div className="radio_label">
                                    <div className="radio">
                                      <input
                                        id="radio-5"
                                        name="radio"
                                        type="radio"
                                        checked
                                      />
                                      <label
                                        for="radio-5"
                                        className="radio-label"
                                      >
                                        Relevancy
                                      </label>
                                    </div>
                                    <div className="radio">
                                      <input
                                        id="radio-8"
                                        name="radio"
                                        type="radio"
                                      />
                                      <label
                                        for="radio-8"
                                        className="radio-label"
                                      >
                                        Reach
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="hash_content">
                                <p>
                                  We are suggesting few hastags related to your
                                  content
                                </p>
                                <div className="business_tagger">
                                  <div className="business_tag">
                                    <span>
                                      <input id="foo" type="checkbox" />
                                      <label for="foo">
                                        #Businessstrategy <span>1.1M</span>
                                      </label>
                                    </span>
                                  </div>
                                  <div className="business_tag">
                                    <span>
                                      <input id="bah" type="checkbox" />
                                      <label for="bah">
                                        #Businesssuccess <span>1.1M</span>
                                      </label>
                                    </span>
                                  </div>
                                  <div className="business_tag">
                                    <span>
                                      <input id="bah1" type="checkbox" />
                                      <label for="bah1">
                                        #Businesssuccess <span>1.1M</span>
                                      </label>
                                    </span>
                                  </div>
                                  <div className="business_tag">
                                    <span>
                                      <input id="bah2" type="checkbox" />
                                      <label for="bah2">
                                        #Businesssuccess <span>1.1M</span>
                                      </label>
                                    </span>
                                  </div>
                                  <div className="business_tag">
                                    <span>
                                      <input id="bah3" type="checkbox" />
                                      <label for="bah3">
                                        #Businesssuccess <span>1.1M</span>
                                      </label>
                                    </span>
                                  </div>
                                  <div className="business_tag">
                                    <span>
                                      <input id="bah4" type="checkbox" />
                                      <label for="bah4">
                                        #Businesssuccess <span>1.1M</span>
                                      </label>
                                    </span>
                                  </div>
                                  <div className="business_tag">
                                    <span>
                                      <input id="bah5" type="checkbox" />
                                      <label for="bah5">
                                        #Businesssuccess <span>1.1M</span>
                                      </label>
                                    </span>
                                  </div>
                                  <div className="business_tag">
                                    <span>
                                      <input id="bah6" type="checkbox" />
                                      <label for="bah6">
                                        #Businesssuccess <span>1.1M</span>
                                      </label>
                                    </span>
                                  </div>
                                  <div className="business_tag">
                                    <span>
                                      <input id="bah7" type="checkbox" />
                                      <label for="bah7">
                                        #Businesssuccess <span>1.1M</span>
                                      </label>
                                    </span>
                                  </div>
                                  <div className="business_tag">
                                    <span>
                                      <input id="bah8" type="checkbox" />
                                      <label for="bah8">
                                        #Businesssuccess <span>1.1M</span>
                                      </label>
                                    </span>
                                  </div>
                                  <div className="business_tag">
                                    <span>
                                      <input id="bah9" type="checkbox" />
                                      <label for="bah9">
                                        #Businesssuccess <span>1.1M</span>
                                      </label>
                                    </span>
                                  </div>
                                  <div className="business_tag">
                                    <span>
                                      <input id="bah10" type="checkbox" />
                                      <label for="bah10">
                                        #Businesssuccess <span>1.1M</span>
                                      </label>
                                    </span>
                                  </div>
                                  <div className="business_tag">
                                    <span>
                                      <input id="bah15" type="checkbox" />
                                      <label for="bah15">
                                        #Businesssuccess <span>1.1M</span>
                                      </label>
                                    </span>
                                  </div>
                                  <div className="business_tag">
                                    <span>
                                      <input id="bah26" type="checkbox" />
                                      <label for="bah26">
                                        #Businesssuccess <span>1.1M</span>
                                      </label>
                                    </span>
                                  </div>
                                  <div className="business_tag">
                                    <span>
                                      <input id="bah37" type="checkbox" />
                                      <label for="bah37">
                                        #Businesssuccess <span>1.1M</span>
                                      </label>
                                    </span>
                                  </div>
                                  <div className="business_tag">
                                    <span>
                                      <input id="bah48" type="checkbox" />
                                      <label for="bah48">
                                        #Businesssuccess <span>1.1M</span>
                                      </label>
                                    </span>
                                  </div>
                                  <div className="business_tag">
                                    <span>
                                      <input id="bah59" type="checkbox" />
                                      <label for="bah59">
                                        #Businesssuccess <span>1.1M</span>
                                      </label>
                                    </span>
                                  </div>
                                  <div className="business_tag">
                                    <span>
                                      <input id="bah106" type="checkbox" />
                                      <label for="bah106">
                                        #Businesssuccess <span>1.1M</span>
                                      </label>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </Tab>
                          </Tabs>
                        </div>
                      </div>

                      {getArticles?.map((val, buttonId) => {
                        return (
                          <div className="design_inner_topper col-lg-4 col-md-4 col-12 articles_grid_item my-3">
                            <div className="card border-0 h-100 overflow-hidden">
                              <div
                                className="design_inner_top framed_image"
                                style={{
                                  backgroundImage: "url(" + Frame_1 + ")",
                                }}
                              >
                                <div className="row frame_row align-items-center">
                                  <div className="col">
                                    {val.title && (
                                      <h2 className="image-title">
                                        {val.title}
                                      </h2>
                                    )}
                                    {val.hookline && (
                                      <p className="image-title mb-0">
                                        {val.hookline}
                                      </p>
                                    )}
                                  </div>
                                  <div className="col">
                                    <img
                                      onClick={() => handleImageClick(val)}
                                      src={val.images}
                                      alt={val.title}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="design_inner_bottom">
                                <p className="article_text_content">
                                  {showText === buttonId
                                    ? val.content
                                    : val.content.slice(0, 40) + "..."}
                                </p>
                                <div className="text-right mb-3">
                                  <a
                                    href="javascript:void(0)"
                                    onClick={() => handleButtonClick(buttonId)}
                                  >
                                    {showText === buttonId
                                      ? "Show Less"
                                      : "Show More"}
                                  </a>
                                </div>

                                <div className="minutes_ago">
                                  <p>3 minutes ago</p>
                                  <label className="button_type">
                                    <button type="button" className="like_but">
                                      <img src={Like} />
                                    </button>
                                    <button
                                      type="button"
                                      className="dislike_but"
                                    >
                                      <img src={DisLike} />
                                    </button>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg="3" md="12" sm="12" className="pr-0">
                <div className="dash_right_paertts">
                  <DashRightPanel />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GenerateArticlesNew;
