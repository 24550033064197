import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";

import "bootstrap/dist/css/bootstrap.min.css";

import Layout from "./components/layout/layout";
import HomePage from "./pages/Home/Home";
import LoginPage from "./pages/Login/login";
import RegistrationPage from "./pages/Registration/registration";
import DashboardPage from "./pages/Dashboard/dashboard";
import MemberPage from "./pages/Dashboard/ManageMembers/Members";
import SocialConnect from "./pages/Dashboard/SocialConnect/SocialConnect";
import SocialAuth from "./pages/Dashboard/SocialConnect/SocialAuth";
import LinkedInAuth from "./pages/Dashboard/SocialConnect/LinkedInAuth";
import SocialAnalytics from "./pages/Dashboard/SocialAnalytics/SocialAnalytics";
import GenerateArticles from "./pages/Dashboard/GenerateArticle/GenerateArticle";
import GenerateArticlesNew from "./pages/Dashboard/GenerateArticlenew/generate-articlenew";
import ContentCalendarPage from "./pages/Dashboard/ContentCalendar/content-calendar";
import ArchiveArticle from "./pages/Dashboard/ArchiveArticle/archive-article";
import PlanPage from "./pages/Dashboard/Plan/plan";
import ProfilePage from "./pages/Dashboard/profile/profile";
import FolderPage from "./pages/Dashboard/my-folder/Folder";
import AffiliatePage from "./pages/Dashboard/Affiliates/affiliates";
import SupportPage from "./pages/Dashboard/Support/support";
import ForGotPasswordPage from "./pages/ForgotPassword/ForgotPassword";
import ResetPasswordPage from "./pages/ForgotPassword/ResetPasswordPage";
import { ThemeProvider } from "./ThemeContext";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import EmailVerifyPage from "./pages/Registration/emailVerifyPage";
import AddMember from "./pages/Dashboard/ManageMembers/AddMember";
import UpdateMember from "./pages/Dashboard/ManageMembers/UpdateMember";
import PAymentSuccessfull from "./pages/Dashboard/PaymentSuccessfull/PaymentSuccessfull";
import PAymentFailed from "./pages/Dashboard/PaymentFailed/PaymentFailed";
import ChatPage from "./pages/Dashboard/Chat/Chat";
import RaiseTickets from "./pages/Dashboard/Chat/RaiseTicket";
import InstagramAuth from "./pages/Dashboard/SocialConnect/InstagramAuth";
import InstagramGenerate from "./pages/Dashboard/GenerateArticle/InstagramGenerate";
import TwitterGenerate from "./pages/Dashboard/GenerateArticle/TwitterGenerate";
import NewRegistration from "./pages/Registration/new-registration";
import IndivRegistration from "./pages/Registration/individual-registration";
import ArticleGenerateNewDesign from "./pages/Dashboard/GenerateArticle/ArticleGenerateNewDesign";
import SsoLogin from "./pages/Login/ssoLogin";
import ShareOnMedia from "./pages/Dashboard/GenerateArticle/ShareOnMedia";
import DashboardHomePage from "./pages/Dashboard/DashboardHome";
// import DashboardLeftPanel from "./components/DashboardLeftPanel/dashboardLeftPanel";
// function RouteWrapper({
//   component: Component,
//   layout: Layout,
//   ...rest
// }) {
//   return (
//     <Route {...rest} render={(props) =>
//       <Layout {...props}>
//         <Component {...props} />
//       </Layout>
//     } />
//   );
// }

function App() {
  return (
    <ThemeProvider>
      <div className="App" id="app">
        <Router>
          <Switch>
            <Route exact path="/">
              <Layout isDashboard={false}>
                <HomePage />
              </Layout>
            </Route>

            <Route exact path="/login">
              <Layout isDashboard={false}>
                <LoginPage />
              </Layout>
            </Route>

            <Route exact path="/sso-login/:token?">
              <Layout isDashboard={false}>
                <SsoLogin />
              </Layout>
            </Route>

            <Route exact path="/organization-registration">
              <Layout isDashboard={false}>
                <RegistrationPage />
              </Layout>
            </Route>

            <Route exact path="/individual-registration">
              <Layout isDashboard={false}>
                <IndivRegistration />
              </Layout>
            </Route>

            <Route exact path="/linkedin/auth">
              <Layout isDashboard={false}>
                <LinkedInAuth />
              </Layout>
            </Route>

            <Route exact path="/social-analytics">
              <Layout isDashboard={true}>
                <SocialAnalytics />
              </Layout>
            </Route>

            <Route exact path="/facebook/auth">
              <Layout isDashboard={false}>
                <SocialAuth />
              </Layout>
            </Route>

            <Route exact path="/twitter/auth">
              <Layout isDashboard={false}>
                <SocialAuth />
              </Layout>
            </Route>

            <Route exact path="/instagram/auth">
              <Layout isDashboard={false}>
                <InstagramAuth />
              </Layout>
            </Route>

            <Route exact path="/support">
              <Layout isDashboard={true}>
                <SupportPage />
              </Layout>
            </Route>
            <Route exact path="/chat-with-us">
              <Layout isDashboard={true}>
                <ChatPage />
              </Layout>
            </Route>
            <Route exact path="/raise-tickets">
              <Layout isDashboard={true}>
                <RaiseTickets />
              </Layout>
            </Route>
            <Route exact path="/forgot-password">
              <Layout isDashboard={false}>
                <ForGotPasswordPage />
              </Layout>
            </Route>

            <Route exact path="/reset-password/:code">
              <Layout isDashboard={false}>
                <ResetPasswordPage />
              </Layout>
            </Route>

            <Route exact path="/email-verify/:code">
              <Layout isDashboard={false}>
                <EmailVerifyPage />
              </Layout>
            </Route>

            <Route exact path="/signup/:code">
              <Layout isDashboard={false}>
                <AddMember />
              </Layout>
            </Route>

            <Route exact path="/manage-members/:id">
              <Layout isDashboard={true}>
                <UpdateMember />
              </Layout>
            </Route>

            <Route exact path="/payment-confirm">
              <Layout isDashboard={true}>
                <PAymentSuccessfull />
              </Layout>
            </Route>
            <Route exact path="/payment-failed">
              <Layout isDashboard={true}>
                <PAymentFailed />
              </Layout>
            </Route>

            <Route exact path="/generate-article/:postId">
              <Layout isDashboard={true}>
                <GenerateArticles />
              </Layout>
            </Route>

            <Route exact path="/registration">
              <Layout isDashboard={false}>
                <NewRegistration />
              </Layout>
            </Route>

            {/* <Route exact path="/generate-articles-2">
              <Layout isDashboard={true}>
                <GenerateArticlesNew />
              </Layout>
            </Route> */}
            {localStorage.getItem("AuthToken") ? (
              <Route exact path="/dashboard">
                <Layout isDashboard={true}>
                  <DashboardPage />
                </Layout>
              </Route>
            ) : (
              <>
                {" "}
                <Redirect to="/" />
              </>
            )}

            {localStorage.getItem("AuthToken") ? (
              <Route exact path="/dashboard-home">
                <Layout isDashboard={true}>
                  <DashboardHomePage />
                </Layout>
              </Route>
            ) : (
              <>
                {" "}
                <Redirect to="/" />
              </>
            )}

            {localStorage.getItem("AuthToken") ? (
              <Route exact path="/generate-article">
                <Layout isDashboard={true}>
                  {/* <GenerateArticles /> */}
                  <ArticleGenerateNewDesign />
                </Layout>
              </Route>
            ) : (
              <>
                {" "}
                <Redirect to="/" />
              </>
            )}

            {localStorage.getItem("AuthToken") ? (
              <Route exact path="/generate-content">
                <Layout isDashboard={true}>
                  <ArticleGenerateNewDesign />
                </Layout>
              </Route>
            ) : (
              <>
                {" "}
                <Redirect to="/" />
              </>
            )}

            {localStorage.getItem("AuthToken") ? (
              <Route exact path="/instagram-generate-article">
                <Layout isDashboard={true}>
                  <InstagramGenerate />
                </Layout>
              </Route>
            ) : (
              <>
                {" "}
                <Redirect to="/" />
              </>
            )}
            {localStorage.getItem("AuthToken") ? (
              <Route exact path="/twitter-generate-article">
                <Layout isDashboard={true}>
                  <TwitterGenerate />
                </Layout>
              </Route>
            ) : (
              <>
                {" "}
                <Redirect to="/" />
              </>
            )}

            {localStorage.getItem("AuthToken") ? (
              <Route exact path="/content-calendar">
                <Layout isDashboard={true}>
                  <ContentCalendarPage />
                </Layout>
              </Route>
            ) : (
              <>
                {" "}
                <Redirect to="/" />
              </>
            )}

            {localStorage.getItem("AuthToken") ? (
              <Route exact path="/archive-article">
                <Layout isDashboard={true}>
                  <ArchiveArticle />
                </Layout>
              </Route>
            ) : (
              <>
                {" "}
                <Redirect to="/" />
              </>
            )}

            {localStorage.getItem("AuthToken") ? (
              <Route exact path="/plan">
                <Layout isDashboard={true}>
                  <PlanPage />
                </Layout>
              </Route>
            ) : (
              <>
                {" "}
                <Redirect to="/" />
              </>
            )}

            {localStorage.getItem("AuthToken") ? (
              <Route exact path="/profile">
                <Layout isDashboard={true}>
                  <ProfilePage />
                </Layout>
              </Route>
            ) : (
              <>
                {" "}
                <Redirect to="/" />
              </>
            )}

            {localStorage.getItem("AuthToken") ? (
              <Route exact path="/my-folder">
                <Layout isDashboard={true}>
                  <FolderPage />
                </Layout>
              </Route>
            ) : (
              <>
                {" "}
                <Redirect to="/" />
              </>
            )}

            {localStorage.getItem("AuthToken") ? (
              <Route exact path="/manage-members">
                <Layout isDashboard={true}>
                  <MemberPage />
                </Layout>
              </Route>
            ) : (
              <>
                {" "}
                <Redirect to="/" />
              </>
            )}

            {localStorage.getItem("AuthToken") ? (
              <Route exact path="/social-connect">
                <Layout isDashboard={true}>
                  <SocialConnect />
                </Layout>
              </Route>
            ) : (
              <>
                {" "}
                <Redirect to="/" />
              </>
            )}

            {localStorage.getItem("AuthToken") ? (
              <Route exact path="/social-analytics">
                <Layout isDashboard={true}>
                  <SocialAnalytics />
                </Layout>
              </Route>
            ) : (
              <>
                {" "}
                <Redirect to="/" />
              </>
            )}

            {localStorage.getItem("AuthToken") ? (
              <Route exact path="/affiliates">
                <Layout isDashboard={true}>
                  <AffiliatePage />
                </Layout>
              </Route>
            ) : (
              <>
                {" "}
                <Redirect to="/" />
              </>
            )}

            {localStorage.getItem("AuthToken") ? (
              <Route exact path="/share-on-media/:id">
                <Layout isDashboard={true}>
                  {/* <GenerateArticles /> */}
                  <ShareOnMedia />
                </Layout>
              </Route>
            ) : (
              <>
                {" "}
                <Redirect to="/" />
              </>
            )}
          </Switch>
        </Router>
      </div>
    </ThemeProvider>
  );
}
export default App;
