import React, { useState, useContext, useEffect } from 'react';
import { Container, Row, Col, Modal, Button, Form, Dropdown, Table, Accordion, Card } from 'react-bootstrap';
import { Link } from "react-router-dom";
import './failed.css';


const PAymentSuccessfull = () => {
    return(
        <section className='success_payment'></section>
        );
    };
    
export default PAymentSuccessfull;