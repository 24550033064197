import React, { useState, useContext, useEffect } from "react";
//import "./styles.css";
import { Container, Navbar, Nav, Dropdown } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
// import LeftpanelAdmin from "../../../components/leftpanel/left-panel-admin";
// import HeaderPart from "../../../components/layout/header-admin/header";
import "./header.css";
import {
  DashboardLogo,
  Mode,
  Bell,
  LogOut,
  BlackThemeLogo,
  Mode2,
} from "../../../assets/images";
import { useHistory } from "react-router-dom";
import axios from "../../../axios/axios";

import { ThemeContext } from "../../../ThemeContext";
const DashboardHeader = () => {
  const history = useHistory();
  // const [isToggled, setIsToggled] = useState(false);
  const { isDarkTheme, toggleTheme } = useContext(ThemeContext);

  // const toggleMode = () => {
  //   setIsDarkMode(!isDarkMode);
  // };
  const [isClassApplied, setIsClassApplied] = useState(true);
  const [authUserData, setauthUserData] = useState(
    localStorage.getItem("AuthUserData")
      ? JSON.parse(localStorage.getItem("AuthUserData"))
      : null
  );
  const [loader, setLoader] = useState(false)

  const handleButtonClick = () => {
    setIsClassApplied((prevState) => !prevState);
  };

  // Add or remove the class to/from the body element based on the state
  if (isClassApplied) {
    document.body.classList.add("dark-theme");
  } else {
    document.body.classList.remove("dark-theme");
  }
  const handleLogout = () => {
    localStorage.removeItem("AuthToken");
    window.open("/", "_self");
  };

  // const [isToggled, setIsToggled] = useState(false);

  // // Function to handle the toggle
  // const handleToggle = () => {
  //   setIsToggled((prevIsToggled) => !prevIsToggled);
  // };

  // Effect to load the state from localStorage on initial render
  useEffect(() => {
    const storedIsClassApplied = localStorage.getItem("isClassApplied");
    if (storedIsClassApplied) {
      setIsClassApplied(JSON.parse(storedIsClassApplied));
    }
  }, []);

  // Effect to save the state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("isClassApplied", JSON.stringify(isClassApplied));
  }, [isClassApplied]);

  return (
    <div className="dash_header_partts">
      <section className="dashboard_header">
        <div className="d-flex align-items-center">
          <div className="dashboard_logo">
            <img
              src={DashboardLogo}
              onClick={() => {
                history.push("/dashboard");
              }}
            />
          </div>
          <div className="dash_black_logo">
            {authUserData.user_info.type !== "Corporate" && (
              <img
                src={BlackThemeLogo}
                onClick={() => {
                  history.push("/dashboard");
                }}
              />
            )}
          </div>
          {authUserData != null &&
          authUserData.user_info.type == "Corporate" &&
          authUserData.org_info.profile_picture ? (
            <>
              <div className="dash_black_logo">
                <img
                  src={authUserData.org_info.profile_picture}
                  onClick={() => {
                    history.push("/dashboard");
                  }}
                  width="115"
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>

        <div className="notification_and_logout">
          <Link to="#" className="noti_link" onClick={handleButtonClick}>
            <img src={Mode} width={26} className="mode1" />
            <img src={Mode2} width={32} className="mode2" />
          </Link>
          {/* <Link to="#" className="noti_link">
            <img src={Bell} width={26} />
            <span></span>
          </Link> */}
          <Link to="#" onClick={handleLogout} className="noti_link">
            <img src={LogOut} width={26} />
          </Link>
        </div>
      </section>
    </div>
  );
};

export default DashboardHeader;
