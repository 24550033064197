import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import PropTypes from "prop-types"
import axios from '../../axios/axios'
import { message } from 'antd'

const CreateFolderModal = ({ showModal, handleCreate, folderName, handleChange, handleClose, folderNameError, setFolderName }) => {
	return (
		<Modal show={showModal} centered size="lg">
			<Modal.Header className="d-block">
				<Modal.Title className="text-center">Create Folder</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={handleCreate}>
					<Form.Group
						className="mb-3 describe_form"
						controlId="formBasicEmail"
					>
						<Form.Label>Folder Name</Form.Label>
						<Form.Control
							autoFocus
							type="text"
							className="type_email"
							value={folderName}
							placeholder="Enter folder name"
							onChange={handleChange}
						/>
						{folderNameError && <p style={{ color: "red" }}>{folderNameError}</p>}
					</Form.Group>
					{/* <Button type="submit" className="type_submit_butt">Create</Button> */}
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
					Close
				</Button>
				<Button variant="primary" onClick={handleCreate}>
					Create
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

CreateFolderModal.propTypes = {
	showModal: PropTypes.bool.isRequired,
	folderName: PropTypes.string.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleClose: PropTypes.func.isRequired,
	setFolderName: PropTypes.func.isRequired,
	handleCreate: PropTypes.func.isRequired,
	folderNameError: PropTypes.string
}

export default CreateFolderModal