import Logo from "./logo.png";
import Grad1 from "./grad_1.png";
import Grad2 from "./grad_2.png";
import Grad3 from "./grad_3.png";
import Grad4 from "./grad_4.png";
import Grad5 from "./grad_5.png";
import GptScreenshot from "./gpt_screenshot.png";
import Reg from "./reg.png";
import Topic from "./topic.png";
import Generate from "./generate.png";
import GPTScreen from "./gpt_screen.png";
import GenerateCont from "./generate_cont.png";
import GeneratorGrad from "./generator_grad.png";
import Grad6 from "./grad_6.png";
import Top1 from "./top1.png";
import Globe from "./globe.png";
import Iconss2 from "./iconss2.png";
import Iconss3 from "./iconss3.png";
import Seo from "./seo.png";
import Calendar from "./calendar.png";
import Publish from "./publish.png";
import Scheduling from "./scheduling.png";
import Analytical from "./analytical.png";
import Grad7 from "./grad_7.png";
import Grad8 from "./grad_8.png";
import Grad9 from "./grad_9.png";
import ParentalControl from "./parental_control.png";
import Grad10 from "./grad_10.png";
import Grad11 from "./grad_11.png";
import ManImage from "./man_image.png";
import Social from "./social.png";
import SocialMediaGrad from "./social_media_grad.png";
import Grad12 from "./grad_12.png";
import TwitterBg from "./twitter_bg.png";
import OpenUpBg from "./openup_bg.png";
import RewritterBg from "./rewriter_bg.png";
import ArticleBG from "./article_bg.png";
import YellowStar from "./yellow_star.png";
import Blogger from "./blogger.png";
import TestGrad from "./testimonial_grad.png";
import AccordionGrad from "./faq_grad.png";
import FaceBook from "./face_book.png";
import Twitter from "./twi_tter.png";
import Instagram from "./instagram.png";
import Linkedin from "./linked_in.png";
import PlayButton from "./play_button.png";
import LoginLogo from "./login_logo.png";
import DashboardLogo from "./dashboard_logo.png";
import Mode from "./mode.png";
import Bell from "./bell_icon.png";
import LogOut from "./log_out.png";
import DashIcon from "./dash_icon.png";
import Members from "./member.png";
import Connects from "./connects.png";
import Analytics from "./analytics.png";
import Calendar1 from "./calendar_icon.png";
import Art1 from "./art_1.png";
import Art2 from "./art_2.png";
import Art3 from "./art_3.png";
import Art4 from "./art_4.png";
import Gadget from "./gadget_1.png";
import Facebook from "./facebook.png";
import Clock from "./clock.png";
import InsIcon from "./instagram_icon.png";
import TwitterIcon from "./twitter_icon.png";
import FacebookIcon from "./facebook_iconn.png";
import InsIconn from "./ins_iconn.png";
import DashLogo from "./dash_logo.png";
import MemberRight from "./member_right.png";
import Toggle from "./toggle.png";
import SearchIcon from "./search_icon.png";
import NameView from "./name_view.png";
import action from "./action.png";
import FacebookSocial from "./facebook_social.png";
import InsSocial from "./ins_social.png";
import TwitterSocial from "./twitter_social.png";
import LinkedSocial from "./ln_social.png";
import Wp from "./wp.png";
import TwitterIconn from "./twitter_iconn.png";
import TotalPostts from "./total_postts.png";
import PostReach from "./post_reach.png";
import UpArrow from "./up_arrow.png";
import PostEngagement from "./post_engagement.png";
import DownArrow1 from "./down_arrow1.png";
import PostView from "./post_views.png";
import Cal from "./cal.png";
import SquareImage from "./square_image.png";
import ProtraitImage from "./protrait_image.png";
import LandscapeImage from "./landscape_image.png";
import GenImg1 from "./gen_img1.png";
import GenImg2 from "./gen_image3.png";
import GenImg3 from "./gen_image4.png";
import GenImg5 from "./gen_image5.png";
import BlueTick from "./blue_tick.png";
import PrevImg from "./prev_img.png";
import PrevCross from "./prev_cross.png";
import FbLogo from "./fb_logo.png";
import Social1 from "./social_1.png";
import Social2 from "./social_2.png";
import Social3 from "./social_3.png";
import Social4 from "./social_4.png";
import Social5 from "./social_5.png";
import SocialTop from "./social_top.png";
import SocialImggg from "./social_imggg.png";
import Calendar11 from "./calendar1.png";
import Book22 from "./book.png";
import ArchiveArticle from "./archive_article.png";
import Search1 from "./search_1.png";
import TickPlan from "./tick-plan.png";
import ProTopImg from "./profile_bg.png";
import PrfBannerEidt from "./prf_banner_edit.png";
import PrfImggg from "./prf_img.png";
import SearchIcon121 from "./search_icon.png";
import Folder from "./folder.png";
import Icon11 from "./icon__1.png";
import Icon2 from "./enr.png";
import Comm from "./comm.png";
import Lifetime from "./lifetime.png";
import DemoLogo from "./demo_logo.jpg";
import SearchButtonIcon from "./search_button_icon.png";
import Support1 from "./support_1.png";
import SupportChatts from "./support_chats.png";
import BlackThemeLogo from "./black_theme_logo.png";
import Mode2 from "./mode_2.png";
import TwitterLike from "./svg/heart.svg";
import ChatTwitter from "./svg/chat-twitter.svg";
import Retweet from "./svg/retweet.svg";
import LikeLinkedin from "./svg/like-linkedin.svg";
import CommentsLinkedin from "./svg/comments-linkedin.svg";
import ShareLinkedin from "./svg/retweet1.svg";
import no_image from "./no_image.png";
import no_prf from "./no_prf.png";
import PaymentSuccess from "./success-tick.png";
import OrgReg from "./org_img.png";
import UserReg from "./user_reg.png";
import Information from "./information.png";
import EditBut from "./pencil_button.png";
import Create1 from "./create_1.png";
import Create2 from "./create_2.png";
import Create3 from "./create_3.png";
import Create4 from "./create_4.png";
import Create5 from "./create_5.png";
import Create6 from "./create_6.png";
import Like from "./like.png";
import DisLike from "./dislike.png";
import TextIcon from "./text_icon.png";
import SingleImage from "./image_icon.png";
import Image1 from "./image_121.jpeg";
import Image2 from "./image_122.jpeg";
import Image3 from "./image_123.jpeg";
import Image4 from "./image_124.jpeg";
import Image5 from "./image_125.jpeg";
import Image6 from "./image_126.jpeg";
import Assets from "./assets.png";
import AiIcon from "./ai_img.png";
import Creatives from "./creative.png";
import Captions from "./caption1.png";
import Hashtag from "./hashtag.png";
import Suggetions from "./suggetions.png";
import CreativeImg from "./creative_img.png";
import RoundedImg from "./rounded_img.png";
import HeartRate from "./heart_right.png";
import SecretRightImg from "./secret_right.png";
import TopperImg from "./topper_img.png";
import Frame_1 from "./frame_1.jpg";
import HomeIcon from './home_icon.png';
import Prf_Img_blank from './member_profile_img.png';
import White_right_arrow from './white_arrow_icon_5.png'
export {
    Frame_1,
    Logo,
    Grad1,
    Grad2,
    Grad3,
    Grad4,
    Grad5,
    GptScreenshot,
    Reg,
    Topic,
    Generate,
    GPTScreen,
    GenerateCont,
    GeneratorGrad,
    Grad6,
    Top1,
    Globe,
    Iconss2,
    Iconss3,
    Seo,
    Calendar,
    Publish,
    Scheduling,
    Analytical,
    Grad7,
    Grad8,
    Grad9,
    ParentalControl,
    Grad10,
    Grad11,
    ManImage,
    Social,
    SocialMediaGrad,
    Grad12,
    TwitterBg,
    OpenUpBg,
    RewritterBg,
    ArticleBG,
    YellowStar,
    Blogger,
    TestGrad,
    AccordionGrad,
    FaceBook,
    Twitter,
    Instagram,
    Linkedin,
    PlayButton,
    LoginLogo,
    DashboardLogo,
    Mode,
    Bell,
    LogOut,
    DashIcon,
    Members,
    Connects,
    Analytics,
    Calendar1,
    Art1,
    Art2,
    Art3,
    Art4,
    Gadget,
    Facebook,
    Clock,
    InsIcon,
    TwitterIcon,
    FacebookIcon,
    InsIconn,
    DashLogo,
    MemberRight,
    Toggle,
    SearchIcon,
    NameView,
    action,
    FacebookSocial,
    InsSocial,
    TwitterSocial,
    LinkedSocial,
    Wp,
    TwitterIconn,
    TotalPostts,
    PostReach,
    UpArrow,
    PostEngagement,
    DownArrow1,
    PostView,
    Cal,
    SquareImage,
    ProtraitImage,
    LandscapeImage,
    GenImg1,
    GenImg2,
    GenImg3,
    GenImg5,
    BlueTick,
    PrevImg,
    PrevCross,
    FbLogo,

    Social1,
    Social2,
    Social3,
    Social4,
    Social5,
    SocialTop,
    SocialImggg,
    Calendar11,
    Book22,
    ArchiveArticle,
    Search1,
    TickPlan,
    ProTopImg,
    PrfBannerEidt,
    PrfImggg,
    SearchIcon121,
    Folder,
    Icon11,
    Icon2,
    Comm,
    Lifetime,
    DemoLogo,
    SearchButtonIcon,
    Support1,
    SupportChatts,
    BlackThemeLogo,
    Mode2,
    TwitterLike,
    ChatTwitter,
    Retweet,
    LikeLinkedin,
    CommentsLinkedin,
    ShareLinkedin,
    no_image,
    no_prf,
    PaymentSuccess,
    OrgReg,
    UserReg,
    Information,
    EditBut,
    Create1,
    Create2,
    Create3,
    Create4,
    Create5,
    Create6,
    Like,
    DisLike,
    TextIcon,
    SingleImage,
    Image1,
    Image2,
    Image3,
    Image4,
    Image5,
    Image6,
    Assets,
    AiIcon,
    Creatives,
    Captions,
    Hashtag,
    Suggetions,
    CreativeImg,
    RoundedImg,
    HeartRate,
    SecretRightImg,
    TopperImg,
    HomeIcon,
    Prf_Img_blank,
    White_right_arrow,

};