import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Form,
  Dropdown,
  Table,
  Accordion,
  Card,
  Tab,
  Nav,
} from "react-bootstrap";
import DashboardLeftPanel from "../../../components/DashboardLeftPanel/dashboardLeftPanel";
import DashRightPanel from "../../../components/DashboardRightPanel/dashbaordrightpanel";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import "./archive-article.css";
import axios from "../../../axios/axios";
import message from "../../../messages";
import { Audio } from "react-loader-spinner";
// import { Calendar, momentLocalizer } from 'react-event-calendar';
import {
  Toggle,
  Search1,
  FacebookSocial,
  InsSocial,
  Wp,
  action,
  LinkedSocial,
  TwitterSocial,
} from "../../../assets/images";
import { useEffect } from "react";
import moment from "moment";

const ArchiveArticle = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [publishedPost, setPublishedPost] = useState([]);
  const [schedulePost, setSchedulePost] = useState([]);
  const [loader, setLoader] = useState(false);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
    setExpanded(!expanded);
  };
// Effect to load the state from localStorage on initial render
useEffect(() => {
  const storedCollapsed = localStorage.getItem('collapsed');
  if (storedCollapsed) {
      setCollapsed(JSON.parse(storedCollapsed));
  }
}, []);

// Effect to save the state to localStorage whenever it changes
useEffect(() => {
  localStorage.setItem('collapsed', JSON.stringify(collapsed));
}, [collapsed]);



// Effect to load the state from localStorage on initial render
useEffect(() => {
  const storedExpanded = localStorage.getItem('expanded');
  if (storedExpanded) {
      setExpanded(JSON.parse(storedExpanded));
  }
}, []);

// Effect to save the state to localStorage whenever it changes
useEffect(() => {
  localStorage.setItem('expanded', JSON.stringify(collapsed));
}, [collapsed]);
  const getPublishPost = async () => {
    setLoader(true);
    await axios
      .get("/archive-post")
      .then((res) => {
        setLoader(false);
        setPublishedPost(res.data.data);
        // console.log(res.data.data);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };
  const getSchedulePost = async () => {
    setLoader(true);
    await axios
      .get("/scheduled-post")
      .then((res) => {
        setLoader(false);
        setSchedulePost(res.data.data);
        // console.log(res.data.data);
      })
      .catch((err) => {
        setLoader(false);
        console.log("err", err);
        message.error(err.response.data.message);
      });
  };

  const archivePost = () => {
    let post = [];
    publishedPost.map((item, i) => {
      post.push({
        topics: (
          <div className="management_list_name">
            <div className="man_text">{item.title}</div>
          </div>
        ),
        // publisMember: <div className="man_username">publish member</div>,
        created_At: (
          <div className="man_username">
            {moment(item.createdAt).format("DD/MM/YYY LTS")}
          </div>
        ),
        platforms: (
          <div className="man_username">
            <span>
                {item.wordpress.length <= 0 ? <></> : <img style={{width:"30px", height:"30px"}} src={Wp} />}
            </span>
            <span>
              {item.facebook.length <= 0 ? <></> : <img style={{width:"30px", height:"30px"}} src={FacebookSocial} />}
            </span>
            <span>
              {item.twitter.length <= 0 ? <></> : <img style={{width:"30px", height:"30px"}} src={TwitterSocial} />}
            </span>
            <span>
              {item.instagram.length <= 0 ? <></> : <img style={{width:"30px", height:"30px"}} src={InsSocial} />}
            </span>
            <span>
              {item.linkedin.length <= 0 ? <></> : <img style={{width:"30px", height:"30px"}} src={LinkedSocial} />}
            </span>
          </div>
        ),
        status: <div className="man_username">{item.status==="Publish"&&"Published"}</div>,
        // action: (
        //   <div className="action_partts">
        //     <Dropdown>
        //       <Dropdown.Toggle variant="success" id="dropdown-basic">
        //         <img src={action} />
        //       </Dropdown.Toggle>

        //       <Dropdown.Menu>
        //         <Dropdown.Item href={`/manage-members/${item._id}`}>
        //           Edit
        //         </Dropdown.Item>
        //         <Dropdown.Item onClick={() => deleteUser(item._id)}>
        //           Delete
        //         </Dropdown.Item>
        //       </Dropdown.Menu>
        //     </Dropdown>
        //   </div>
        // ),
      });
    });
    return post;
  };

  const archiveSchedule = () => {
    let post = [];
    schedulePost.map((item, i) => {
      post.push({
        topics: (
          <div className="management_list_name">
            <div className="man_text">{item.title}</div>
          </div>
        ),
        // publisMember: <div className="man_username">publish member</div>,
        created_At: (
          <div className="man_username">
            {moment(item.createdAt).format("DD/MM/YYY LTS")}
          </div>
        ),
        platforms: (
          <div className="man_username">
            <span>
                {item.wordpress.length <= 0 ? <></> : <img style={{width:"30px", height:"30px"}} src={Wp} />}
            </span>
            <span>
              {item.facebook.length <= 0 ? <></> : <img style={{width:"30px", height:"30px"}} src={FacebookSocial} />}
            </span>
            <span>
              {item.twitter.length <= 0 ? <></> : <img style={{width:"30px", height:"30px"}} src={TwitterSocial} />}
            </span>
            <span>
              {item.instagram.length <= 0 ? <></> : <img style={{width:"30px", height:"30px"}} src={InsSocial} />}
            </span>
            <span>
              {item.linkedin.length <= 0 ? <></> : <img style={{width:"30px", height:"30px"}} src={LinkedSocial} />}
            </span>
          </div>
        ),
        status: <div className="man_username">{item.status==="Schedule"&&"Scheduled"}</div>,
        // action: (
        //   <div className="action_partts">
        //     <Dropdown>
        //       <Dropdown.Toggle variant="success" id="dropdown-basic">
        //         <img src={action} />
        //       </Dropdown.Toggle>

        //       <Dropdown.Menu>
        //         <Dropdown.Item href={`/manage-members/${item._id}`}>
        //           Edit
        //         </Dropdown.Item>
        //         <Dropdown.Item onClick={() => deleteUser(item._id)}>
        //           Delete
        //         </Dropdown.Item>
        //       </Dropdown.Menu>
        //     </Dropdown>
        //   </div>
        // ),
      });
    });
    return post;
  };


  const columns = [
    {
      name: "Sl No.",
      selector: (row, ind) => ind + 1,
      width: "100px",
      sortable: true,
      style: {
        padding: "10px 7px",
        // height: "50px",
        // display: "block",
      },
    },
    {
      name: " Article Topics",
      selector: (row) => row.topics,
      width: "190px",
      sortable: true,
      style: {
        padding: "10px 7px",
        // height: "50px",
        // display: "block",
      },
    },
    // {
    //   name: "Published Members",
    //   selector: (row) => row.publisMember,
    //   width: "180px",
    //   sortable: true,
    //   style: {
    //     padding: "10px 7px",
    //     // height: "50px",
    //     // display: "block",
    //   },
    // },
    {
      name: "Creation Date",
      selector: (row) => row.created_At,
      width: "140px",
      sortable: true,
      style: {
        padding: "10px 7px",
        // height: "50px",
        // display: "block",
      },
    },
    {
      name: "Shared Platform",
      selector: (row) => row.platforms,
      width: "170px",
      sortable: true,
      style: {
        padding: "10px 7px",
        // height: "50px",
        // display: "block",
      },
    },
    {
      name: "Status",
      selector: (row) => row.status ,
      width: "170px",
      sortable: true,
      style: {
        padding: "10px 7px",
        // height: "50px",
        // display: "block",
      },
    },
    // {
    //     name: 'Action',
    //     selector: row => row.action,
    //     sortable: true,
    //     width: '110px',
    //     style: {
    //         padding: "10px 7px",
    //         // height: "50px",
    //         // display: "block",
    //       },
    // },
  ];

  useEffect(() => {
    getPublishPost();
    getSchedulePost();
  }, []);

  return (
    <section className="dash_partts">
      {loader == true && (
        <div className="loader-wrapper">
          <Audio
            height="80"
            width="80"
            radius="9"
            color="green"
            ariaLabel="loading"
            wrapperStyle
            wrapperClass
          />
        </div>
      )}
      <div className="dash_inner_partts">
        <div className={`sidebar ${collapsed ? "collapsed" : ""}`}>
          <DashboardLeftPanel />
        </div>

        <div className={`dash_right_panel ${expanded ? "expanded" : ""}`}>
          <button onClick={toggleSidebar} className="toggle__button">
            <img src={Toggle} />
          </button>
          <div className="dash_right_inner_panel">
            <Row>
              <Col lg="9" md="12" sm="12">
                <div className="archive_article_boxx mt-4">
                  <div className="archive_heading">
                    <h3>Published Post</h3>
                    <div className="search_boxxx">
                      <Form.Control
                        type="text"
                        placeholder="Search"
                        className="search__archive"
                      ></Form.Control>
                      <img src={Search1} className="search_img" />
                    </div>
                  </div>
                  <div className="archive_data_table">
                    <DataTable
                      columns={columns}
                      data={archivePost()}
                      pagination
                    />
                  </div>

                </div>
                <div className="archive_article_boxx mt-4">
                <div className="archive_heading">
                    <h3>Scheduled Post</h3>
                    <div className="search_boxxx">
                      <Form.Control
                        type="text"
                        placeholder="Search"
                        className="search__archive"
                      ></Form.Control>
                      <img src={Search1} className="search_img" />
                    </div>
                  </div>
                <div className="archive_data_table">
                    <DataTable
                      columns={columns}
                      data={archiveSchedule()}
                      pagination
                    />
                  </div>
                </div>
              </Col>
              <Col lg="3" md="12" sm="12" className="pr-0">
                <div className="dash_right_paertts">
                  <DashRightPanel />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ArchiveArticle;
