// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-tooltip-container {
    position: relative;
    display: inline-block;
  }
  
  .custom-tooltip {
    position: absolute;
    top: -100%;
    left: 100%;
    /* transform: translateX(-100%);
     */
     background-color: #1c1c1c;
    color: #fff;
    padding: 10px;
    border-radius: 3px;
    z-index: 1;
    width: 250px;
    font-size: 13px;
    line-height: 20px;
  }

  .custom-tooltip-container svg{
    display: inline-block;
    width: 18px;
    height: 18px;
  }
  .custom-tooltip-container svg path{
    width: 100%;
    height: 100%;
  }`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/customTooltip/custom-tooltip.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;EACvB;;EAEA;IACE,kBAAkB;IAClB,UAAU;IACV,UAAU;IACV;MACE;KACD,yBAAyB;IAC1B,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,qBAAqB;IACrB,WAAW;IACX,YAAY;EACd;EACA;IACE,WAAW;IACX,YAAY;EACd","sourcesContent":[".custom-tooltip-container {\n    position: relative;\n    display: inline-block;\n  }\n  \n  .custom-tooltip {\n    position: absolute;\n    top: -100%;\n    left: 100%;\n    /* transform: translateX(-100%);\n     */\n     background-color: #1c1c1c;\n    color: #fff;\n    padding: 10px;\n    border-radius: 3px;\n    z-index: 1;\n    width: 250px;\n    font-size: 13px;\n    line-height: 20px;\n  }\n\n  .custom-tooltip-container svg{\n    display: inline-block;\n    width: 18px;\n    height: 18px;\n  }\n  .custom-tooltip-container svg path{\n    width: 100%;\n    height: 100%;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
