import React, { useState } from 'react';
import { useEffect } from 'react';
import axios from '../../../axios/axios';
import message from "../../../messages";

const LinkedInAuthPage = () => {
    let called = 0;
    useEffect( () => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let code = params.get('code');
        if(code){
            called++;
            if(called == 1){
                var AuthToken = localStorage.getItem('AuthToken');
                axios.post('/linkedin/auth/validate', {code: code}, {headers: {'x-access-token': AuthToken}}).then( (res) => {
                    console.log(res)
                    if(res.status === 200){
                        localStorage.setItem("social_media", "Connected")
                        message.success("Connected")
                        setTimeout(()=>{
                            window.open("https://staging.d3lv58s33sw975.amplifyapp.com/social-connect", "_self")
                        },2000)
                    }else{
                        message.error(res.message)
                        setTimeout(()=>{
                            window.open("https://staging.d3lv58s33sw975.amplifyapp.com/social-connect", "_self")
                        },2000)
                    }
                }).catch((err)=>{
                    message.error(err.message)
                    setTimeout(()=>{
                        window.open("https://staging.d3lv58s33sw975.amplifyapp.com/social-connect", "_self")
                    },2000)
                })
            }
        }
    }, [])

    return (
        <div className='social_connect'>
        <p>Please wait..</p>
        </div>
    )
}
export default LinkedInAuthPage;