import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Modal, Button, Form, Dropdown, Table, Accordion, Card, Tabs, Tab } from 'react-bootstrap';
import DashboardLeftPanel from "../../../components/DashboardLeftPanel/dashboardLeftPanel";
import DashRightPanel from "../../../components/DashboardRightPanel/dashbaordrightpanel";
import { Link } from "react-router-dom";
import DataTable from 'react-data-table-component';
import './folder.css';

import {
    Toggle,
    SearchIcon121,
    Folder,
    action,

} from "../../../assets/images";
import CreateFolderModal from '../../../components/FolderCreate/CreateFolderModal';
import axios from '../../../axios/axios';
import { message } from 'antd';
import moment from 'moment';
import { useHistory } from "react-router-dom"


const FolderPage = () => {
    const history = useHistory()
    const [collapsed, setCollapsed] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [folderName, setFolderName] = useState("")
    const [showModal, setShowModal] = useState(false)
    const [folders, setFolders] = useState([])
    const [errorMsg, setErrorMsg] = useState("")
    const [key, setKey] = useState("afiles")
    const [posts, setPosts] = useState([])

    const toggleSidebar = () => {
        setCollapsed(!collapsed);
        setExpanded(!expanded);
    };

    // Effect to load the state from localStorage on initial render
    useEffect(() => {
        const storedCollapsed = localStorage.getItem('collapsed');
        if (storedCollapsed) {
            setCollapsed(JSON.parse(storedCollapsed));
        }
    }, []);

    // Effect to save the state to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('collapsed', JSON.stringify(collapsed));
    }, [collapsed]);



    // Effect to load the state from localStorage on initial render
    useEffect(() => {
        const storedExpanded = localStorage.getItem('expanded');
        if (storedExpanded) {
            setExpanded(JSON.parse(storedExpanded));
        }
    }, []);

    // Effect to save the state to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('expanded', JSON.stringify(collapsed));
    }, [collapsed]);
    const columns = [
        {
            name: 'Sl No.',
            selector: row => row.no,
            minWidth: '80px',
            maxWidth: '80px',
            sortable: true,
            style: {
                padding: "10px 7px",
            },
        },
        {
            name: 'Post Title',
            selector: row => row.postTitle,
            minWidth: '250px',
            sortable: true,
            style: {
                padding: "10px 7px",
            },
        },
        {
            name: 'Created Date',
            selector: row => row.date,
            minWidth: '240px',
            sortable: true,
            style: {
                padding: "10px 7px",
            },
        },
        {
            name: 'Action',
            selector: row => row.action,
            minWidth: '100px',
            maxWidth: '100px',
            sortable: true,
            style: {
                padding: "10px 7px",
            },
        },
    ];

    const setFolderData = () => {
        const arr = []

        posts.map((post, idx) => {
            arr.push({
                no: idx + 1,
                postTitle: <div className='name_text'>
                    <div className='name_view_sec file_name'>{post.title}</div>
                </div>,
                date: <div className='mail'>{moment(post.createdAt).calendar()}</div>,
                action: <div className='action_partts'>
                    <Dropdown drop="up">
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <img src={action} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => {
                                history.push(`/generate-article/${post._id}`)
                            }}>Edit</Dropdown.Item>
                            <Dropdown.Item>Delete</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            })
        })
        return arr
    }

    const getFolders = async () => {
        await axios.get("/my-folders").then(res => {
            console.log(res, "<<-- response")
            setFolders(res.data.data)
        }).catch(err => {
            console.error(err, '<<-- Error in get folders')
        })
    }

    const handleClose = () => {
        setShowModal(false)
        setFolderName("")
    }

    const handleCreate = async (e) => {
        e.preventDefault()
        console.log(folderName, "<<-- folderName")
        if (!folderName) {
            setErrorMsg("Folder name is required")
            return;
        } else {
            await axios.post("/create-folder", { "name": folderName }).then(res => {
                getFolders()
                message.success("Folder created successfully")
                setFolderName("")
                handleClose()
            }).catch(err => {
                console.error(err, "<<-- Error in create folder")
                if (err.code === "ERR_BAD_REQUEST") {
                    message.error(err.response.data.message)
                }
            })
        }
    }

    const getFolderContent = async (signal) => {
        let url
        if (key !== "afiles") {
            url = `/folder/${key}`
        } else {
            url = "/saved-post"
        }
        await axios.get(url, { signal }).then(res => {
            setPosts(res.data.data)
        }).catch(err => {
            if (!err.code === "ERR_CANCELED") {
                console.error(err, "<<-- Error in get posts")
            }
        })
    }

    useEffect(() => {
        getFolders()
    }, [])

    useEffect(() => {
        const controller = new AbortController()
        const signal = controller.signal;
        getFolderContent(signal)

        return () => {
            controller.abort()
        }
    }, [key])

    return (
        <section className="dash_partts">
            <div className='dash_inner_partts'>
                <div className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
                    <DashboardLeftPanel />
                </div>

                <div className={`dash_right_panel ${expanded ? 'expanded' : ''}`}>
                    <button onClick={toggleSidebar} className='toggle__button'><img src={Toggle} /></button>
                    <div className='dash_right_inner_panel'>
                        <Row>
                            <Col lg="9" md="12" sm="12">
                                <div className='my_folder_partts'>
                                    <div className='folder_heading'>
                                        <h2>Folder</h2>
                                        <div className='folder_search_and_button'>
                                            <div className='folder_search_field'>
                                                <Form.Control type='text' className='folder_search' placeholder='Search'></Form.Control>
                                                <span className='search__iconns'><img src={SearchIcon121} /></span>
                                            </div>
                                            <div className='search_button' onClick={() => { setShowModal(true) }}>
                                                <Button type='button' className='search_button_partts'>Add Folder <span>+</span></Button>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='allfolders_grp'>
                                        <div className='all_folders_heading'>
                                            <h3>All Folders</h3>
                                            <Link to="#" className="see_all">See all</Link>
                                        </div>
                                        <div className='article_group'>
                                            {folders && folders.length > 0 && folders.map(folder => {
                                                return (
                                                    <div className='article_inner' key={folder._id}>
                                                        <div className='art_inner_inner'>
                                                            <img src={Folder} height={40} />
                                                            <div className='article_text'>
                                                                <h4>{folder?.name}</h4>
                                                                <p>{folder?.posts?.length} {`post${folder.posts.length > 1 ? "s" : ""}`}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>



                                    <div className='content_marketing'>
                                        <Tabs defaultActiveKey="afiles" activeKey={key} id="my-tabs" onSelect={(k) => {
                                            setPosts([])
                                            setKey(k)
                                        }} >
                                            <Tab eventKey="afiles" title="All Files">
                                                <div className='content_data_table'>
                                                    <DataTable
                                                        columns={columns}
                                                        data={setFolderData()}
                                                        pagination
                                                    />
                                                </div>
                                            </Tab>
                                            {folders && folders.map(folder => (
                                                <Tab eventKey={folder._id} title={folder.name} onClick={() => { console.log("called") }}>
                                                    <div className='content_data_table'>
                                                        <DataTable
                                                            columns={columns}
                                                            data={setFolderData()}
                                                            pagination
                                                        />
                                                    </div>
                                                </Tab>
                                            ))}
                                        </Tabs>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="3" md="12" sm="12" className='pr-0'>
                                <div className='dash_right_paertts'>
                                    <DashRightPanel />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <CreateFolderModal handleCreate={handleCreate} showModal={showModal} folderName={folderName} handleChange={(e) => { setFolderName(e.target.value) }} handleClose={handleClose} folderNameError={errorMsg} setFolderName={setFolderName} />
        </section>
    );
};

export default FolderPage;