import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Modal, Button, Form, Dropdown, Table, Accordion, Card, Tab, Nav } from 'react-bootstrap';
import DashboardLeftPanel from "../../../components/DashboardLeftPanel/dashboardLeftPanel";
import DashRightPanel from "../../../components/DashboardRightPanel/dashbaordrightpanel";
import { Link } from "react-router-dom";
import DataTable from 'react-data-table-component';
import './socialanalytics.css';
import Switch from "react-switch";
import {
    FacebookSocial,
    InsSocial,
    TwitterSocial,
    LinkedSocial,
    Toggle,
    SearchIcon,
    NameView,
    action,
    Wp,
    TwitterIconn,
    TotalPostts,
    PostReach,
    PostEngagement,
    UpArrow,
    DownArrow1,
    PostView,
    Cal,
    TwitterLike,
    ChatTwitter,
    Retweet,
    LikeLinkedin,
    CommentsLinkedin,
    ShareLinkedin,
} from "../../../assets/images";

import like from "../../../assets/images/svg/like.svg"
import comment from "../../../assets/images/svg/comment-alt.svg"
import share from "../../../assets/images/svg/share.svg"

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    ArcElement, Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line, Doughnut } from 'react-chartjs-2';
import faker from 'faker';
import axios from '../../../axios/axios';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            // position: 'top' as const,
        },
        title: {
            display: true,
            text: '',
        },
    },
};

const labels = ['Jun 21', 'Jun 22', 'Jun 23', 'Jun 24', 'Jun 25', 'Jun 26', 'Jun 27'];

export const data = {
    labels,
    datasets: [
        {
            fill: true,
            label: 'Likes',
            data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            borderColor: 'rgba(224, 102, 255, 0.5)',
            backgroundColor: 'rgba(224, 102, 255, 0.5)',
        },

        {
            fill: true,
            label: 'Comments',
            data: labels.map(() => faker.datatype.number({ min: 40, max: 500 })),
            borderColor: 'rgba(140, 207, 255, 0.5)',
            backgroundColor: 'rgba(140, 207, 255, 0.5)',
        },

        {
            fill: true,
            label: 'Share',
            data: labels.map(() => faker.datatype.number({ min: 70, max: 800 })),
            borderColor: 'rgba(238, 225, 156, 0.5)',
            backgroundColor: 'rgba(238, 225, 156, 0.5)',
        },
    ],
};

export const data2 = {
    labels: ['Male', 'Female'],
    datasets: [
        {
            label: '',
            data: [40, 60],
            backgroundColor: [
                'rgba(255, 162, 117, 1)',
                'rgba(176, 116, 253, 1)',
            ],
            borderColor: [
                'rgba(255, 162, 117, 1)',
                'rgba(176, 116, 253, 1)',
            ],
            borderWidth: 1,
        },
    ],
};

const SocialConnectPage = () => {

    const [checked, setChecked] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [expanded, setExpanded] = useState(false);
    
    const [facebookAnalytics, setFacebookAnalytics] = useState([])
    const [instagramAnalytics, setInstagramAnalytics] = useState([])
    const [twitterAnalytics, setTwitterAnalytics] = useState([])
    const [linkedinAnalytics, setLinkedinAnalytics] = useState([])
    
    const handleChange = nextChecked => {
        setChecked(nextChecked);
    };
    const toggleSidebar = () => {
        setCollapsed(!collapsed);
        setExpanded(!expanded);
    };
// Effect to load the state from localStorage on initial render
useEffect(() => {
    const storedCollapsed = localStorage.getItem('collapsed');
    if (storedCollapsed) {
        setCollapsed(JSON.parse(storedCollapsed));
    }
  }, []);
  
  // Effect to save the state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('collapsed', JSON.stringify(collapsed));
  }, [collapsed]);
  
  // Effect to load the state from localStorage on initial render
  useEffect(() => {
    const storedExpanded = localStorage.getItem('expanded');
    if (storedExpanded) {
        setExpanded(JSON.parse(storedExpanded));
    }
  }, []);
  
  // Effect to save the state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('expanded', JSON.stringify(collapsed));
  }, [collapsed]);
    const columns = [
        {
            name: 'Sl No.',
            selector: row => row.no,
            width: '70px',
            sortable: true,
            style: {
                padding: "10px 7px",
                // height: "50px",
                // display: "block",
            },
        },
        {
            name: 'Platform',
            selector: row => row.platform,
            width: '150px',
            sortable: true,
            style: {
                padding: "10px 7px",
                // height: "50px",
                // display: "block",
            },
        },
        {
            name: 'Social Url Name',
            selector: row => row.url,
            width: '300px',
            sortable: true,
            style: {
                padding: "10px 7px",
                // height: "50px",
                // display: "block",
            },
        },
        {
            name: 'Status',
            selector: row => row.status,
            width: '140px',
            sortable: true,
            style: {
                padding: "10px 7px",
                // height: "50px",
                // display: "block",
            },
        },
        {
            name: 'Action',
            selector: row => row.action,
            sortable: true,
            width: '100px',
            style: {
                padding: "10px 7px",
                // height: "50px",
                // display: "block",
            },
        },
    ];

    const data1 = [
        {
            id: 1,
            no: '01',
            platform: <div className='name_text d-flex align-items-center'>
                <div className='name_view_sec'><img src={Wp} width={40} /></div>
            </div>,
            url: <div className='mail'>https://wptest.webgen.work/openai</div>,
            status: <Switch
                onChange={handleChange}
                checked={checked}
                className="react-switch"
            />,
            action: <div className='action_partts'>
                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <img src={action} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Edit</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Delete</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>,
        },
        {
            id: 2,
            no: '02',
            platform: <div className='name_text d-flex align-items-center'>
                <div className='name_view_sec'><img src={TwitterIconn} width={40} /></div>
            </div>,
            url: <div className='mail'>https://wptest.webgen.work/openai2</div>,
            status: <Switch
                onChange={handleChange}
                checked={checked}
                className="react-switch"
            />,
            action: <div className='action_partts'>
                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <img src={action} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Edit</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Delete</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>,
        },

        {
            id: 2,
            no: '03',
            platform: <div className='name_text d-flex align-items-center'>
                <div className='name_view_sec'><img src={Wp} width={40} /></div>
            </div>,
            url: <div className='mail'>https://wptest.webgen.work/openai</div>,
            status: <Switch
                onChange={handleChange}
                checked={checked}
                className="react-switch"
            />,
            action: <div className='action_partts'>
                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <img src={action} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Edit</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Delete</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>,
        },
        {
            id: 2,
            no: '04',
            platform: <div className='name_text d-flex align-items-center'>
                <div className='name_view_sec'><img src={TwitterIconn} width={40} /></div>
            </div>,
            url: <div className='mail'>https://wptest.webgen.work/openai2</div>,
            status: <Switch
                onChange={handleChange}
                checked={checked}
                className="react-switch"
            />,
            action: <div className='action_partts'>
                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <img src={action} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Edit</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Delete</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>,
        },
        {
            id: 2,
            no: '05',
            platform: <div className='name_text d-flex align-items-center'>
                <div className='name_view_sec'><img src={FacebookSocial} width={40} /></div>
            </div>,
            url: <div className='mail'>https://wptest.webgen.work/openai2</div>,
            status: <Switch
                onChange={handleChange}
                checked={checked}
                className="react-switch"
            />,
            action: <div className='action_partts'>
                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <img src={action} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Edit</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Delete</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>,
        },
    ]

    const getFbAnalytics = async () => {
        await axios
            .get("/facebook/analytics")
            .then(res => {
                setFacebookAnalytics(res.data.data)
            })
            .catch(err => {
                console.error("err",err)
            })
    }

    const getInstaAnalytics = async () => {
        await axios
            .get("/instagram/analytics")
            .then(res => {
                setInstagramAnalytics(res.data.data)
            })
            .catch(err => {
                console.error("err",err)
            })
    }

    const getTwitterAnalytics = async () => {
        await axios
            .get("/twitter/analytics")
            .then(res => {
                setTwitterAnalytics(res.data.data)
            })
            .catch(err => {
                console.error("err",err)
            })
    }

    const getLinkedinAnalytics = async () => {
        await axios
            .get("/linkedin/analytics")
            .then(res => {
                setLinkedinAnalytics(res.data.data)
            })
            .catch(err => {
                console.error("err",err)
            })
    }

    // useEffect(() => {
    //     getFbAnalytics()
    //     getInstaAnalytics()
    //     getTwitterAnalytics()
    //     getLinkedinAnalytics()
    // }, [])

    return (
        <section className="dash_partts">
             <div className='dash_inner_partts'>
                 <div className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
                     <DashboardLeftPanel />
                 </div>

                 <div className={`dash_right_panel ${expanded ? 'expanded' : ''}`}>
                     <button onClick={toggleSidebar} className='toggle__button'><img src={Toggle} /></button>
                <h1 style={{color:"gray", textAlign:"center"}}>Coming Soon!</h1>
                 </div>
         </div>
         </section>
    );
};

export default SocialConnectPage;

// <section className="dash_partts">
        //     <div className='dash_inner_partts'>
        //         <div className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
        //             <DashboardLeftPanel />
        //         </div>

        //         <div className={`dash_right_panel ${expanded ? 'expanded' : ''}`}>
        //             <button onClick={toggleSidebar} className='toggle__button'><img src={Toggle} /></button>
        //             <div className='dash_right_inner_panel'>
        //                 <Row>
        //                     <Col lg="9" md="12" sm="12">
        //                         <div className='dash_middle_panel'>
        //                             <Tab.Container id="tabs-container" defaultActiveKey="tab1">
        //                                 <Nav variant="tabs" className='social_connect_inner_sec tab_inner_inner_seccc'>
        //                                     <Nav.Item className='social_inner_inner_sec'>
        //                                         <Nav.Link eventKey="tab1">
        //                                             <div className='social_white_sec'>
        //                                                 <img src={FacebookSocial} width={35} />
        //                                                 <h4>Facebook</h4>
        //                                             </div>
        //                                         </Nav.Link>
        //                                     </Nav.Item>
        //                                     <Nav.Item className='social_inner_inner_sec'>
        //                                         <Nav.Link eventKey="tab2">
        //                                             <div className='social_white_sec'>
        //                                                 <img src={InsSocial} width={35} />
        //                                                 <h4>Instagram</h4>
        //                                             </div>
        //                                         </Nav.Link>
        //                                     </Nav.Item>
        //                                     <Nav.Item className='social_inner_inner_sec'>
        //                                         <Nav.Link eventKey="tab3">
        //                                             <div className='social_white_sec'>
        //                                                 <img src={TwitterSocial} width={35} />
        //                                                 <h4>Twitter</h4>
        //                                             </div>
        //                                         </Nav.Link>
        //                                     </Nav.Item>

        //                                     <Nav.Item className='social_inner_inner_sec'>
        //                                         <Nav.Link eventKey="tab4">
        //                                             <div className='social_white_sec'>
        //                                                 <img src={LinkedSocial} width={35} />
        //                                                 <h4>Linkedin</h4>
        //                                             </div>
        //                                         </Nav.Link>
        //                                     </Nav.Item>

        //                                     {/* <Nav.Item className='social_inner_inner_sec'>
        //                                         <Nav.Link eventKey="tab5">
        //                                             <div className='social_white_sec'>
        //                                                 <img src={Wp} width={35} />
        //                                                 <h4>Wordpress Blog</h4>
        //                                             </div>
        //                                         </Nav.Link>
        //                                     </Nav.Item> */}
        //                                 </Nav>

        //                                 <Tab.Content className='social_content'>
        //                                     <Tab.Pane eventKey="tab1">
        //                                         <div className='post_parttss'>
        //                                             <div className='post_topper'>
        //                                                 <div className='post_iinner_toppter_parttts'>
        //                                                     <div className='post_inner_topper'>
        //                                                         <div className='post_top_heading'>
        //                                                             <img src={TotalPostts} width={35} />
        //                                                             Total Posts
        //                                                         </div>
        //                                                         <div className='postts_decl d-flex justify-content-between'>
        //                                                             {facebookAnalytics.total_post}
        //                                                         </div>
        //                                                     </div>
        //                                                 </div>
        //                                                 <div className='post_iinner_toppter_parttts'>
        //                                                     <div className='post_inner_topper'>
        //                                                         <div className='post_top_heading'>
        //                                                             <img src={like} width={35} />
        //                                                             Likes
        //                                                         </div>
        //                                                         <div className='postts_decl d-flex justify-content-between align-items-center'>
        //                                                             {facebookAnalytics.like ?? 0}
        //                                                             {/* <span><img src={UpArrow} />5.0%</span> */}
        //                                                         </div>
        //                                                     </div>
        //                                                 </div>

        //                                                 <div className='post_iinner_toppter_parttts'>
        //                                                     <div className='post_inner_topper'>
        //                                                         <div className='post_top_heading'>
        //                                                             <img src={comment} width={35} />
        //                                                             Comments
        //                                                         </div>
        //                                                         <div className='postts_decl d-flex justify-content-between align-items-center'>
        //                                                             {facebookAnalytics.comments ?? 0}
        //                                                             {/* <span><img src={UpArrow} />15.0%</span> */}
        //                                                         </div>
        //                                                     </div>
        //                                                 </div>


        //                                                 <div className='post_iinner_toppter_parttts'>
        //                                                     <div className='post_inner_topper'>
        //                                                         <div className='post_top_heading'>
        //                                                             <img src={share} width={35} />
        //                                                             Shared
        //                                                         </div>
        //                                                         <div className='postts_decl down d-flex justify-content-between align-items-center'>
        //                                                             {facebookAnalytics.shared ?? 0}
        //                                                             {/* <span><img src={DownArrow1} />-8.0%</span> */}
        //                                                         </div>
        //                                                     </div>
        //                                                 </div>
        //                                             </div>
        //                                         </div>

        //                                         <div className='post_analytics_social mt-4'>
        //                                             <Row>
        //                                                 <Col lg="12" md="12">
        //                                                     <div className='analytics_left'>
        //                                                         <div className='social_analytics_heading d-flex flex-wrap justify-content-between align-items-center'>
        //                                                             <h3>Post Analytics</h3>
        //                                                             <div className='post_dropdown'>
        //                                                                 <Dropdown className='post_drop_inner'>
        //                                                                     <Dropdown.Toggle variant="success" id="dropdown-basic">
        //                                                                         <span className='mr-2'><img src={Cal} /></span> Weekly
        //                                                                     </Dropdown.Toggle>

        //                                                                     <Dropdown.Menu>
        //                                                                     <Dropdown.Item href="#/action-2">Monthly</Dropdown.Item>
        //                                                                         <Dropdown.Item href="#/action-1">Yearly</Dropdown.Item>
        //                                                                     </Dropdown.Menu>
        //                                                                 </Dropdown>
        //                                                             </div>
        //                                                         </div>
        //                                                         <div className='social_chartts mt-2'>
        //                                                             <Line options={options} data={data} />
        //                                                         </div>
        //                                                     </div>
        //                                                 </Col>
        //                                                 <Col lg="6" md="6">
        //                                                     {/* <div className='analytics_right'>
        //                                                         <div className='ana_heading'>
        //                                                             Audience
        //                                                         </div>
        //                                                         <div className='dognut_chartts text-center'>
        //                                                             <Doughnut data={data2} />
        //                                                             <label className='percent_label'>100%</label>
        //                                                         </div>
        //                                                         <div className='male_female'>
        //                                                             <span>Male: 40%</span>
        //                                                             <span>Female: 60%</span>
        //                                                         </div>
        //                                                     </div> */}
        //                                                 </Col>
        //                                             </Row>
        //                                         </div>
        //                                     </Tab.Pane>
        //                                     <Tab.Pane eventKey="tab2">
        //                                         <div className='post_parttss'>
        //                                             <div className='post_topper'>
        //                                                 <div className='post_iinner_toppter_parttts'>
        //                                                     <div className='post_inner_topper'>
        //                                                         <div className='post_top_heading'>
        //                                                             <img src={TotalPostts} width={35} />
        //                                                             Total Posts
        //                                                         </div>
        //                                                         <div className='postts_decl d-flex justify-content-between'>
        //                                                             {instagramAnalytics.total_post}
        //                                                         </div>
        //                                                     </div>
        //                                                 </div>
        //                                                 <div className='post_iinner_toppter_parttts'>
        //                                                     <div className='post_inner_topper'>
        //                                                         <div className='post_top_heading'>
        //                                                             <img src={like} width={35} />
        //                                                             Likes
        //                                                         </div>
        //                                                         <div className='postts_decl d-flex justify-content-between align-items-center'>
        //                                                             {instagramAnalytics.like ?? 0}
        //                                                             {/* <span><img src={UpArrow} />5.0%</span> */}
        //                                                         </div>
        //                                                     </div>
        //                                                 </div>

        //                                                 <div className='post_iinner_toppter_parttts'>
        //                                                     <div className='post_inner_topper'>
        //                                                         <div className='post_top_heading'>
        //                                                             <img src={comment} width={35} />
        //                                                             Comments
        //                                                         </div>
        //                                                         <div className='postts_decl d-flex justify-content-between align-items-center'>
        //                                                             {instagramAnalytics.comments ?? 0}
        //                                                             {/* <span><img src={UpArrow} />15.0%</span> */}
        //                                                         </div>
        //                                                     </div>
        //                                                 </div>


        //                                                 <div className='post_iinner_toppter_parttts'>
        //                                                     <div className='post_inner_topper'>
        //                                                         <div className='post_top_heading'>
        //                                                             <img src={share} width={35} />
        //                                                             Shared
        //                                                         </div>
        //                                                         <div className='postts_decl down d-flex justify-content-between align-items-center'>
        //                                                             {instagramAnalytics.shared ?? 0}
        //                                                             {/* <span><img src={DownArrow1} />-8.0%</span> */}
        //                                                         </div>
        //                                                     </div>
        //                                                 </div>
        //                                             </div>
        //                                         </div>

        //                                         <div className='post_analytics_social mt-4'>
        //                                         <Row>
        //                                                 <Col lg="12" md="12">
        //                                                     <div className='analytics_left'>
        //                                                         <div className='social_analytics_heading d-flex flex-wrap justify-content-between align-items-center'>
        //                                                             <h3>Post Analytics</h3>
        //                                                             <div className='post_dropdown'>
        //                                                                 <Dropdown className='post_drop_inner'>
        //                                                                     <Dropdown.Toggle variant="success" id="dropdown-basic">
        //                                                                         <span className='mr-2'><img src={Cal} /></span> Weekly
        //                                                                     </Dropdown.Toggle>

        //                                                                     <Dropdown.Menu>
        //                                                                         <Dropdown.Item href="#/action-2">Monthly</Dropdown.Item>
        //                                                                         <Dropdown.Item href="#/action-1">Yearly</Dropdown.Item>
        //                                                                     </Dropdown.Menu>
        //                                                                 </Dropdown>
        //                                                             </div>
        //                                                         </div>
        //                                                         <div className='social_chartts mt-2'>
        //                                                             <Line options={options} data={data} />
        //                                                         </div>
        //                                                     </div>
        //                                                 </Col>
        //                                                 {/* <Col lg="6" md="6">
        //                                                     <div className='analytics_right'>
        //                                                         <div className='ana_heading'>
        //                                                             Audience
        //                                                         </div>
        //                                                         <div className='dognut_chartts text-center'>
        //                                                             <Doughnut data={data2} />
        //                                                             <label className='percent_label'>100%</label>
        //                                                         </div>
        //                                                         <div className='male_female'>
        //                                                             <span>Male: 40%</span>
        //                                                             <span>Female: 60%</span>
        //                                                         </div>
        //                                                     </div>
        //                                                 </Col> */}
        //                                             </Row>
        //                                         </div>
        //                                     </Tab.Pane>
        //                                     <Tab.Pane eventKey="tab3">
        //                                     <div className='post_parttss'>
        //                                             <div className='post_topper'>
        //                                                 <div className='post_iinner_toppter_parttts'>
        //                                                     <div className='post_inner_topper'>
        //                                                         <div className='post_top_heading'>
        //                                                             <img src={TotalPostts} width={35} />
        //                                                             Total Posts
        //                                                         </div>
        //                                                         <div className='postts_decl d-flex justify-content-between'>
        //                                                             {twitterAnalytics.total_post}
        //                                                         </div>
        //                                                     </div>
        //                                                 </div>
        //                                                 <div className='post_iinner_toppter_parttts'>
        //                                                     <div className='post_inner_topper'>
        //                                                         <div className='post_top_heading'>
        //                                                             <img src={TwitterLike} width={35} />
        //                                                             Likes
        //                                                         </div>
        //                                                         <div className='postts_decl d-flex justify-content-between align-items-center'>
        //                                                             {twitterAnalytics.like ?? 0}
        //                                                             {/* <span><img src={UpArrow} />5.0%</span> */}
        //                                                         </div>
        //                                                     </div>
        //                                                 </div>

        //                                                 <div className='post_iinner_toppter_parttts'>
        //                                                     <div className='post_inner_topper'>
        //                                                         <div className='post_top_heading'>
        //                                                             <img src={ChatTwitter} width={35} />
        //                                                             Comments
        //                                                         </div>
        //                                                         <div className='postts_decl d-flex justify-content-between align-items-center'>
        //                                                             {twitterAnalytics.comments ?? 0}
        //                                                             {/* <span><img src={UpArrow} />15.0%</span> */}
        //                                                         </div>
        //                                                     </div>
        //                                                 </div>


        //                                                 <div className='post_iinner_toppter_parttts'>
        //                                                     <div className='post_inner_topper'>
        //                                                         <div className='post_top_heading'>
        //                                                             <img src={Retweet} width={35} />
        //                                                             Shared
        //                                                         </div>
        //                                                         <div className='postts_decl down d-flex justify-content-between align-items-center'>
        //                                                             {twitterAnalytics.shared ?? 0}
        //                                                             {/* <span><img src={DownArrow1} />-8.0%</span> */}
        //                                                         </div>
        //                                                     </div>
        //                                                 </div>
        //                                             </div>
        //                                     </div>

        //                                     <div className='post_analytics_social mt-4'>
        //                                         <Row>
        //                                             <Col lg="12" md="12">
        //                                                 <div className='analytics_left'>
        //                                                     <div className='social_analytics_heading d-flex flex-wrap justify-content-between align-items-center'>
        //                                                         <h3>Post Analytics</h3>
        //                                                         <div className='post_dropdown'>
        //                                                             <Dropdown className='post_drop_inner'>
        //                                                                 <Dropdown.Toggle variant="success" id="dropdown-basic">
        //                                                                     <span className='mr-2'><img src={Cal} /></span> Weekly
        //                                                                 </Dropdown.Toggle>

        //                                                                 <Dropdown.Menu>
        //                                                                 <Dropdown.Item href="#/action-2">Monthly</Dropdown.Item>
        //                                                                         <Dropdown.Item href="#/action-1">Yearly</Dropdown.Item>
        //                                                                 </Dropdown.Menu>
        //                                                             </Dropdown>
        //                                                         </div>
        //                                                     </div>
        //                                                     <div className='social_chartts mt-2'>
        //                                                         <Line options={options} data={data} />
        //                                                     </div>
        //                                                 </div>
        //                                             </Col>
        //                                             <Col lg="6" md="6">
        //                                                 {/* <div className='analytics_right'>
        //                                                     <div className='ana_heading'>
        //                                                         Audience
        //                                                     </div>
        //                                                     <div className='dognut_chartts text-center'>
        //                                                         <Doughnut data={data2} />
        //                                                         <label className='percent_label'>100%</label>
        //                                                     </div>
        //                                                     <div className='male_female'>
        //                                                         <span>Male: 40%</span>
        //                                                         <span>Female: 60%</span>
        //                                                     </div>
        //                                                 </div> */}
        //                                             </Col>
        //                                         </Row>
        //                                     </div>
        //                                     </Tab.Pane>

        //                                     <Tab.Pane eventKey="tab4">
        //                                     <div className='post_parttss'>
        //                                             <div className='post_topper'>
        //                                                 <div className='post_iinner_toppter_parttts'>
        //                                                     <div className='post_inner_topper'>
        //                                                         <div className='post_top_heading'>
        //                                                             <img src={TotalPostts} width={35} />
        //                                                             Total Posts
        //                                                         </div>
        //                                                         <div className='postts_decl d-flex justify-content-between'>
        //                                                             {linkedinAnalytics.total_post}
        //                                                         </div>
        //                                                     </div>
        //                                                 </div>
        //                                                 <div className='post_iinner_toppter_parttts'>
        //                                                     <div className='post_inner_topper'>
        //                                                         <div className='post_top_heading'>
        //                                                             <img src={LikeLinkedin} width={35} />
        //                                                             Likes
        //                                                         </div>
        //                                                         <div className='postts_decl d-flex justify-content-between align-items-center'>
        //                                                             {linkedinAnalytics.like ?? 0}
        //                                                             {/* <span><img src={UpArrow} />5.0%</span> */}
        //                                                         </div>
        //                                                     </div>
        //                                                 </div>

        //                                                 <div className='post_iinner_toppter_parttts'>
        //                                                     <div className='post_inner_topper'>
        //                                                         <div className='post_top_heading'>
        //                                                             <img src={CommentsLinkedin} width={35} />
        //                                                             Comments
        //                                                         </div>
        //                                                         <div className='postts_decl d-flex justify-content-between align-items-center'>
        //                                                             {linkedinAnalytics.comments ?? 0}
        //                                                             {/* <span><img src={UpArrow} />15.0%</span> */}
        //                                                         </div>
        //                                                     </div>
        //                                                 </div>


        //                                                 <div className='post_iinner_toppter_parttts'>
        //                                                     <div className='post_inner_topper'>
        //                                                         <div className='post_top_heading'>
        //                                                             <img src={ShareLinkedin} width={35} height={35} />
        //                                                             Shared
        //                                                         </div>
        //                                                         <div className='postts_decl down d-flex justify-content-between align-items-center'>
        //                                                             {linkedinAnalytics.shared ?? 0}
        //                                                             {/* <span><img src={DownArrow1} />-8.0%</span> */}
        //                                                         </div>
        //                                                     </div>
        //                                                 </div>
        //                                             </div>
        //                                     </div>

        //                                     <div className='post_analytics_social mt-4'>
        //                                         <Row>
        //                                             <Col lg="12" md="12">
        //                                                 <div className='analytics_left'>
        //                                                     <div className='social_analytics_heading d-flex flex-wrap justify-content-between align-items-center'>
        //                                                         <h3>Post Analytics</h3>
        //                                                         <div className='post_dropdown'>
        //                                                             <Dropdown className='post_drop_inner'>
        //                                                                 <Dropdown.Toggle variant="success" id="dropdown-basic">
        //                                                                     <span className='mr-2'><img src={Cal} /></span> Weekly
        //                                                                 </Dropdown.Toggle>

        //                                                                 <Dropdown.Menu>
        //                                                                 <Dropdown.Item href="#/action-2">Monthly</Dropdown.Item>
        //                                                                         <Dropdown.Item href="#/action-1">Yearly</Dropdown.Item>
        //                                                                 </Dropdown.Menu>
        //                                                             </Dropdown>
        //                                                         </div>
        //                                                     </div>
        //                                                     <div className='social_chartts mt-2'>
        //                                                         <Line options={options} data={data} />
        //                                                     </div>
        //                                                 </div>
        //                                             </Col>
        //                                             <Col lg="6" md="6">
        //                                                 {/* <div className='analytics_right'>
        //                                                     <div className='ana_heading'>
        //                                                         Audience
        //                                                     </div>
        //                                                     <div className='dognut_chartts text-center'>
        //                                                         <Doughnut data={data2} />
        //                                                         <label className='percent_label'>100%</label>
        //                                                     </div>
        //                                                     <div className='male_female'>
        //                                                         <span>Male: 40%</span>
        //                                                         <span>Female: 60%</span>
        //                                                     </div>
        //                                                 </div> */}
        //                                             </Col>
        //                                         </Row>
        //                                     </div>
        //                                     </Tab.Pane>
        //                                     <Tab.Pane eventKey="tab5">
        //                                         <h3>Tab 5 Content</h3>
        //                                         <p>This is the content of Tab 5.</p>
        //                                     </Tab.Pane>
        //                                 </Tab.Content>
        //                             </Tab.Container>
        //                         </div>
        //                     </Col>
        //                     <Col lg="3" md="12" sm="12" className='pr-0'>
        //                         <div className='dash_right_paertts'>
        //                             <DashRightPanel />
        //                         </div>
        //                     </Col>
        //                 </Row>
        //             </div>

        //         </div>
        //     </div>


        // </section>
